import React, { useState, useRef } from "react";
import { IMAGES } from "../helper/images";
import { Parallax } from "react-parallax";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { setNewPasswordApi } from "../helper/http_api";
import { useAlert } from "react-alert";
import { setBookFunc } from "../helper/temporary_data";
import HeaderWithIcon from "../components/commoncomponents/header_home_icon";
import styles from "../styles/set_new_password.module.css";

function SetNewPassword() {
  const InputRef = useRef(null);

  const [showNewPassword, setNewPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const [userNewPasswordData, setUserNewPasswordData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const alert = useAlert();

  const [showSpinner, setShowSpinner] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    /* custom validity for new password */
    if (name === "newPassword") {
      if (value.trim().length === 0) {
        e.target.setCustomValidity("Please enter a valid password.");
      } else if (value.length < 8) {
        e.target.setCustomValidity(
          "Password must be at least 8 characters long."
        );
      } else if (value.length > 16) {
        e.target.setCustomValidity(
          "Password cannot be longer than 16 characters."
        );
      } else {
        e.target.setCustomValidity("");
      }

      if (value.length === 0) {
        e.target.setCustomValidity("Password is a required field.");
      }
    }
    /* custom validity for confirm password */
    if (name === "confirmPassword") {
      if (value.trim().length === 0) {
        e.target.setCustomValidity("Please enter a valid confirm password.");
      } else if (value.length < 8) {
        e.target.setCustomValidity(
          "Confirm password must be at least 8 characters long."
        );
      } else if (value.length > 16) {
        e.target.setCustomValidity(
          "Confirm password cannot be longer than 16 characters."
        );
      } else {
        e.target.setCustomValidity("");
      }

      if (value.length === 0) {
        e.target.setCustomValidity("Confirm password is a required field.");
      }
    }

    setUserNewPasswordData((prevUserNewPasswordData) => ({
      ...prevUserNewPasswordData,
      [name]: value,
    }));
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  /* getting data from home page using queryParams */
  const user_Id = queryParams.get("user_Id");

  // console.log(
  //   "## user_Id from forgotpassword verify page received ##",
  //   user_Id
  // );

  const forgotPassword_setNewPassword_api_payload = {
    id: user_Id,
    newPassword: userNewPasswordData.newPassword,
    confirmPassword: userNewPasswordData.confirmPassword,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    // disabling button
    setIsButtonDisabled(true);

    if (
      userNewPasswordData.newPassword !== userNewPasswordData.confirmPassword
    ) {
      setShowSpinner(true);
      alert.success("New Password and Confirm Password do not match.");
      setShowSpinner(false);
      // enabling button
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 4000);
    } else {
      const setNewPasswordPromise = new Promise(async (resolve, reject) => {
        try {
          const response = await setNewPasswordApi(
            forgotPassword_setNewPassword_api_payload
          );
          resolve(response.data);
        } catch (error) {
          reject(error);
        }
      });

      setNewPasswordPromise
        .then((data) => {
          const { success, message } = data;
          // setShowSpinner(false);

          setTimeout(() => {
            setShowSpinner(false);
            if (success) {
              alert.success("Your password has changed successfully");
              navigate(`/user/auth/login`);
            } else {
              alert.error(message);
            }
            // enabling button
            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 4000);
          }, 1000);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(
            "Error occurred while setting user password in server",
            error
          );
          alert.error("Oops! Something unexpected happened.");
          // enabling button
          setTimeout(() => {
            setIsButtonDisabled(false);
          }, 4000);
        });
    }
  };

  const handleHomeClick = () => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
      setBookFunc(false);
      navigate(`/`);
    }, 1000);
  };

  return (
    <div>
      <Parallax
        // blur={10}
        bgImage={IMAGES.bgImg_section1}
        bgImageAlt="section1"
        // strength={120}
      >
        <div className={styles.content}>
          {/* active spinner */}
          {showSpinner && (
            <div className={styles.spinner_layout}>
              <SpinnerDotted color="white" />
            </div>
          )}

          <HeaderWithIcon HomeIconClick={handleHomeClick} />

          <form onSubmit={handleSubmit} className={styles.form_container}>
            <div className={styles.forgotPassword3_details_cont}>
              <div className={styles.forgotPassword_title}>Forgot Password</div>
              <div className={styles.user_input}>
                <div className={styles.input_field_cont}>
                  <input
                    type={showNewPassword ? "text" : "password"}
                    placeholder="New Password"
                    className={styles.input_field1}
                    name="newPassword"
                    value={userNewPasswordData.newPassword}
                    onChange={handleChange}
                    required
                    // minLength="8"
                    maxLength="16"
                    style={{ paddingRight: "30px" }} // text overlaying the icon issue
                    autoComplete="off"
                    ref={InputRef}
                    // pattern="[A-Z0-9]+"
                  />
                  <div className={styles.input_icons}>
                    {showNewPassword ? (
                      <BsEye
                        size={20}
                        onClick={() => setNewPassword(false)}
                        className={styles.eye_icon}
                      />
                    ) : (
                      <BsEyeSlash
                        size={20}
                        onClick={() => setNewPassword(true)}
                        className={styles.eye_icon}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.input_field_cont}>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    className={styles.input_field2}
                    name="confirmPassword"
                    value={userNewPasswordData.confirmPassword}
                    onChange={handleChange}
                    required
                    // minLength="8"
                    maxLength="16"
                    style={{ paddingRight: "30px" }} // text overlaying the icon issue
                    autoComplete="off"
                    ref={InputRef}
                    // pattern="[A-Z0-9]+"
                  />
                  <div className={styles.input_icons}>
                    {showConfirmPassword ? (
                      <BsEye
                        size={20}
                        onClick={() => setConfirmPassword(false)}
                        className={styles.eye_icon}
                      />
                    ) : (
                      <BsEyeSlash
                        size={20}
                        onClick={() => setConfirmPassword(true)}
                        className={styles.eye_icon}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* abc */}
            <div className={styles.login_details_cont1}>
              <div className={styles.below_upper_cont}>
                <div className={styles.submit_btn_cont}>
                  <button
                    type="submit"
                    className={styles.btn_submit}
                    disabled={isButtonDisabled}
                  >
                    <div className={styles.btn_cont}>
                      <div className={styles.btn_name}>Submit</div>
                    </div>
                  </button>
                </div>
              </div>
              <div className={styles.below_lower_cont}>
                Enter a new password and confirm it.
              </div>
            </div>
          </form>
        </div>
      </Parallax>
    </div>
  );
}

export default SetNewPassword;
