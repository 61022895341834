import React from "react";
import styles from "../../styles/header_component.module.css";
// import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo/logo_sengal.png";
const Header_component = () => {
 return (
  <div className={styles.main_container}>
   <div className={styles.logo_container}>
    <img src={Logo} className={styles.logo_size} alt="" />
   </div>
  </div>
 );
};

export default Header_component;
