import React, { useState, useEffect } from "react";
import { IMAGES } from "../helper/images";
import { Parallax } from "react-parallax";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { userForgotPasswordVerifyApi } from "../helper/http_api";
import { useAlert } from "react-alert";
import { resendVerifyCodeApi } from "../helper/http_api";
import { setBookFunc } from "../helper/temporary_data";
import HeaderWithIcon from "../components/commoncomponents/header_home_icon";
import styles from "../styles/forgot_password_verify.module.css";

function ForgotPasswordVerify() {
 // const [isSmallScreen, setIsSmallScreen] = useState(false);

 // useEffect(() => {
 //   const handleResize = () => {
 //     if (window.innerWidth >= 320) {
 //       setIsSmallScreen(true);
 //     } else {
 //       setIsSmallScreen(false);
 //     }
 //   };

 //   // Initial check
 //   handleResize();

 //   // Attach the event listener
 //   window.addEventListener("resize", handleResize);

 //   // Clean up the event listener on component unmount
 //   return () => {
 //     window.removeEventListener("resize", handleResize);
 //   };
 // }, []);

 const location = useLocation();
 const queryParams = new URLSearchParams(location.search);

 /* getting data from home page using queryParams */
 const forgotpassword_mail = queryParams.get("forgotpassword_mail");
 const userId = queryParams.get("userId");

 const [showVerifyCode, setVerifyCode] = useState(false);
 const [userForgotPasswordVerifyData, setUserForgotPasswordVerifyData] = useState({
  verifyCode: "",
 });

 const alert = useAlert(); // Initialize the useAlert hook

 const [showSpinner, setShowSpinner] = useState(false);
 const [isButtonDisabled, setIsButtonDisabled] = useState(false);
 const navigate = useNavigate();

 const handleChange = (e) => {
  const { name, value } = e.target;

  if (name === "verifyCode") {
   if (value.trim().length === 0) {
    e.target.setCustomValidity("Please enter a valid code.");
   } else if (value.length < 6) {
    e.target.setCustomValidity("Code must be 6 digits long.");
   } else {
    e.target.setCustomValidity("");
   }

   // if (value.length === 0) {
   //   e.target.setCustomValidity("Password is a required field.");
   // }
  }

  setUserForgotPasswordVerifyData((prevUserForgotPasswordVerifyData) => ({
   ...prevUserForgotPasswordVerifyData,
   [name]: value.toUpperCase(),
  }));
 };

 /* payloads */
 const forgotPasswordVerify_api_payload = {
  verificationCode: userForgotPasswordVerifyData.verifyCode,
  id: userId,
 };
 const resendVerifyCode_api_payload = {
  email: forgotpassword_mail,
 };

 const handleSubmit = (e) => {
  e.preventDefault();
  setShowSpinner(true);
  // disabling button
  setIsButtonDisabled(true);

  const forgotPasswordVerifyPromise = new Promise(async (resolve, reject) => {
   try {
    const response = await userForgotPasswordVerifyApi(forgotPasswordVerify_api_payload);
    resolve(response.data);
   } catch (error) {
    reject(error);
   }
  });

  forgotPasswordVerifyPromise
   .then((data) => {
    const { success, message, id } = data;
    // setShowSpinner(false);

    setTimeout(() => {
     setShowSpinner(false);
     if (success) {
      const queryParams = new URLSearchParams({
       user_Id: id,
      });

      navigate(`/user/auth/forgot_password/set_new_password?${queryParams}`);
      // alert(message);
     } else {
      alert.error(message);
     }
     // enabling button
     setTimeout(() => {
      setIsButtonDisabled(false);
     }, 4000);
    }, 1000);
   })
   .catch((error) => {
    setShowSpinner(false);
    console.error("Error occurred while otp verify for forgot password in server", error);
    alert.error("Oops! Something unexpected happened.");
    // enabling button
    setTimeout(() => {
     setIsButtonDisabled(false);
    }, 4000);
   });
 };

 const handleHomeClick = () => {
  setShowSpinner(true);
  setTimeout(() => {
   setShowSpinner(false);
   setBookFunc(false);
   navigate(`/`);
  }, 1000);
 };

 const [countdown, setCountdown] = useState(180);

 // useEffect(() => {
 //   let timer;

 //   if (countdown > 0 && !showVerifyCode) {
 //     timer = setInterval(() => {
 //       setCountdown(countdown - 1);
 //     }, 1000);
 //   }

 //   return () => {
 //     clearInterval(timer);
 //   };
 // }, [countdown, showVerifyCode]);

 useEffect(() => {
  let timer;

  if (countdown > 0) {
   timer = setInterval(() => {
    setCountdown(countdown - 1);
   }, 1000);
  }
  return () => {
   clearInterval(timer);
  };
 }, [countdown]);

 const handleResendCode = () => {
  // Add your logic to resend the code here
  setShowSpinner(true);
  setCountdown(180); // Reset the countdown

  const resendCodePromise = new Promise(async (resolve, reject) => {
   try {
    const response = await resendVerifyCodeApi(resendVerifyCode_api_payload);
    resolve(response.data);
   } catch (error) {
    reject(error);
   }
  });

  resendCodePromise
   .then((data) => {
    const { success, message } = data;
    // setShowSpinner(false);

    setTimeout(() => {
     setShowSpinner(false);
     if (success) {
      alert.success(message);
      // navigate(`/user/auth/forgot_password_verify`);
     } else {
      alert.error(message);
     }
    }, 1000);
   })
   .catch((error) => {
    setShowSpinner(false);
    console.error("Error occured while resend otp code from server", error);
    alert.error("Oops! Something unexpected happened.");
   });
 };

 return (
  <div>
   <Parallax
    // blur={10}
    bgImage={IMAGES.bgImg_section1}
    bgImageAlt="section1"
    // strength={120}
   >
    <div className={styles.content}>
     {/* active spinner */}
     {showSpinner && (
      <div className={styles.spinner_layout}>
       <SpinnerDotted color="white" />
      </div>
     )}

     <HeaderWithIcon HomeIconClick={handleHomeClick} />

     <form onSubmit={handleSubmit} className={styles.form_container}>
      <div className={styles.forgotPassword2_details_cont}>
       <div className={styles.forgotPassword_title}>Forgot Password</div>
       <div className={styles.user_input}>
        <div className={styles.input_field_cont}>
         <input
          type={showVerifyCode ? "text" : "password"}
          placeholder="Verification Code"
          className={styles.input_field1}
          name="verifyCode"
          value={userForgotPasswordVerifyData.verifyCode}
          onChange={handleChange}
          required
          maxLength="6"
          // style={{ paddingRight: "30px" }} // text overlaying the icon issue
          autoComplete="off"
          // pattern="[A-Z0-9]+"
         />
         <div className={styles.input_icons}>
          {showVerifyCode ? (
           <BsEye size={20} onClick={() => setVerifyCode(false)} className={styles.eye_icon} />
          ) : (
           <BsEyeSlash
            size={20}
            onClick={() => setVerifyCode(true)}
            className={styles.eye_icon}
           />
          )}
         </div>
        </div>
        <div className={styles.resend_code1}>
         {countdown > 0 ? (
          `Resend Code in ${Math.floor(countdown / 60)}:${(countdown % 60)
           .toString()
           .padStart(2, "0")} minutes`
         ) : (
          <div onClick={handleResendCode} className={styles.resend_code2}>
           Resend Code
          </div>
         )}
        </div>
       </div>
      </div>
      {/* abc */}
      <div className={styles.login_details_cont1}>
       <div className={styles.below_upper_cont}>
        <div className={styles.submit_btn_cont}>
         <button type="submit" className={styles.btn_submit} disabled={isButtonDisabled}>
          <div className={styles.btn_cont}>
           <div className={styles.btn_name}>Submit</div>
          </div>
         </button>
        </div>
       </div>
       {/* {isSmallScreen ? (
                <div className={styles.below_lower_cont}>
                  <div>We've sent a code to your</div>
                  <div>registered mail.</div>
                </div>
              ) : (
                <div className={styles.below_lower_cont}>
                  We've sent a code to your registered mail.
                </div>
              )} */}
       <div className={styles.below_lower_cont}>We've sent a code to your mail.</div>
      </div>
     </form>
    </div>
   </Parallax>
  </div>
 );
}

export default ForgotPasswordVerify;
