import React, { useContext } from "react";
import { BsSearch } from "react-icons/bs";
import "../../styles/foodstyles/SearchInput.css";
import { CartContext } from "../../context/cart_context";

const SearchInput = ({ onChange, placeholder, onSearchFood }) => {
  const { isRegistered } = useContext(CartContext);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearchFood(); // Call the search function when Enter is pressed
    }
  };

  return (
    <div
      className="search-container"
      style={{ outlineColor: "rgba(51, 24, 18, 1)" }}
      tabIndex={0}
      // onKeyDown={(event) => {
      //   if (event.key == "Enter") {
      //   }
      // }}
      onKeyDown={handleKeyDown}
    >
      <input
        type="search"
        // placeholder="Search..."
        placeholder={placeholder}
        onChange={onChange}
        className="search-input"
        minLength={1}
        required
      />
      {/* {isRegistered ? ( */}
      <BsSearch onClick={onSearchFood} className="search-icon" />
      {/* ) : ( */}
      {/* <BsSearch onClick={onSearchFood} className="search-icon" /> */}
      {/* )} */}
    </div>
  );
};

export default SearchInput;
