import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../helper/base_url";
import { GetAllImagesApi } from "../../helper/http_api";
import checkWindowSize from "../../helper/screen_size_check";

// react icons
import { BsArrowLeft, BsArrowRight, BsChevronDoubleUp } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";
import { HiCheck } from "react-icons/hi";

// styles
import styles from "../../styles/home_section_bottom.module.css";

const HomeSectionBottom = ({ scrollToHomeSectionTop }) => {
  /* screen size check */
  const { isSmallScreen, isLandscape } = checkWindowSize();
  const [LogoImg, setLogoImg] = useState("");
  const [imageUrl, setImageUrl] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  /* optimised code for getting media data from server */
  const getAllImages = async () => {
    try {
      const response = await GetAllImagesApi({});
      const { status, All_Files } = response.data;

      if (status === "success") {
        const logoImg = All_Files.Logo ? All_Files.Logo : "";
        setLogoImg(logoImg);

        const Section3_files = All_Files.Section3_files || [];
        setImageUrl(Section3_files.map((data) => data.image_url));
        setIsImageLoaded(true);
      } else {
        setIsImageLoaded(false);
      }
    } catch (error) {
      console.error("Error occurred while getting images from server:", error);
      setIsImageLoaded(false);
    }
  };

  useEffect(() => {
    getAllImages();
  }, []);

  /* room image left arrow click handler  */
  const previousSlide = () => {
    if (imageUrl.length > 0) {
      setCurrentSlide((prevSlide) =>
        prevSlide === 0 ? imageUrl.length - 1 : prevSlide - 1
      );
    }
  };

  /* room image right arrow click handler */
  const nextSlide = () => {
    if (imageUrl.length > 0) {
      setCurrentSlide((prevSlide) =>
        prevSlide === imageUrl.length - 1 ? 0 : prevSlide + 1
      );
    }
  };

  return (
    <>
      {isSmallScreen || isLandscape ? (
        <></>
      ) : (
        <header className={styles.header2}>
          <nav className={styles.navigation2}>
            <div
              className={styles.goto_sec1_icon}
              onClick={scrollToHomeSectionTop}
            >
              <BsChevronDoubleUp size={20} />
            </div>
          </nav>
        </header>
      )}

      {isLandscape && (
        <header className={styles.header2}>
          <nav className={styles.navigation2}>
            <div className={styles.logo_and_address}>
              {LogoImg && (
                <img
                  crossOrigin="anonymous"
                  src={`${BASE_URL}/sengal_admin/downloadFile/Logo_adminFiles${LogoImg}`}
                  alt="Sengal Logo"
                  className={styles.logo}
                />
              )}
              <div className={styles.head_right2}>
                <Link
                  to="https://www.google.com/maps/place/Sengal+Boutique+Hotel/@12.0232246,79.7984199,17z/data=!4m20!1m10!3m9!1s0x3a5367c617d3024f:0x285b7f8dc66496e1!2sSengal+Boutique+Hotel!5m2!4m1!1i2!8m2!3d12.0232194!4d79.8009948!16s%2Fg%2F11fmxj04vj!3m8!1s0x3a5367c617d3024f:0x285b7f8dc66496e1!5m2!4m1!1i2!8m2!3d12.0232194!4d79.8009948!16s%2Fg%2F11fmxj04vj?entry=ttu"
                  className={styles.address_link}
                >
                  <div className={styles.head_right_cont2}>
                    <div className={styles.head_right_cont_upp2}>
                      <div>Address</div>
                    </div>
                    <div className={styles.head_right_cont_down2}>
                      <div className={styles.hrcd_2}>
                        No.102, 4th Cross, Indira Nagar Alankuppam Post,
                      </div>
                      <div className={styles.hrcd_2}>Puducherry 605111</div>
                    </div>
                    <div className={styles.head_right_cont_upp2p}>
                      {isSmallScreen ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {isLandscape ? (
                            <div>Phone: +91 93848 51008, +91 89252 21008</div>
                          ) : (
                            <>
                              <span>Phone: +91 93848 51008</span>
                              <span
                                style={{ position: "relative", left: "3.1rem" }}
                              >
                                +91 89252 21008
                              </span>
                            </>
                          )}
                        </div>
                      ) : (
                        <div>Phone: +91 93848 51008, +91 89252 21008</div>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </nav>
        </header>
      )}

      <div className={styles.container2}>
        <div className={styles.slider_box}>
          {imageUrl.length > 0 && (
            <img
              crossOrigin="anonymous"
              src={`${BASE_URL}/sengal_admin/downloadFile/Section3_adminFiles${imageUrl[currentSlide]}`}
              alt="rooms_slider"
              className={styles.image2s3}
            />
          )}
          {isImageLoaded && (
            <div className={styles.slider_things}>
              <div onClick={previousSlide} className={styles.prev_slide}>
                <BsArrowLeft size={15} color="rgba(224, 225, 228, 0.8)" />
              </div>
              <div className={styles.num_of_slides}>{`${currentSlide + 1}/${
                imageUrl.length
              }`}</div>
              <div onClick={nextSlide} className={styles.next_slide}>
                <BsArrowRight size={15} color="rgba(224, 225, 228, 0.8)" />
              </div>
            </div>
          )}

          <div className={styles.slider_box_desc1}>
            SBH rooms offer plentiful amounts of natural and patterned lighting
            through the arrangement of signature red bricks. Along with the
            natural light, the fresh and cool air circulates in and out of the
            rooms, giving you a paradisiacal experience.
          </div>
          <div className={styles.slider_box_desc2}>
            Each room is equipped with air conditioning, personalized/dedicated
            Wi-Fi, a flat-screen LED TV with satellite channels, safety locker,
            computer desk, coffee table, a fridge, a kettle etc. Rooms have a
            naturally blended private bathroom with a Real Shower supplied with
            solar power heated water round the clock.
          </div>
        </div>
        <div className={styles.price_button_cont}>
          {isLandscape === false && (
            <div className={styles.logo_and_address}>
              {LogoImg && (
                <img
                  // src={IMAGES.SengalLogo}
                  crossOrigin="anonymous"
                  src={`${BASE_URL}/sengal_admin/downloadFile/Logo_adminFiles${LogoImg}`}
                  alt="Sengal Logo"
                  className={styles.logo}
                />
              )}
              <div className={styles.head_right2}>
                <Link
                  to="https://www.google.com/maps/place/Sengal+Boutique+Hotel/@12.0232246,79.7984199,17z/data=!4m20!1m10!3m9!1s0x3a5367c617d3024f:0x285b7f8dc66496e1!2sSengal+Boutique+Hotel!5m2!4m1!1i2!8m2!3d12.0232194!4d79.8009948!16s%2Fg%2F11fmxj04vj!3m8!1s0x3a5367c617d3024f:0x285b7f8dc66496e1!5m2!4m1!1i2!8m2!3d12.0232194!4d79.8009948!16s%2Fg%2F11fmxj04vj?entry=ttu"
                  className={styles.address_link}
                >
                  <div className={styles.head_right_cont2}>
                    <div className={styles.head_right_cont_upp2}>
                      <div>Address</div>
                    </div>
                    <div className={styles.head_right_cont_down2}>
                      <div className={styles.hrcd_2}>
                        No.102, 4th Cross, Indira Nagar Alankuppam Post,
                      </div>
                      <div className={styles.hrcd_2}>Puducherry 605111</div>
                    </div>
                    <div className={styles.head_right_cont_upp2p}>
                      <div>Phone: +91 93848 51008, +91 89252 21008</div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )}
          <div className={styles.booking_detail}>
            <div className={styles.room_name_big}>Deluxe rooms</div>
            <div className={styles.tick2}>
              <HiCheck size={15} />
              <span className={styles.item2}>Check-in time: 3:00 pm</span>
            </div>
            <div className={styles.tick3}>
              <HiCheck size={15} />
              <span className={styles.item3}>Check-out time: 11:00 am</span>
            </div>
            <div className={styles.tick4}>
              <HiCheck size={15} />
              <span className={styles.item4}>Free Unlimited Wi-Fi</span>
            </div>
            <div className={styles.tick6}>
              <HiCheck size={15} />
              <span className={styles.item6}>Free Vehicle Parking</span>
            </div>
          </div>
          <div className={styles.services}>
            <div className={styles.services_list}>Additional Facilities</div>
            <div className={styles.star1}>
              <AiFillStar size={15} />
              <span className={styles.item1s}>
                Airport Pick up & Drop off (Chennai, Puducherry)
              </span>
            </div>
            <div className={styles.star2}>
              <AiFillStar size={15} />
              <span className={styles.item2s}>
                Guided Tour (Auroville, White Town, Beaches)
              </span>
            </div>
            <div className={styles.star3}>
              <AiFillStar size={15} />
              <span className={styles.item3s}>
                Sea Adventure (Surfing, Scuba diving)
              </span>
            </div>
            <div className={styles.star4}>
              <AiFillStar size={15} />
              <span className={styles.item4s}>Guided Spiritual Tour</span>
            </div>
            <div className={styles.star5}>
              <AiFillStar size={15} />
              <span className={styles.item5s}>Cab, Bike, Bicycle Rentals</span>
            </div>
          </div>
        </div>
      </div>
      <footer className={styles.footer2}>
        Copyright ©{new Date().getFullYear()} All rights reserved | Sengal
        Boutique Hotel | Puducherry, India
      </footer>
    </>
  );
};
export default HomeSectionBottom;
