import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageViewTracker = () => {
    const location = useLocation();
    useEffect(() => {
        console.log('Current path:', location.pathname);
        window.gtag('config', '	G-58NQK2HG2G', {
            page_path: location.pathname,
        });
    }, [location]);

    return null; 
};

export default PageViewTracker;