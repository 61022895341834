import React, { useState, useEffect, useContext } from "react";
import styles from "../../styles/foodstyles/Food.module.css";
import Modal from "react-modal";
import { IoCloseOutline } from "react-icons/io5";
import { BASE_URL } from "../../helper/base_url";
import { IMAGES } from "../../helper/images";
import { STRINGS } from "../../model/constant";
import { CartContext } from "../../context/cart_context";
import FoodDetails from "./FoodDetails";

Modal.setAppElement("body");
const modalStyles = {
 overlay: {
  backgroundColor: "rgba(0,0,0,0.5)",
 },
};

const FoodItem = ({ food, alert_Predict_Func }) => {
 //  console.log(food, "food==============>");
 const [orderQuantity, setOrderQuantity] = useState();
 const [modalOpen, setModalOpen] = useState(false);
 const { cartFood, setArrows, userAuthentication_Details, currentSelectedDate } =
  useContext(CartContext);

 const currentItemQuantity = async () => {
  const itemIndex = cartFood?.findIndex(
   (item) => item.mainDish === food.Main_dish && item.foodCategory == food.foodCategory
  );
  // console.log(cartFood,'cartfood==========>');
  if (itemIndex === -1) setOrderQuantity(1);
  else setOrderQuantity(cartFood[itemIndex].orderQty);
 };

 const openModal = async () => {
  await currentItemQuantity();
  setArrows(true);
  setModalOpen(true);
 };

 //  console.log("modalOpen", modalOpen);

 const closeModal = () => {
  setModalOpen(false);
  setArrows(false);
 };

 return (
  <>
   <div onClick={() => openModal()} className={styles.food_cont_main}>
    {food?.Complementary &&
     userAuthentication_Details?.booking_details?.length > 0 &&
     userAuthentication_Details?.mapped_Dates[currentSelectedDate] == true && (
      <div style={{ position: "absolute", top: "6px", left: "-10px" }}>
       <img
        src={IMAGES.complementary_food_tag_img}
        width={187}
        height={33}
        alt="complementary_image"
       />
      </div>
     )}
    <div className={styles.food_menu_img_cont}>
     <img
      crossOrigin="anonymous"
      src={`${BASE_URL}/restaurant/download_images/food_imgs${food.Food_img}`}
      width={140}
      height={140}
      alt=""
      priority={1}
      className={styles.food_img}
     />
    </div>
    <div className={styles.food_menu_title_cont}>
     <div className={styles.food_name}>{food.Main_dish}</div>
    </div>
    {food.Starter.length >= 1 && (
     <div className={styles.food_sidedish_title}>{STRINGS.sidedish_title}</div>
    )}
    {food.Starter.length == 0 && <div className={styles.empty_sidedish}></div>}
    <div className={styles.food_sidedish}>
     {food.Starter.map((item, index) => (
      <span key={index}>
       {item}
       {index !== food.Starter.length - 1 ? ", " : ""}
      </span>
     ))}
    </div>

    <div className={styles.price_btn_cont}>
     {food?.Complementary &&
     userAuthentication_Details?.booking_details?.length > 0 &&
     userAuthentication_Details?.mapped_Dates[currentSelectedDate] == true
      ? ""
      : `Rs.${food.Price}`}
    </div>
   </div>
   {modalOpen && (
    <Modal
     shouldReturnFocusAfterClose={false}
     isOpen={modalOpen}
     style={modalStyles}
     onRequestClose={closeModal}
     contentLabel={`Food Modal`}
     className={styles.modal_food_details}
    >
     <div className={styles.close_icon_cont} onClick={closeModal}>
      <IoCloseOutline className={styles.close_icon} />
     </div>
     <FoodDetails
      food={food}
      setModalOpen={setModalOpen}
      OrderQuantity={orderQuantity}
      alert_Predict_Func={alert_Predict_Func}
     />
    </Modal>
   )}
  </>
 );
};

export const BreakfastFood = ({ breakfast_food, alert_Predict_Func }) => (
 <FoodItem
  food={{ foodCategory: "breakfast", ...breakfast_food }}
  alert_Predict_Func={alert_Predict_Func}
 />
);

export const LunchFood = ({ lunch_food, alert_Predict_Func }) => (
 <FoodItem
  food={{ foodCategory: "lunch", ...lunch_food }}
  alert_Predict_Func={alert_Predict_Func}
 />
);

export const DinnerFood = ({ dinner_food, alert_Predict_Func }) => (
 <FoodItem
  food={{ foodCategory: "dinner", ...dinner_food }}
  alert_Predict_Func={alert_Predict_Func}
 />
);

export const SnacksFood = ({ snacks_food, alert_Predict_Func }) => (
 <FoodItem
  food={{ foodCategory: "snack", ...snacks_food }}
  alert_Predict_Func={alert_Predict_Func}
 />
);
