import { useContext, useState } from "react";
// icons
import { BiPlus, BiMinus } from "react-icons/bi";
import { IoCloseOutline } from "react-icons/io5";
// context
import { CartContext } from "../../../context/cart_context";
import styles from "../../../styles/foodstyles/cartstyles/CartItem.module.css";
import { MdDeleteOutline } from "react-icons/md";
import { useAlert } from "react-alert";
import { updateCartAllFood } from "../../../helper/localstorage_update";

const CartItemRestaurant = ({ cart_others, index }) => {
 const alert = useAlert(); // Initialize the useAlert hook
 const { removeItemOthers, setCartOthers, cartOthers, userAuthentication_Details } =
  useContext(CartContext);

 //  console.log("Cart restaurants data ==>: ", cart_others);

 //   const [cartOrderQty, setCartOrderQty] = useState(cart_food?.orderQty);

 // let cartOrderQty = cart_food.orderQty;

 // decrease cart order quantity
 //   const decCartOrderQty = () => {
 //     cartOrderQty > 1 ? setCartOrderQty(cartOrderQty - 1) : setCartOrderQty(1);
 //   };

 // increase cart order quantity
 //   const incCartOrderQty = () => {
 //     if (cartOrderQty < 40) {
 //       setCartOrderQty(cartOrderQty + 1);
 //     } else if (cartOrderQty === 40) {
 //       // alert("Maximum order quantity reached (40)");
 //       alert.success("Maximum order quantity should not be more than 40");
 //     }
 //   };

 // Calculate price based on cart quantity
 //   const cartItemPrice = cart_food?.price * cartOrderQty;

 // const handleChange = (e, value, index) => {
 //   console.log(e.target.value, value);
 //   console.error("gettinggggg", index, "twoooo", e.target.value, "geeeeeee");
 //   const neww = value;

 //   const newvalue = { ...neww, food_desc: e.target.value };
 //   console.log(newvalue, "newvalue", index);
 //   cartOthers[index] = newvalue;
 //   setCartOthers([...cartOthers]);
 // };

 const [error, setError] = useState(false);

 const handleChange = (e, value, index) => {
  const inputValue = e.target.value;
  //   console.log(inputValue, value, "handleChange====>");

  if (inputValue.trim() === "") {
   setError(true); // Set error state if textarea is empty
  } else {
   setError(false); // Clear error state if textarea has content
   const neww = value;
   const newvalue = { ...neww, food_desc: inputValue };
   //    console.log(newvalue, "newvalue", index);
   cartOthers[index] = newvalue;
   setCartOthers([...cartOthers]);
   updateCartAllFood(
    { key: 2, value: cartOthers },
    userAuthentication_Details.loggedIn_user_email
   );
  }
 };

 //  console.log("cart item restaurant data == ", cart_others);
 return (
  <div className={styles.cartItem_main_cont}>
   <div className={styles.cartItem_cont}>
    {/* cart item info */}
    <div className={styles.cartItem_info_cont}>
     <div className={styles.cart_item_name_price_cont}>
      {/* hotel name */}
      <div className={styles.cartItem_mainDish_name}>{cart_others.restName}</div>
      {/* delete */}
      <div
       className={styles.delete_item_cont}
       onClick={() => removeItemOthers(cart_others.restId, cart_others.restName)}
      >
       <div className={styles.delete_item_icon}>
        <MdDeleteOutline size={35} />
       </div>
       <div className={styles.delete_item_text}>Remove</div>
      </div>
     </div>
     {/* restaurant food description by user */}
     <div className={styles.special_instruction_cont}>
      <div className={styles.special_ins_txt}>Enter food order description</div>
      <div className={styles.special_ins_input_cont}>
       <textarea
        className={styles.special_ins_user_input1}
        value={cartOthers[index]?.food_desc ? cartOthers[index]?.food_desc : ""}
        // placeholder="Please add food description to order from nearby restaurant."
        rows="4"
        // cols="50"
        maxLength="250"
        onChange={(e) => {
         handleChange(e, cart_others, index);
        }}
        required
       />
       {error && <div className={styles.errorText}>Please enter food description.</div>}
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default CartItemRestaurant;
