import React, { useEffect, useContext, useState } from "react";
import "./App.css";
import PageRoutes from "./router/page_routes";
import { APP_CONFIG } from "./model/constant";
import { CartContext } from "./context/cart_context";

if (!APP_CONFIG.CONSOLE) {
 console.log = function () {};
 console.error = function () {};
 console.warn = function () {};
}

const App = () => {
 const { GetLoggedIn_Func } = useContext(CartContext);
 const [isRendered, setIsRendered] = useState(false);
 console.log("APPLICATION CONFIG========>", APP_CONFIG);

 const getAuth_Func = async () => {
  let values = await GetLoggedIn_Func();
  setIsRendered(true);
  console.log("values======>", values);
 };
 useEffect(() => {
  getAuth_Func();
 }, []);

 return <>{isRendered && <PageRoutes />}</>;
};

export default App;
