const updateCartAllFood = (data, email) => {
 console.log(data);
 let All_FoodDetails = JSON.parse(localStorage.getItem("All_FoodDetails"));
 console.log("all detialss", All_FoodDetails);
 if (All_FoodDetails && email) {
  switch (data.key) {
   case 1:
    All_FoodDetails[email].sbh_food = data.value;
    break;
   case 2:
    All_FoodDetails[email].nearby_food = data.value;
    break;
   case 3:
    All_FoodDetails[email].sbh_special_instruction = data.value;
    break;
   case 4:
    All_FoodDetails[email].roomNumber = data.value;
    break;
   case 5:
    All_FoodDetails[email].sbh_totalPrice = data.value;
    break;
   case 6:
    All_FoodDetails[email].sbh_totalPrice = "";
    All_FoodDetails[email].sbh_special_instruction = "";
    All_FoodDetails[email].sbh_food = [];
    break;
   case 7:
    All_FoodDetails[email].nearbycart_Qty = data.value;
    break;
   case 8:
    All_FoodDetails[email].nearby_food = "";
    All_FoodDetails[email].nearbycart_Qty = 0;
    All_FoodDetails[email].nearby_food = [];
    break;
   case 9:
    All_FoodDetails[email].current_State = data.value;
    break;
  }
  localStorage.setItem("All_FoodDetails", JSON.stringify(All_FoodDetails));
 }
};

/**
 *
 * @param {*} value
 * @param {*} email
 * @returns Number
 */
const getStorage_allData = (value, email) => {
 try {
  let current_value = JSON.parse(localStorage.getItem("All_FoodDetails"));
  console.log("all the values", value, email);
  if (value && email) {
   console.log("getting cjange valuee");
   switch (value) {
    case 9:
     current_value = current_value[email].current_State;
     console.log("geting current value", current_value);
     break;
   }
  }
  return current_value;
 } catch (err) {}
};

/**
 * @description Stores data in async storage provided with setItem_key. setItem_key should be string data type
 * @param {*} setItem_key
 * @example "userDetails"
 * @param {*} data
 */
const setLocalStorage = async (setItem_keys, data, functionCallData) => {
 console.log(":lineNo setLocalStorage: ", setItem_keys, data, functionCallData);
 try {
  setItem_keys.forEach(async (element, index) => {
   await localStorage.setItem(setItem_keys[index], JSON.stringify(data[index]));
  });
 } catch (err) {
  console.log("localstorage.js" + ":lineNo setLocalStorage update error", err);
 }
};

/**
 * @description retreves data from async storage provided with getItem_key. getItem_key should be string data type
 * @param {*} getItem_key
 * @example "userDetails"
 */
const getLocalStorage = async (getItem_key) => {
 console.log("localstorage.js" + ":lineNo getLocalStorage: ", getItem_key);
 try {
  let values = {};
  // Loop through each key and retrieve its value from localStorage
  getItem_key.forEach(async (key) => {
   let data = await localStorage.getItem(key);
   values[key] = data != null && data != "undefined" ? JSON.parse(data) : data;
  });
  return values;
 } catch (err) {
  console.log("getlocalStorage update error", err);
 }
};

const removeLocalStorage = async (keysToRemove) => {
 console.log("localstorage.js" + ":lineNo removeLocalStorage: ", keysToRemove);
 try {
  keysToRemove.forEach(async (key) => {
   await localStorage.removeItem(key);
  });
 } catch (err) {
  console.log("getlocalStorage update error", err);
 }
};

export {
 updateCartAllFood,
 getStorage_allData,
 setLocalStorage,
 getLocalStorage,
 removeLocalStorage,
};
