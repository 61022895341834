import React from "react";
import { STRINGS } from "../../../model/constant";
import { IMAGES } from "../../../helper/images";
import styles from "../../../styles/foodstyles/cartstyles/cart_page_popup.module.css";

const PlaceOrderComponent = ({ orderPlacedSuccess, isRestaurant_show }) => {
 return (
  <div className={styles.confirm_page_overall_view}>
   <div className={styles.confirm_page_middle_overall_view}>
    <div className={styles.confirm_page_middle_child_view1}>
     {orderPlacedSuccess === false ? (
      <img src={IMAGES.order_fail} alt="" className={styles.order_image} />
     ) : (
      <img src={IMAGES.order_confirm} alt="" className={styles.order_image} />
     )}
    </div>
    <div
     className={
      orderPlacedSuccess === false
       ? styles.cancel_page_middle_child_view2
       : styles.confirm_page_middle_child_view2
     }
    >
     {orderPlacedSuccess === false ? STRINGS.order_fail_title : STRINGS.order_confirm_title}
    </div>
    <div>
     <div className={styles.confirm_page_middle_child_view3}>
      {orderPlacedSuccess === false ? STRINGS.order_fail_msg : STRINGS.order_confirm_msg}
     </div>
     <div className={styles.confirm_page_middle_child_view3}>
      {orderPlacedSuccess === false
       ? STRINGS.order_fail_msg_desc
       : STRINGS.order_confirm_msg_desc}
     </div>
    </div>
    {isRestaurant_show == 1 ? (
     <div className={styles.confirm_page_middle_child_view4}>
      {orderPlacedSuccess === false
       ? STRINGS.order_fail_btn_txt
       : STRINGS.other_restaurant_pending}
      <span className={styles.textblock}>Other Restaurants</span>
      tab
     </div>
    ) : isRestaurant_show == 2 ? (
     <div className={styles.confirm_page_middle_child_view4}>
      {orderPlacedSuccess === false
       ? STRINGS.order_fail_btn_txt
       : STRINGS.other_restaurant_pending}
      <span className={styles.textblock}>SBH Food</span>
      tab
     </div>
    ) : (
     <></>
    )}
   </div>
  </div>
 );
};

export default PlaceOrderComponent;
