import React, { useContext } from "react";
import { CartContext } from "../../../context/cart_context";

import styles from "../../../styles/foodstyles/cartstyles/CartBottom.module.css";
import { STRINGS } from "../../../model/constant";

const CartBottom = () => {
  const { cartFoodTotal } = useContext(CartContext);

  return (
    <div className={styles.cartBottom_cont}>
      <div className={styles.your_subtotal_txt}>Your Subtotal</div>
      <div className={styles.subtotal_price_cont}>
        <div>Subtotal</div>
        <div>Rs.{cartFoodTotal}</div>
      </div>
      <div className={styles.extra_charge_note}>
        {STRINGS.addtional_charges}
      </div>
      <div className={styles.total_price_cont}>
        <div>Total</div>
        <div>Rs.{cartFoodTotal}</div>
      </div>
    </div>
  );
};

export default CartBottom;
