import { useContext, useState } from "react";
import { BiPlus, BiMinus } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { updateCartAllFood } from "../../../helper/localstorage_update";
import { CartContext } from "../../../context/cart_context";
import styles from "../../../styles/foodstyles/cartstyles/CartItem.module.css";
import { STRINGS } from "../../../model/constant";

const CartItem = ({ cart_food, Cart_Index }) => {
 const { removeItem, setCartFood, cartFood, userAuthentication_Details } =
  useContext(CartContext);

 const [cartOrderQty, setCartOrderQty] = useState(cart_food?.orderQty);

 // decrease cart order quantity
 const decCartOrderQty = () => {
  // console.log("Drecrease========>", cartFood);
  // console.log(cartFood[Cart_Index].orderQty, "Index");
  if (cartOrderQty > 1) {
   cartFood[Cart_Index].orderQty = cartOrderQty - 1;
   setCartFood([...cartFood]);
   updateCartAllFood(
    { key: 1, value: cartFood },
    userAuthentication_Details.loggedIn_user_email
   );
   setCartOrderQty(cartOrderQty - 1);
   //  console.log(cartFood[Cart_Index]);
  } else {
   cartFood[Cart_Index].orderQty = 1;
   setCartFood([...cartFood]);
   updateCartAllFood(
    { key: 1, value: cartFood },
    userAuthentication_Details.loggedIn_user_email
   );
   setCartOrderQty(1);
  }
 };

 // increase cart order quantity
 const incCartOrderQty = () => {
  // console.log("Increase========>", cartFood);
  if (cartOrderQty < 40) {
   cartFood[Cart_Index].orderQty = cartOrderQty + 1;
   setCartFood([...cartFood]);
   updateCartAllFood(
    { key: 1, value: cartFood },
    userAuthentication_Details.loggedIn_user_email
   );
   setCartOrderQty(cartOrderQty + 1);
  } else if (cartOrderQty === 40) {
   // show maximum cart order quantity reached limit msg
  }
 };

 // Calculate price based on cart quantity
 const cartItemPrice = cart_food?.price * cartFood[Cart_Index].orderQty;

 return (
  <div className={styles.cartItem_main_cont}>
   <div className={styles.cartItem_cont}>
    <div className={styles.cartItem_info_cont}>
     <div className={styles.cart_item_name_price_cont}>
      <div className={styles.cartItem_mainDish_name}>{cart_food.mainDish}</div>
      {cart_food.complementary === true &&
      userAuthentication_Details?.booking_details?.length > 0 ? (
       <div className={styles.price_cont}>Free</div>
      ) : (
       <div className={styles.price_cont}>Rs.{cartItemPrice}</div>
      )}
     </div>
     <div className={styles.cartItem_sidedish_cont}>
      {cart_food.sideDish.map((item, index) => {
       return (
        <div key={index} className={styles.cartItem_sidedish_name}>
         {item}
         {/* {index !== cart_food.sideDish.length - 1 ? ", " : ""} */}
        </div>
       );
      })}
     </div>
     <div className={styles.food_category_cont}>
      <div>Category:</div>
      <div>
       {cart_food.complementary === true &&
        userAuthentication_Details?.booking_details?.length > 0 && (
         <span>complementary</span>
        )}{" "}
       {cart_food.foodCategory}
      </div>
     </div>
     <div className={styles.cart_qty_remove_main_cont}>
      <div className={styles.qty_title_cart}>Qty:</div>
      <div className={styles.qty_cont_cart}>
       <div
        className={styles.cart_qty_inc_cont}
        onClick={decCartOrderQty}
        style={
         cartFood[Cart_Index].orderQty > 1
          ? { cursor: "pointer" }
          : { cursor: "not-allowed", color: "rgba(0, 0, 0, 0.541)" }
        }
       >
        <BiMinus size={22} />
       </div>
       <div className={styles.cart_order_qty_value}>{cartFood[Cart_Index].orderQty}</div>
       <div
        className={styles.cart_qty_dec_cont}
        onClick={incCartOrderQty}
        style={
         cartFood[Cart_Index].orderQty < 40
          ? { cursor: "pointer" }
          : { cursor: "not-allowed", color: "rgba(0, 0, 0, 0.541)" }
        }
       >
        <BiPlus size={22} />
       </div>
      </div>
      <div className={styles.delete_item_cont} onClick={() => removeItem(Cart_Index)}>
       <div className={styles.delete_item_icon}>
        <MdDeleteOutline size={35} />
       </div>
       <div className={styles.delete_item_text}>Remove</div>
      </div>
     </div>
     {cartFood[Cart_Index].orderQty >= 40 && (
      <p className={styles.max_limit_error_note}>{STRINGS.max_limit_error_msg}</p>
     )}
    </div>
   </div>
  </div>
 );
};

export default CartItem;
