import React, { createContext, useEffect, useState } from "react";
import {
 getLocalStorage,
 removeLocalStorage,
 setLocalStorage,
 updateCartAllFood,
} from "../helper/localstorage_update";
import {
 foodPageVerifyMailApi,
 SBH_Cart_Update,
 SBH_Delete_Cart,
 SBH_Get_Cart,
 SBH_Get_Cart_Count,
 SBH_Get_Restaurant_Cart,
 SBH_Restaurant_Cart_Update,
 SBH_Restaurant_Delete_Cart,
 userLogoutAPI,
} from "../helper/http_api";
import { socket_Create_Func } from "../helper/socket_Initlization";
import eventEmitter from "../store/evenemitter_Intializer";
// create context
export const CartContext = createContext();

const CartProvider = ({ children }) => {
 const [isOpenFoodCart, setIsOpenFoodCart] = useState(false);
 const [UserDetails, setUserDetails] = useState({
  verified_user_name: "",
  verified_user_email: "",
  verified_user_phone: "",
  verified_user_booking_dates: "",
  verified_user_roomIds: "",
 });
 const [DateChangeValueforChange, setDateChangeValueforChange] = useState();
 const [special_instruction, setSpecialInstruction] = useState("");
 const [IsCartOpen, setIsCartOpen] = useState(false);
 const [isOpenRestaurantCart, setIsOpenRestaurantCart] = useState(false);
 const [isOpenOrderSuccessModal, setIsOpenOrderSuccessModal] = useState(false);
 const [Arrows, setArrows] = useState(false);
 const [cartFood, setCartFood] = useState([]);
 const [cartOthers, setCartOthers] = useState([]);
 const [cartFoodTotal, setCartFoodTotal] = useState(0);
 const [cartTotalQty, setCartTotalQty] = useState(0);
 const [foodCartRoomNo, setFoodCartRoomNo] = useState(0);
 const [foodCartQtyAmount, setFoodCartQtyAmount] = useState(0);
 const [restaurantCartQtyAmount, setRestaurantCartQtyAmount] = useState(0);
 const [totalcart_Quantity, setTotalcart_Quantity] = useState(0);
 const [currentSelectedDate, setCurrentSelectedDate] = useState("");
 const [userAuthentication_Details, setUserAuthentication_Details] = useState({});
 const [modalVerifyMail, setModalVerifyMail] = useState(false);
 const [nearbyRestaurantTab, setNearbyRestaurantTab] = useState(false);
 const [registeredPopup, setRegisteredPopup] = useState(false);
 const [modalVerifyMobile, setModalVerifyMobile] = useState(false);
 // update item food amount
 useEffect(() => {
  let sbh_quantity = 0;
  if (cartFood?.length) {
   sbh_quantity = cartFood.reduce((a, c) => {
    return a + c.orderQty;
   }, 0);
  }
  setTotalcart_Quantity(sbh_quantity + cartOthers.length);
 }, [cartFood, cartOthers]);

 useEffect(() => {
  eventEmitter.on("Logout_Event", handleLogout_Event);
  return () => {
   eventEmitter.off("Logout_Event", handleLogout_Event);
  };
 }, []);

 // update cart total price
 useEffect(() => {
  if (cartFood?.length) {
   const totalPrice = cartFood.reduce((a, c) => {
    return (
     a +
     Number(
      userAuthentication_Details?.mapped_Dates[currentSelectedDate] == true &&
       c?.complementary === true
       ? 0
       : c.price
     ) *
      c.orderQty
    );
   }, 0);
   setCartFoodTotal(totalPrice);
  }
 }, [cartFood]);

 // add food data to cart
 const addToFoodCart = (foodCategory, mainDish, sideDish, price, orderQty, complementary) => {
  const newItem = {
   foodCategory,
   mainDish,
   sideDish,
   price,
   orderQty,
   complementary,
  };
  console.log("Getting error cart details=====>", cartFood);
  const cartItemIndex = cartFood.findIndex(
   (item) =>
    item.foodCategory === foodCategory &&
    item.mainDish === mainDish &&
    item.sideDish === sideDish &&
    item.price === price &&
    item.orderQty === orderQty &&
    item.complementary === complementary
  );

  const findMainDishExists = cartFood.find(
   (item) => item.mainDish == mainDish && item.foodCategory == foodCategory
  );
  const findMainDishIndex = cartFood.findIndex(
   (item) => item.mainDish == mainDish && item.foodCategory == foodCategory
  );
  if (findMainDishExists) {
   const newCart = [...cartFood];
   newCart[findMainDishIndex].orderQty = orderQty;
   setCartFood(newCart);
   cart_Updation(newCart);
  } else {
   if (cartItemIndex === -1) {
    setCartFood([...cartFood, newItem]);
    cart_Updation([...cartFood, newItem]);
   } else {
    const newCart = [...cartFood];
    newCart[cartItemIndex].amount += 1;
    cart_Updation(newCart);
    setCartFood(newCart);
   }
  }
 };

 const cart_Updation = (cartFood) => {
  const foodCart_Structure_Formation = (cartData) => {
   let cartPayload = {
    user_email: userAuthentication_Details.loggedIn_user_email,
    Cart_data: cartData,
    meal_date: currentSelectedDate,
    spl_ins: special_instruction,
    totalAmount: cartFoodTotal,
   };
   return cartPayload;
  };
  SBH_Cart_Update(foodCart_Structure_Formation(cartFood))
   .then((data) => {
    console.log("getting data", data);
   })
   .catch((err) => {
    //alert handling
   });
 };

 // remove item
 const removeItem = (cartFood_Index) => {
  if (cartFood_Index !== -1) {
   const newCart = [...cartFood];
   newCart.splice(cartFood_Index, 1);
   !newCart?.length && setSpecialInstruction("");
   setCartFood(newCart);
  }
 };

 const foodCart_Structure_Formation = (cartData) => {
  let cartPayload = {
   user_email: userAuthentication_Details.loggedIn_user_email,
   res_details: cartData,
   meal_date: currentSelectedDate,
  };
  return cartPayload;
 };

 /* nearby restaurant cart data */
 const addToOtherCart = async (restId, restName, food_desc) => {
  console.log("getting data==>", restId, restName, food_desc);
  const newItem = {
   restId,
   restName,
   food_desc,
  };
  const cartItemIndex1 = cartOthers.findIndex(
   (item) => item.restId === restId && item.restName === restName
  );
  if (cartItemIndex1 === -1) {
   console.log("getting data1==>", [...cartOthers, newItem]);
   setCartOthers([...cartOthers, newItem]);
   await SBH_Restaurant_Cart_Update(foodCart_Structure_Formation([...cartOthers, newItem]));
  } else {
   const newCart = [...cartOthers];
   newCart[cartItemIndex1];
   setCartOthers(newCart);
   await SBH_Restaurant_Cart_Update(foodCart_Structure_Formation(newCart));
  }
 };

 // remove others cart item
 const removeItemOthers = async (restId, restName) => {
  const itemIndex = cartOthers.findIndex(
   (item) => item.restId === restId && item.restName === restName
  );
  if (itemIndex !== -1) {
   const newCart = [...cartOthers];
   newCart.splice(itemIndex, 1);
   setCartOthers(newCart); // Corrected from setCart to setCartFood
   await SBH_Restaurant_Cart_Update(foodCart_Structure_Formation(newCart));
  }
 };

 const DateConversion = async (dates) => {
  let overallDate = dates.map((date) => {
   return `${date.split("T")[0]}`;
  });
  const sortedDates = overallDate.sort((a, b) => new Date(a) - new Date(b));
  return sortedDates;
 };

 const logout_Func = async (data) => {
  removeLocalStorage([
   "loggedInUserToken",
   "loggedInUserId",
   "loggedIn_user_name",
   "loggedIn_user_phone",
   "loggedIn_user_email",
   "loggedIn_Status",
   "loggedIn_user_mail_status",
   "booking_details",
   "roomIds",
   "mapped_Dates",
   "sessionID",
  ]);
  setUserAuthentication_Details({});
  localStorage.setItem("verified_user_selected_date", "");
  localStorage.setItem("All_FoodDetails", JSON.stringify(""));
  setCartFood([]);
  setCartOthers([]);
  setFoodCartRoomNo(0);
  setCartFoodTotal(0);
  setSpecialInstruction("");
  setCurrentSelectedDate(null);
  setDateChangeValueforChange(null);
  await SBH_Delete_Cart({ user_email: userAuthentication_Details.loggedIn_user_email });
  await SBH_Restaurant_Delete_Cart({
   user_email: userAuthentication_Details.loggedIn_user_email,
  });
 };

 const handleLogout_Event = async (userData) => {
  console.log("Getting event calling duplicate tab handling");
  let sessionData = await getLocalStorage(["sessionID"]);
  console.log("getting user session id", sessionData?.sessionID, userData?.sessionID);
  try {
   const response = await userLogoutAPI({
    userId: userAuthentication_Details.loggedInUserId,
    sessionID: sessionData?.sessionID,
   });
   let { success } = response?.data;
   if (success) {
    logout_Func();
   } else {
   }
  } catch (error) {}
 };
 /**
   const updateMobileVerify_Func = async (data) => {
   * descripiton 
   * @param {*} data 
   */
 const updateMobileVerify_Func = async (data, mobileNumber) => {
  data.booking_details = data.booking_details.length
   ? await DateConversion(data.booking_details)
   : "";
  setLocalStorage(
   ["booking_details", "roomIds", "loggedIn_user_phone", "mapped_Dates"],
   [data.booking_details, data.roomIds, mobileNumber, data.mapped_Dates],
   "Nav.js"
  );
  setCurrentSelectedDate(data.booking_details[0]);
  setDateChangeValueforChange(data.booking_details[0]);
  setUserAuthentication_Details((prev_value) => ({
   ...prev_value,
   booking_details: data.booking_details,
   roomIds: data.roomIds,
   mapped_Dates: data.mapped_Dates,
  }));
  const cartCount = await SBH_Get_Cart_Count({
   user_email: userAuthentication_Details.loggedIn_user_email,
   meal_date: data.booking_details[0],
  });
  setTotalcart_Quantity(cartCount?.totalCount ? cartCount.totalCount : 0);
  getCartDetails(
   userAuthentication_Details.loggedIn_user_email,
   data.booking_details[0],
   data.mapped_Dates
  );
 };

 const getCartDetails = (user_email, meal_Date, mapped_Dates) => {
  SBH_Get_Cart({
   user_email: user_email,
   meal_date: meal_Date,
  })
   .then((data) => {
    if (data?.status) {
     //  console.error("getting cart data===>", data.Cart_data);
     //  if (data?.Cart_data.length && mapped_Dates[meal_Date] == true) {
     //   const complementary_Filteration = data.Cart_data.map((ele) => {
     //    ele.complementary == true ? (ele.price = 0) : "";
     //    return ele;
     //   });
     //   cart_Updation(complementary_Filteration);
     //   setCartFood(complementary_Filteration);
     //  } else {
     //   setCartFood([...data.Cart_data]);
     //   }
     setCartFood([...data.Cart_data]);
     setSpecialInstruction(data?.spl_ins ? data.spl_ins : "");
     Get_restaurant_api_func(user_email, meal_Date);
    } else {
     //  console.log("No data in Food cart");
     Get_restaurant_api_func(user_email, meal_Date);
    }
   })
   .catch((err) => {
    console.log("Cart error=======>>", err?.message);
    // alert_Predict_Func(STRINGS.somethingunexpected);
   });
 };

 const Get_restaurant_api_func = (user_email, meal_Date) => {
  SBH_Get_Restaurant_Cart({
   user_email: user_email,
   meal_date: meal_Date,
  })
   .then((data) => {
    if (data?.status) {
     setCartOthers([...data.res_details]);
    }
   })
   .catch((err) => {
    console.log("Cart error=======>>", err?.message);
   });
 };

 const GetLoggedIn_Func = async () => {
  let isAuth = await getLocalStorage(["loggedInUserId"]);
  if (isAuth?.loggedInUserId) {
   let Auth_Data = await getLocalStorage([
    "loggedInUserToken",
    "loggedInUserId",
    "loggedIn_user_name",
    "loggedIn_user_phone",
    "loggedIn_user_email",
    "loggedIn_Status",
    "loggedIn_user_mail_status",
    "booking_details",
    "roomIds",
    "mapped_Dates",
    "sessionID",
   ]);
   const login_api_payload = {
    phone: Auth_Data.loggedIn_user_phone,
   };
   try {
    // console.error("booking changes===>", Auth_Data?.loggedIn_user_phone);
    const response = await foodPageVerifyMailApi(login_api_payload);
    let meal_Date;
    if (response?.data?.status) {
     let selectedDateCheck = localStorage.getItem("verified_user_selected_date");
     Auth_Data.booking_details = await DateConversion(response?.data?.booking_details);
     Auth_Data.roomIds = response?.data?.roomIds;
     Auth_Data.mapped_Dates = response?.data?.mapped_Dates;
     selectedDateCheck = Auth_Data.booking_details.find((ele) => ele == selectedDateCheck);
     setUserAuthentication_Details(Auth_Data);
     if (selectedDateCheck) {
      setCurrentSelectedDate(selectedDateCheck);
      setDateChangeValueforChange(selectedDateCheck);
      meal_Date = selectedDateCheck;
      //  getAllFoodCart_Data(false);
     } else {
      localStorage.setItem("verified_user_selected_date", Auth_Data.booking_details[0]);
      setCurrentSelectedDate(Auth_Data.booking_details[0]);
      setDateChangeValueforChange(Auth_Data.booking_details[0]);
      meal_Date = Auth_Data.booking_details[0];
      //  getAllFoodCart_Data(true);
      //cart data null
     }
     const cartCount = await SBH_Get_Cart_Count({
      user_email: Auth_Data.loggedIn_user_email,
      meal_date: meal_Date,
     });
     setTotalcart_Quantity(cartCount?.totalCount ? cartCount.totalCount : 0);
     getCartDetails(Auth_Data.loggedIn_user_email, meal_Date, Auth_Data.mapped_Dates);
    } else {
     if (response?.data?.statusCode == 205) {
      Auth_Data.booking_details = [];
      Auth_Data.roomIds = [];
      Auth_Data.mapped_Dates = {};
     }
     setCurrentSelectedDate(
      Auth_Data.booking_details?.length ? Auth_Data.booking_details?.length[0] : ""
     );
     setDateChangeValueforChange(
      Auth_Data.booking_details?.length ? Auth_Data.booking_details[0] : ""
     );
     setUserAuthentication_Details(Auth_Data);
    }
   } catch (err) {
    console.log("GEtting error in catch", err);
    //handling
   }
   socket_Create_Func({
    userId: Auth_Data?.loggedInUserId,
    sessionId: Auth_Data?.sessionID,
   });
   return true;
  } else {
   return true;
  }
 };

 /**
  * @description update LoggedIn state
  * @param {*} data
  */
 const updateLoggedIn_Func = async (data) => {
  data.booking_details = data?.booking_details?.length
   ? await DateConversion(data?.booking_details)
   : "";
  setCurrentSelectedDate(data?.booking_details[0]);
  setDateChangeValueforChange(data?.booking_details[0]);
  console.log("getting updatelogged in", data);
  setLocalStorage(
   [
    "loggedInUserToken",
    "loggedInUserId",
    "loggedIn_user_name",
    "loggedIn_user_phone",
    "loggedIn_user_email",
    "loggedIn_Status",
    "loggedIn_user_mail_status",
    "booking_details",
    "roomIds",
    "mapped_Dates",
    "sessionID",
   ],
   [
    data?.access_token,
    data?._id,
    data?.name,
    data?.phone,
    data?.email,
    data?.loginStatus,
    data?.isVerified,
    data?.booking_details,
    data?.roomIds,
    data?.mapped_Dates,
    data?.session_Id,
   ],
   "cartContext.js"
  );
  setUserAuthentication_Details({
   loggedInUserToken: data?.access_token,
   loggedInUserId: data?._id,
   loggedIn_user_name: data?.name,
   loggedIn_user_phone: data?.phone,
   loggedIn_user_email: data?.email,
   loggedIn_Status: data?.loginStatus,
   loggedIn_user_mail_status: data?.isVerified,
   booking_details: data?.booking_details,
   roomIds: data?.roomIds,
   mapped_Dates: data?.mapped_Dates,
  });
  socket_Create_Func({ userId: data?._id, sessionId: data?.session_Id });
  const cartCount = await SBH_Get_Cart_Count({
   user_email: data?.email,
   meal_date: data?.booking_details[0],
  });
  setTotalcart_Quantity(cartCount?.totalCount ? cartCount.totalCount : 0);
  getCartDetails(data?.email, data?.booking_details[0], data?.mapped_Dates);
 };

 // decrease cart order quantity
 const decCartOrderQty = (cartOrderQty, Cart_Index) => {
  if (cartOrderQty > 1) {
   cartFood[Cart_Index].orderQty = cartOrderQty - 1;
   setCartFood([...cartFood]);
  } else {
   cartFood[Cart_Index].orderQty = 1;
   setCartFood([...cartFood]);
  }
 };

 // increase cart order quantity
 const increaseOrderQty = (cartOrderQty, Cart_Index) => {
  // console.log("Increase========>", cartFood);
  if (cartOrderQty < 40) {
   cartFood[Cart_Index].orderQty = cartOrderQty + 1;
   //  setFoodCartQtyAmount((prev) => prev + 1);
   setCartFood([...cartFood]);
  } else if (cartOrderQty === 40) {
   // show maximum cart order quantity reached limit msg
  }
 };

 return (
  <CartContext.Provider
   value={{
    isOpenFoodCart,
    setIsOpenFoodCart,
    addToFoodCart,
    addToOtherCart,
    isOpenRestaurantCart,
    setIsOpenRestaurantCart,
    cartFood,
    cartOthers,
    nearbyRestaurantTab,
    setNearbyRestaurantTab,
    removeItem,
    removeItemOthers,
    cartFoodTotal,
    modalVerifyMail,
    setModalVerifyMail,
    addToOtherCart,
    currentSelectedDate,
    setCurrentSelectedDate,
    cartTotalQty,
    setCartTotalQty,
    foodCartQtyAmount,
    restaurantCartQtyAmount,
    foodCartRoomNo,
    setFoodCartRoomNo,
    isOpenOrderSuccessModal,
    setIsOpenOrderSuccessModal,
    setCartFood,
    setCartOthers,
    setUserDetails,
    UserDetails,
    setRestaurantCartQtyAmount,
    setIsCartOpen,
    IsCartOpen,
    special_instruction,
    setSpecialInstruction,
    setCartFoodTotal,
    setDateChangeValueforChange,
    DateChangeValueforChange,
    setArrows,
    Arrows,
    userAuthentication_Details,
    setUserAuthentication_Details,
    registeredPopup,
    setRegisteredPopup,
    modalVerifyMobile,
    setModalVerifyMobile,
    GetLoggedIn_Func,
    updateLoggedIn_Func,
    updateMobileVerify_Func,
    logout_Func,
    handleLogout_Event,
    decCartOrderQty,
    increaseOrderQty,
    setFoodCartQtyAmount,
    totalcart_Quantity,
    setTotalcart_Quantity,
   }}
  >
   {children}
  </CartContext.Provider>
 );
};

export default CartProvider;
