import React from "react";
import "../../styles/foodstyles/DropdownInput.css";
import { userTimezone } from "../../model/constant";

const DropdownInput = ({ dates, onChange, selectedDate }) => {
 // Sort the dates array in ascending order
 //  console.log("dateeeeee", dates, selectedDate);
 return (
  <div
   className="dropdown-container"
   tabIndex={0}
   style={{ outlineColor: "rgba(51, 24, 18, 1)" }}
  >
   <select className="dropdown-input" onChange={onChange} value={selectedDate}>
    {dates.map((date, index) => (
     <option key={index} value={date}>
      {date}
     </option>
    ))}
   </select>
  </div>
 );
};

export default DropdownInput;
