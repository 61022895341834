let bookingData = [];
let fromBooking = false;

const setBookingFunc = (data) => {
  bookingData = data;
};

const getBookingFunc = () => {
  return bookingData;
};

const setBookFunc = (data) => {
  fromBooking = data;
};

const getBookFunc = () => {
  return fromBooking;
};

const setBookingSummaryDataFunc = (data) => {
  localStorage.setItem("booking_summary_data", JSON.stringify(data));
};

const getBookingSummaryDataFunc = () => {
  return localStorage.getItem("booking_summary_data");
};

const setPaymentSummaryDataFunc = (data) => {
  localStorage.setItem("payment_summary_data", JSON.stringify(data));
};

const getPaymentSummaryDataFunc = () => {
  return localStorage.getItem("payment_summary_data");
};

export {
  setBookingFunc,
  getBookingFunc,
  setBookFunc,
  getBookFunc,
  setBookingSummaryDataFunc,
  getBookingSummaryDataFunc,
  setPaymentSummaryDataFunc,
  getPaymentSummaryDataFunc,
};
