import React from "react";
import { STRINGS } from "../../model/constant";
import { IMAGES } from "../../helper/images";
import styles from "../../styles/foodstyles/place_order_success_fail_msg.module.css";

const PlaceOrderMsg = ({ onClickContinue, orderPlacedSuccess }) => {
 return (
  <div className={styles.confirm_page_overall_view}>
   <div className={styles.confirm_page_header_overall_view}>
    <div className={styles.logo_view}>
     <img src={IMAGES.logo} alt="" width={75} height={75} className={styles.logo_img} />
    </div>
   </div>
   <div className={styles.confirm_page_middle_overall_view}>
    <div className={styles.confirm_page_middle_child_view1}>
     {orderPlacedSuccess === false ? (
      <img src={IMAGES.order_fail} alt="" className={styles.order_image} />
     ) : (
      <img src={IMAGES.order_confirm} alt="" className={styles.order_image} />
     )}
    </div>
    <div
     className={
      orderPlacedSuccess === false
       ? styles.cancel_page_middle_child_view2
       : styles.confirm_page_middle_child_view2
     }
    >
     {orderPlacedSuccess === false ? STRINGS.order_fail_title : STRINGS.order_confirm_title}
    </div>
    <div>
     <div className={styles.confirm_page_middle_child_view3}>
      {orderPlacedSuccess === false ? STRINGS.order_fail_msg : STRINGS.order_confirm_msg}
     </div>
     <div className={styles.confirm_page_middle_child_view3}>
      {orderPlacedSuccess === false
       ? STRINGS.order_fail_msg_desc
       : STRINGS.order_confirm_msg_desc}
     </div>
    </div>
    <div className={styles.confirm_page_middle_child_view4} onClick={onClickContinue}>
     {orderPlacedSuccess === false
      ? STRINGS.order_fail_btn_txt
      : STRINGS.order_confirm_btn_txt}
    </div>
   </div>
  </div>
 );
};

export default PlaceOrderMsg;
