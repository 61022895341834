import { Empty_cart } from "../../../assets/svg/cart_svg";
import { STRINGS } from "../../../model/constant";
import styles from "../../../styles/foodstyles/cartstyles/cart_page.module.css";
const Empty_cart_component = ({ goHome }) => {
 return (
  <div className={styles.empty_container}>
   <Empty_cart className={styles.emptycart_svg} />
   <div className={styles.cartempty_txt}>{STRINGS.empty_cart_txt}</div>
   <div
    className={styles.goHome}
    tabIndex={0}
    onKeyDown={(event) => {
     if (event.key == "Enter") {
      goHome();
     }
    }}
    onClick={() => goHome()}
   >
    {STRINGS.go_home}
   </div>
  </div>
 );
};

export { Empty_cart_component };
