import { useState, useEffect } from "react";

/* function to check whether the device screen is small screen or landscape view */
function checkWindowSize() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 912);

      setIsLandscape(
        window.matchMedia("(max-height: 575.98px) and (orientation: landscape)")
          .matches
      );
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { isSmallScreen, isLandscape };
}

export default checkWindowSize;
