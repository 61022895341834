import { IoCloseOutline } from "react-icons/io5";
import styles from "../../styles/foodstyles/date_change_alert_msg.module.css";
import { STRINGS } from "../../model/constant";

const DateChangeAlert = ({ onClickClose, onClickOk }) => {
 return (
  <>
   <div className={styles.cancel_icon_view}>
    <IoCloseOutline onClick={onClickClose} className={styles.closeicon} size={20} />
   </div>
   <div className={styles.title_view}>{STRINGS.alert_title}</div>
   <div className={styles.overall_view_date_change_popup}>{STRINGS.date_change_alert_msg}</div>
   <div className={styles.cancel_overall_view}>
    <div onClick={onClickOk} className={styles.ok_button_view}>
     {STRINGS.date_change_alert_btn_txt}
    </div>
   </div>
  </>
 );
};

export default DateChangeAlert;
