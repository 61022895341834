import React, { useState, useRef } from "react";
import { IMAGES } from "../helper/images";
import { Parallax } from "react-parallax";
import { CiMail } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { userForgotPasswordApi } from "../helper/http_api";
import { useAlert } from "react-alert";
import { setBookFunc } from "../helper/temporary_data";
import HeaderWithIcon from "../components/commoncomponents/header_home_icon";
import styles from "../styles/forgot_password.module.css";

function ForgotPassword() {
  const InputRef = useRef(null);

  const [userForgotPasswordData, setUserForgotPasswordData] = useState({
    userEmail: "",
  });

  const alert = useAlert(); // Initialize the useAlert hook

  const [showSpinner, setShowSpinner] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    /* custom validity for email */
    if (name === "userEmail") {
      const emailPattern = /^[a-z0-9._%+]+@[a-z0-9.]+\.[a-z]{2,}$/i;
      if (!emailPattern.test(value)) {
        e.target.setCustomValidity("Please enter a valid email Id.");
      } else {
        e.target.setCustomValidity("");
      }

      if (value.length === 0) {
        e.target.setCustomValidity("Email is a required field.");
      }
    }

    setUserForgotPasswordData((prevUserForgotPasswordData) => ({
      ...prevUserForgotPasswordData,
      [name]: value,
    }));
  };

  const forgotPassword_api_payload = {
    email: userForgotPasswordData.userEmail,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    // disabling button
    setIsButtonDisabled(true);

    const forgotPasswordPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await userForgotPasswordApi(
          forgotPassword_api_payload
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });

    forgotPasswordPromise
      .then((data) => {
        const { success, message, id } = data;
        // setShowSpinner(false);

        setTimeout(() => {
          setShowSpinner(false);
          if (success) {
            // console.log("Forgot Password Code Res ==>", data);
            alert.success(message);
            const queryParams = new URLSearchParams({
              forgotpassword_mail: forgotPassword_api_payload.email,
              userId: id,
            });
            navigate(`/user/auth/forgot_password_verify?${queryParams}`);
          } else {
            // console.log("Forgot Password Code Res ==>", data);
            alert.error(message);
          }
          // enabling button
          setTimeout(() => {
            setIsButtonDisabled(false);
          }, 4000);
        }, 1000);
      })
      .catch((error) => {
        setShowSpinner(false);
        console.error(
          "Error occurred while verifying user mail for forgot password in server",
          error
        );
        alert.error("Oops! Something unexpected happened.");
        // enabling button
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 4000);
      });
  };

  const handleHomeClick = () => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
      setBookFunc(false);
      navigate(`/`);
    }, 1000);
  };

  return (
    <div>
      <Parallax
        // blur={10}
        bgImage={IMAGES.bgImg_section1}
        bgImageAlt="section1"
        // strength={120}
      >
        <div className={styles.content}>
          {/* active spinner */}
          {showSpinner && (
            <div className={styles.spinner_layout}>
              <SpinnerDotted color="white" />
            </div>
          )}

          <HeaderWithIcon HomeIconClick={handleHomeClick} />

          <form onSubmit={handleSubmit} className={styles.form_container}>
            <div className={styles.forgotPassword1_details_cont}>
              <div className={styles.forgotPassword_title}>Forgot Password</div>
              <div className={styles.user_input}>
                <div className={styles.input_field_cont}>
                  <input
                    type="email"
                    placeholder="Email"
                    className={styles.input_field1}
                    name="userEmail"
                    value={userForgotPasswordData.userEmail}
                    onChange={handleChange}
                    required
                    autoComplete="off"
                    maxLength="30"
                    style={{ paddingRight: "30px" }} // text overlaying the icon issue
                    // pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                    ref={InputRef}
                  />
                  <div className={styles.input_icons}>
                    <CiMail size={20} />
                  </div>
                </div>
              </div>
            </div>
            {/* abc */}
            <div className={styles.login_details_cont1}>
              <div className={styles.below_upper_cont}>
                <div className={styles.submit_btn_cont}>
                  <button
                    type="submit"
                    className={styles.btn_submit}
                    disabled={isButtonDisabled}
                  >
                    <div className={styles.btn_cont}>
                      <div className={styles.btn_name}>Submit</div>
                    </div>
                  </button>
                </div>
              </div>
              <div className={styles.below_lower_cont}>
                Enter your registered email id.
              </div>
            </div>
          </form>
        </div>
      </Parallax>
    </div>
  );
}

export default ForgotPassword;
