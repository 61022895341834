import React from "react";
import { ImHome } from "react-icons/im";
import styles from "../../styles/header_home_icon.module.css";

export default function Header(props) {
  return (
    <div className={styles.header_container}>
      <header className={styles.primary_header}>
        <nav className={styles.header_nav}>
          <div className={styles.home_icon_cont} onClick={props.HomeIconClick}>
            <ImHome size={22} color="#FFB000" />
            <div className={styles.home_title}>Home</div>
          </div>
        </nav>
      </header>
    </div>
  );
}
