const STRINGS = {
 no_data_found_msg_registered:
  "We regret to inform you that food menu is not accessible at this moment. Kindly contact our staff regarding food options for the specific date(s)",
 breakfast_title: "Breakfast",
 lunch_title: "Lunch",
 dinner_title: "Dinner",
 snacks_title: "Snacks",
 other_restaurants_mobile: "Other Restaurants",
 other_restaurants: "Nearby Available Restaurants",
 alert_title: "Alert!",
 date_change_alert_msg:
  "Please finalize your current order before adding items for another date. Proceeding further would delete items in your cart. Would you like to proceed?",
 date_change_alert_btn_txt: "Ok",
 order_confirm_title: "Order Confirmed",
 order_fail_title: "OOPS!!!",
 order_fail_msg:
  "Sorry for the inconvenience, we couldn't process your order at this moment. Please reach out to our staff for assistance.",
 order_confirm_msg:
  "Thank you for your order! Sengal Boutique Hotel team will  ensure it’s prepared and ready for you right on time. Please feel free to reach out to us in case of any questions.",
 order_fail_msg_desc: "We appreciate your patience and understanding. Thank you!",
 order_confirm_msg_desc: "Thanks for choosing Sengal Boutique Hotel",
 order_fail_btn_txt: "Back to Home Page",
 order_confirm_btn_txt: "Continue Ordering",
 empty_cart_txt: " There are no items in your cart at the moment.",
 order_place_btn_txt: "Place Your Order   ",
 special_ins_txt: "Special Instructions",
 foodorder_description: "Enter Food order description",
 your_order_txt: "your order",
 sidedish_title: "Sidedishes",
 qty_title: "Quantity",
 verify_prefix_txt: "Please",
 verify_booking_identity: "verify booking identity",
 verify_email_txt: "verify email",
 rem_mail_verify_note: "to proceed with ordering food",
 sign_in_field: "sign in",
 or_field: "or",
 sign_up_field: "sign up",
 add_to_cart_btn_txt: "Add to cart for free",
 add_to_cart_txt: "Add to cart",
 signout_alert_msg:
  "Signing out will clear all the items in your cart. Do you still want to proceed?",
 view_website: "View Website",
 sign_out: "SIGN OUT",
 verify_email: "verify email",
 addtional_charges: "*Additional charges, including taxes, will be applicable",
 admin_contact_txt: "Admin will contact you at once your order is placed.",
 nodate_found: "No bookings found under your name. Click to logout.",
 logout: "Are you sure you want to sign out?",
 Sign_in_text: "SIGN IN",
 entervalidepwd: "Please enter a valid password.",
 pwdatleastChar: "Password must be at least 8 characters long.",
 pwdNotgreater: "Password cannot be longer than 16 characters.",
 pwdRequired: "Password is a required field.",
 validmail: "Please enter a valid email Id.",
 emailRequired: "Email is a required field.",
 pwdnotmatch: "Passwords does not match. Please try again",
 confirmpwd: "Please enter a valid confirm password.",
 confirmpwdatleast: "Confirm password must be at least 8 characters long.",
 confirmpwdnotgreater: "Confirm password cannot be longer than 16 characters.",
 confirmrequired: "Confirm password is a required field.",
 phn10digit: "Phone number must be 10 digits long.",
 invalidphn: "Invalid phone number.",
 alphabeticchar: "Please enter alphabetic characters only.",
 nameshouldnotlessthan3: "Name shouldn't be less than 3 characters.",
 nameshoudnotmorethan26: "Name shouldn't be more than 26 characters.",
 namerequired: "Name is a required field.",
 verificationCode: "Verification code must be 6 digits.",
 newanconfirm: "New Password and Confirm Password do not match.",
 websitenotavailable:
  "Apologies, there is no website set up for the restaurant. Please contact staff directly",
 addtocartcutoff:
  "Sorry, the cutoff time for ordering has elapsed. Kindly choose an alternate date or meal type",
 occupant_identity: "Occupant Identity Verification",
 bookingPhonenumber: "Enter your booking mobile number",
 somethingunexpected: "Oops! Something unexpected happened.",
 welcomeBackLoggedin: "Welcome back! You're now logged in.",
 nobookingrecord:
  "Sorry, there are no bookings reserved under this number. Kinldy contact our staff regarding food options for the specific date(s).",
 welcomeidentity: "Welcome, occupant identity verified.",
 loggedoutToast: "User logged out successfully",
 cart_title: "CART",
 reserv_rooms: "Reserved Room(s) : ",
 meal_date: "Meal Date :",
 sbh_food: "SBH FOOD",
 other_res: "OTHER RESTAURANTS",
 yourorders: "Your Orders",
 qty: "Qty:",
 remove: "Remove",
 specialinstruct: "Special Instructions",
 yourtotal: "Your Total",
 total: "Total",
 chargesincl: "*Please note that additional charges, including taxes, will apply.",
 other_restaurant_pending: "Your order is still pending kindly visit",
 order_place_before: "Kindly place your order before",
 go_home: "Go home",
 restaurant_place:
  "Sengal Boutique Hotel offers an at-cost food delivery service. Our staff will be in touch with you once your order is placed.",
 complementarybreakfast: "*Includes complimentary breakfast",
 registeration_success: "User registration successful",
 item_not_found: "Sorry, we couldn't find the requested food item.",
 max_limit_error_msg:"Sorry, orders for this item have reached the maximum limit.",
};
const userTimezone = "Asia/Kolkata"; // Override default to IST for users in India
// Convert timestamp to user's timezone for display
let APP_CONFIG = {};
switch (process.env.REACT_APP_ENV_MODE) {
 case "DEVELOPMENT":
  APP_CONFIG = {
   CONSOLE: true,
   API_URL: process.env.REACT_APP_DEV_API_URL,
   RAZOR_PAY_ID: process.env.REACT_APP_DEV_RAZOR_PAY_KEY_ID,
   RAZOR_PAY_SECRET_KEY: process.env.REACT_APP_DEV_RAZOR_PAY_KEY_SECRET,
   SOCKET_IO_URL: process.env.REACT_APP_DEV_SOCKETIO_URL,
  };
  break;
 case "STAGING":
  APP_CONFIG = {
   CONSOLE: false,
   API_URL: process.env.REACT_APP_STAGE_API_URL,
   RAZOR_PAY_ID: process.env.REACT_APP_STAGE_RAZOR_PAY_KEY_ID,
   RAZOR_PAY_SECRET_KEY: process.env.REACT_APP_STAGE_RAZOR_PAY_KEY_SECRET,
   SOCKET_IO_URL: process.env.REACT_APP_STAGE_SOCKETIO_URL,
  };
  break;
 case "PRODUCTION":
  APP_CONFIG = {
   CONSOLE: false,
   API_URL: process.env.REACT_APP_PROD_API_URL,
   RAZOR_PAY_ID: process.env.REACT_APP_PROD_RAZOR_PAY_PROD_KEY_ID,
   RAZOR_PAY_SECRET_KEY: process.env.REACT_APP_PROD_RAZOR_PAY_PROD_KEY_SECRET,
   SOCKET_IO_URL: process.env.REACT_APP_PROD_SOCKETIO_URL,
  };
  break;
 default:
  APP_CONFIG = {
   CONSOLE: false,
   API_URL: process.env.REACT_APP_STAGE_CORE_API_URL,
   RAZOR_PAY_ID: process.env.REACT_APP_STAGE_RAZOR_PAY_KEY_ID,
   RAZOR_PAY_SECRET_KEY: process.env.REACT_APP_STAGE_RAZOR_PAY_KEY_SECRET,
   SOCKET_IO_URL: process.env.REACT_APP_STAGE_SOCKETIO_URL,
  };
  break;
}
export { STRINGS, APP_CONFIG, userTimezone };
