// import axios from "axios";
import { BASE_URL } from "./base_url.js";
import { axiosInstance } from "./http_interceptor.js";

let config = {
 headers: {
  "Content-Type": "application/json",
 },
 timeout: 8000,
};

// console.log(`BASE_URL: ${BASE_URL}`);

/* ===================== Hotel Website Apis =====================  */

/* API for checking room availability */
export const checkRoomAvailabilityApi = async (payload) => {
 //  console.log("#Room availability check request payload ===> ", { payload });
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/booking/isRoomAvailable`,
   payload,
   config
  );
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for storing user's booking details into database */
export const storeBookingDetailsApi = async (payload) => {
 //  console.log("#Store booking details request payload ===> ", { payload });
 try {
  const response = await axiosInstance.post(`${BASE_URL}/booking/booknow`, payload, config);
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for discount coupon verify */
export const couponCodeApi = async (payload) => {
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/coupon/verifyCoupon`,
   payload,
   config
  );
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for remove applied discount coupon */
export const removeCouponApi = async (payload) => {
 //  console.log("#Remove discount coupon request payload ===> ", {
 //   payload,
 //  });
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/coupon/removeCoupon`,
   payload,
   config
  );
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for user login */
export const userLoginApi = async (payload) => {
 //  console.log("#User login request payload ===> ", { payload });
 try {
  const response = await axiosInstance.post(`${BASE_URL}/auth/login`, payload, config);
  //   response?.data?.status ? setLocalStorage(["sessionID"], [response.data.sessionID]) : "";
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for new user registration */
export const userRegisterApi = async (payload) => {
 //  console.log("#User register request payload ===> ", { payload });
 try {
  const response = await axiosInstance.post(`${BASE_URL}/auth/register`, payload, config);
  return response;
 } catch (error) {
  return error;
 }
};

/* API for user's forgot password email check */
export const userForgotPasswordApi = async (payload) => {
 //  console.log("#User forgotpassword email check request payload ===> ", {
 //   payload,
 //  });
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/auth/requestPasswordReset`,
   payload,
   config
  );
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for user's forgot password OTP check */
export const userForgotPasswordVerifyApi = async (payload) => {
 //  console.log("#User forgotpassword otp check request payload ===> ", {
 //   payload,
 //  });
 try {
  const response = await axiosInstance.post(`${BASE_URL}/auth/verification`, payload, config);
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for user's forgot password OTP check */
export const userLogoutAPI = async (payload) => {
 try {
  const response = await axiosInstance.post(`${BASE_URL}/auth/logout`, payload, config);
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for OTP resend */
export const resendVerifyCodeApi = async (payload) => {
 //  console.log("#Resend OTP request payload ===> ", {
 //   payload,
 //  });
 try {
  const response = await axiosInstance.post(`${BASE_URL}/auth/resendCode`, payload, config);
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for set new password  */
export const setNewPasswordApi = async (payload) => {
 //  console.log("#Set new passoword request payload ===> ", {
 //   payload,
 //  });
 try {
  const response = await axiosInstance.post(`${BASE_URL}/auth/passwordReset`, payload, config);
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for retrieving hotel rooms details */
export const getRoomDetailsApi = async (payload) => {
 //  console.log("#Room details get request payload ===> ", {
 //   payload,
 //  });
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/rooms/info/getRoomInfo`,
   payload,
   config
  );
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for retrieving all the images and video records from database */
export const GetAllImagesApi = async (payload) => {
 //  console.log("#Media video/images get request payload ===> ", {
 //   payload,
 //  });
 try {
  const response = await axiosInstance.get(
   `${BASE_URL}/sengal_admin/getAll_Files`,
   payload,
   config
  );
  return response;
  // return response.data;
 } catch (error) {
  throw error;
 }
};

/* ===================== Beverages Menu and Feedback Form Apis =====================  */

/* API for retrieving beverages menu page data */
export const getBeveragesDetailsApi = async () => {
 //  console.log("#Beverages menu data get request payload ===> ", {
 //   payload,
 //  });
 try {
  const response = await axiosInstance.get(`${BASE_URL}/Beverages/get_Beverages`, config);
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for storing user's feedback form data and sending form as attachment to admin */
export const customerFeedbackFormApi = async (payload) => {
 //  console.log("#Store feedback form data get request payload ===> ", {
 //   payload,
 //  });
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/Feedback/Update_Feedback`,
   payload,
   config
  );
  return response;
  // return response.data;
 } catch (error) {
  throw error;
 }
};

/* ===================== Food Page Apis =====================  */

/* API for getting common food menu data from server */
export const getCommonFoodMenuDataApi = async (params) => {
 //  console.log("request params get food menu data ===> ", params);
 try {
  const response = await axiosInstance.get(
   `${BASE_URL}/order/get_common_food_details?page=${params.page_number}&pageSize=${params.page_size}&mealType=${params.meal_type}`,
   config
  );
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for getting nearby restaurant data from server */
export const getNearbyRestaurantDataApi = async (params) => {
 //  console.log("request params get restaurant data ===> ", params);
 try {
  const response = await axiosInstance.get(
   `${BASE_URL}/foodMenu/get_all_food?page=${params.page_number}&pageSize=${params.page_size}&decider=${params.decider}`,
   config
  );
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for getting food menu data based on selected date  */
export const getDatewiseFoodMenuDataApi = async (params) => {
 //  console.log("#request params datewise menu payload ===> ", {
 //   params,
 //  });
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/order/get_calendar_food_details?mealType=${params.meal_type}`,
   params.payload,
   config
  );
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for food page items search */
export const foodPageItemSearchApi = async (params) => {
 //  console.log("#search items request params  ===> ", {
 //   params,
 //  });
 try {
  if (!params.date_value) {
   //    console.log("No Dvalue");
   //    console.log(
   //     `${BASE_URL}/order/get_food_based_on_search_query?search=${params.search_query}&searchType=${params.search_type}&page=${params.page_number}&pageSize=${params.page_size}`
   //    );
   const response = await axiosInstance.get(
    `${BASE_URL}/order/get_food_based_on_search_query?search=${params.search_query}&searchType=${params.search_type}&page=${params.page_number}&pageSize=${params.page_size}`,
    config
   );
   return response;
  } else if (params.date_value) {
   //    console.log("With Dvalue");
   //    console.log(
   //     `${BASE_URL}/order/get_food_based_on_search_query?search=${params.search_query}&searchType=${params.search_type}&page=${params.page_number}&pageSize=${params.page_size}&Dvalue=${params.date_value}`
   //    );
   //    console.log(JSON.stringify(params.payload));
   const response = await axiosInstance.get(
    `${BASE_URL}/order/get_food_based_on_search_query?search=${params.search_query}&searchType=${params.search_type}&page=${params.page_number}&pageSize=${params.page_size}&Dvalue=${params.date_value}`,
    config
   );
   return response;
  }
 } catch (error) {
  throw error;
 }
};

/* API for updating/storing food order records into database */
export const storeFoodOrderDataApi = async (payload) => {
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/order/add_order_details`,
   payload,
   config
  );
  return response;
 } catch (error) {
  return error;
 }
};
/* API for updating/storing restaurant order records into database */
export const storeRestaurantOrderDataApi = async (payload) => {
 //  console.log("#Store restaurant order details request payload ===> ", {
 //   payload,
 //  });
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/restaurantOrder/add_restaurant_order_details`,
   payload,
   config
  );
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for verifying user's booking mail id */
export const foodPageVerifyMailApi = async (payload) => {
 //  console.log("#User verify mail request payload ===> ", {
 //   payload,
 //  });
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/users/food_login_booking_records`,
   payload,
   config
  );
  return response;
 } catch (error) {
  return error;
 }
};

/* API for getting food cut off time from server */
export const getCutOffTimeApi = async () => {
 try {
  const response = await axiosInstance.get(`${BASE_URL}/mealtime/get_meal_time`, config);
  return response;
 } catch (error) {
  throw error;
 }
};

/* API for cart update details */
export const SBH_Cart_Update = async (payload) => {
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/cart/sbh_cartUpdate`,
   payload,
   config
  );
  return response;
 } catch (error) {
  return error;
 }
};
/* API for cart details */
export const SBH_Delete_Cart = async (payload) => {
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/cart/sbh_cartDelete`,
   payload,
   config
  );
  return response;
 } catch (error) {
  return error;
 }
};

/* API for cart details */
export const SBH_Get_Cart = async (payload) => {
 try {
  const response = await axiosInstance.get(
   `${BASE_URL}/cart/get_FoodCart?user_email=${payload.user_email}&&meal_date=${payload.meal_date}`,
   config
  );
  return response?.data;
 } catch (error) {
  return error;
 }
};

/* API for cart count  */
export const SBH_Get_Cart_Count = async (payload) => {
 try {
  const response = await axiosInstance.get(
   `${BASE_URL}/cart/get_Cart_Count?user_email=${payload.user_email}&&meal_date=${payload.meal_date}`
  );
  return response?.data;
 } catch (error) {
  return error;
 }
};

/* API for cart update details */
export const SBH_Restaurant_Cart_Update = async (payload) => {
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/cart/restaurant_cartUpdate`,
   payload,
   config
  );
  return response;
 } catch (error) {
  return error;
 }
};

/* API for cart count  */
export const SBH_Get_Restaurant_Cart = async (payload) => {
 try {
  const response = await axiosInstance.get(
   `${BASE_URL}/cart/get_RestaurantCart?user_email=${payload.user_email}&&meal_date=${payload.meal_date}`
  );
  return response?.data;
 } catch (error) {
  return error;
 }
};

/* API for cart details */
export const SBH_Restaurant_Delete_Cart = async (payload) => {
 console.log("getting user sbh", payload);
 try {
  const response = await axiosInstance.post(
   `${BASE_URL}/cart/restaurant_cartDelete`,
   payload,
   config
  );
  return response;
 } catch (error) {
  return error;
 }
};
