import React, { useState, useContext } from "react";
import styles from "../../styles/foodstyles/FoodDetails.module.css";
import { BiPlus, BiMinus } from "react-icons/bi";
import { STRINGS } from "../../model/constant";
import { BASE_URL } from "../../helper/base_url";
import { CartContext } from "../../context/cart_context";
import { cutofftimeCheck } from "../../helper/cutofftime";
import { getCutOffTimeApi, SBH_Cart_Update } from "../../helper/http_api";

const QuantityControl = ({ orderQty, decOrderQty, incOrderQty }) => (
 <div className={styles.quantity_control_cont}>
  <div
   className={styles.decrease_quantity_cont}
   style={
    orderQty > 1
     ? { cursor: "pointer", backgroundColor: "#672f25" }
     : {
        cursor: "not-allowed",
        backgroundColor: "rgba(103, 47, 37, 0.39)",
       }
   }
   onClick={decOrderQty}
  >
   <BiMinus size={25} />
  </div>
  <div className={styles.quantity_value_cont}>{orderQty}</div>
  <div
   className={styles.increase_quantity_cont}
   style={
    orderQty < 40
     ? { cursor: "pointer", backgroundColor: "#672f25" }
     : {
        cursor: "not-allowed",
        backgroundColor: "rgba(103, 47, 37, 0.39)",
       }
   }
   onClick={incOrderQty}
  >
   <BiPlus size={25} />
  </div>
 </div>
);

const FoodDetails = ({ food, setModalOpen, OrderQuantity, alert_Predict_Func }) => {
 const {
  addToFoodCart,
  setModalVerifyMail,
  currentSelectedDate,
  setArrows,
  setRegisteredPopup,
  userAuthentication_Details,
  setModalVerifyMobile,
 } = useContext(CartContext);
 const [orderQty, setOrderQty] = useState(OrderQuantity);

 const decOrderQty = () => {
  if (orderQty > 1) {
   setOrderQty(orderQty - 1);
  }
 };

 const incOrderQty = () => {
  if (userAuthentication_Details?.booking_details?.length) {
   if (orderQty < 40) {
    setOrderQty(orderQty + 1);
   }
  }
 };

 const addToCartDetails = async () => {
  // const price =
  //  food?.Complementary &&
  //  userAuthentication_Details?.booking_details?.length > 0 &&
  //  userAuthentication_Details?.mapped_Dates[currentSelectedDate] == true
  //   ? 0
  //   : food.Price;
  // console.error("getting food price", food.Price);
  addToFoodCart(
   food.foodCategory,
   food.Main_dish,
   food.Starter,
   food.Price,
   orderQty,
   food.Complementary
  );

  alert_Predict_Func("Item added into food cart!");
  setModalOpen();
  setArrows(false);
 };

 const CutoffTimeOperation = async () => {
  const cutofftime = await getCutOffTimeApi();
  if (cutofftime.data.status) {
   //  console.log("success========>");
   const ReturnValue = await cutofftimeCheck(
    cutofftime,
    food.foodCategory,
    currentSelectedDate
   );
   if (ReturnValue) {
    // console.log(ReturnValue, "returnvalue");
    await addToCartDetails();
   } else {
    // console.log("elsePart");
    alert_Predict_Func(STRINGS.addtocartcutoff);
   }
  } else {
   alert_Predict_Func(cutofftime.data.message);
   //  console.log(cutofftime, "getCutOffTimeApielsepart==========>");
  }
 };

 const handleAddToCart = async () => {
  await CutoffTimeOperation();
 };

 return (
  <div className={styles.food_details_main}>
   {/* top */}
   <div className={styles.food_details_cont_top}>
    {/* food image */}
    <div className={styles.food_details_img_cont}>
     <img
      crossOrigin="anonymous"
      src={`${BASE_URL}/restaurant/download_images/food_imgs${food.Food_img}`}
      alt=""
      priority={1}
      width={550}
      height={550}
      className={styles.food_details_img}
     />
    </div>
   </div>
   <div className={styles.food_details_cont}>
    <div className={styles.food_details1}>
     <div className={styles.food_details2}>
      <div className={styles.food_details_name_cont}>
       <h2 className={styles.food_details_name}>{food.Main_dish}</h2>
      </div>
      <div className={styles.food_details_description_cont}>
       <p className={styles.food_details_description}>{food.Desc}</p>
      </div>
      <div className={styles.food_details_sidedish_cont}>
       <h5 className={styles.food_details_sidedish}>{STRINGS.sidedish_title}</h5>
       {food.Starter.map((item, index) => (
        <span key={index} className={styles.sidedish_name}>
         {item}
         {index !== food.Starter.length - 1 ? ", " : ""}
        </span>
       ))}
      </div>
      <div className={styles.qty_main_cont}>
       <div className={styles.order_quantity_title_cont}>
        <h6 className={styles.order_quantity_title}>{STRINGS.qty_title}</h6>
       </div>
       <QuantityControl
        orderQty={orderQty}
        decOrderQty={decOrderQty}
        incOrderQty={incOrderQty}
       />
      </div>
      {!userAuthentication_Details?.loggedInUserId && (
       <div className={styles.unregistered_user_desc_cont}>
        <p className={styles.unregistered_user_desc_note}>
         {STRINGS.verify_prefix_txt}{" "}
         <span
          className={styles.verify_mail_txt}
          onClick={() => {
           setModalOpen(false);
           setModalVerifyMail(true);
          }}
         >
          {STRINGS.sign_in_field}
         </span>{" "}
         {STRINGS.or_field}
         <span
          className={styles.verify_mail_txt}
          onClick={() => {
           setModalOpen(false);
           setRegisteredPopup(true);
          }}
         >
          {" "}
          {STRINGS.sign_up_field}
         </span>{" "}
         {STRINGS.rem_mail_verify_note}
        </p>
       </div>
      )}
      {userAuthentication_Details?.loggedInUserId &&
       !userAuthentication_Details?.booking_details.length && (
        <div className={styles.unregistered_user_desc_cont}>
         <p className={styles.unregistered_user_desc_note}>
          {STRINGS.verify_prefix_txt}
          <span
           className={styles.verify_mail_txt}
           onClick={() => {
            setModalVerifyMobile(true), setModalOpen(false), setArrows(true);
           }}
          >
           {" "}
           {STRINGS.verify_booking_identity}
          </span>{" "}
          {STRINGS.rem_mail_verify_note}
         </p>
        </div>
       )}
      {userAuthentication_Details?.booking_details?.length > 0 && orderQty >= 40 && (
       <div className={styles.unregistered_user_desc_cont}>
        <p className={styles.max_limit_error_note}>{STRINGS.max_limit_error_msg}</p>
       </div>
      )}
     </div>
    </div>
    <div className={styles.add_to_cart_btn_cont}>
     <button
      onClick={handleAddToCart}
      style={
       userAuthentication_Details?.booking_details?.length > 0
        ? { backgroundColor: "#937031", color: "#fff" }
        : { backgroundColor: "#A8A8A8", color: "#555555" }
      }
      disabled={userAuthentication_Details?.booking_details?.length > 0 ? false : true}
      className={styles.add_to_cart_btn}
     >
      {food?.Complementary === true &&
      userAuthentication_Details?.booking_details?.length > 0 &&
      userAuthentication_Details?.mapped_Dates[currentSelectedDate] == true ? (
       <div>{STRINGS.add_to_cart_btn_txt}</div>
      ) : (
       <>
        <div>{STRINGS.add_to_cart_txt}</div>
       </>
      )}
     </button>
    </div>
   </div>
  </div>
 );
};

export default FoodDetails;
