import React, { useContext } from "react";
import styles from "../../styles/foodstyles/NearbyRestaurants.module.css";
import { BASE_URL } from "../../helper/base_url";
import { STRINGS } from "../../model/constant";
import checkWindowSize from "../../helper/screen_size_check";
import { CartContext } from "../../context/cart_context";

/* Nearby restaurant data items */
export const NearbyRestaurant = ({ restaurant_data, alert_Predict_Func }) => {
 const {
  addToOtherCart,
  userAuthentication_Details,
  setModalVerifyMail,
  cartOthers,
  setModalVerifyMobile,
 } = useContext(CartContext);
 const { isSmallScreen } = checkWindowSize();

 const handleAddToCartRestaurant = () => {
  if (!userAuthentication_Details?.loggedInUserId) {
   setModalVerifyMail(true);
   alert_Predict_Func(
    `${STRINGS.verify_prefix_txt} ${STRINGS.sign_in_field} ${STRINGS.or_field} ${STRINGS.sign_up_field} ${STRINGS.rem_mail_verify_note}`
   );
  } else if (!userAuthentication_Details?.booking_details?.length) {
   setModalVerifyMobile(true);
   alert_Predict_Func(`${STRINGS.verify_prefix_txt} ${STRINGS.verify_booking_identity}`);
  } else {
   if (cartOthers.length < 5) {
    addToOtherCart(restaurant_data._id, restaurant_data.Name);
    alert_Predict_Func("Item added into restaurant cart!");
   } else {
    alert_Predict_Func("Maximum number of restaurants already selected");
   }
  }
 };
 return (
  <div className={styles.restaurant__items_cont}>
   <div className={styles.restaurant_img_cont}>
    <img
     crossOrigin="anonymous"
     src={`${BASE_URL}/restaurant/download_images/restaurant_imgs${restaurant_data.Image}`}
     width={470}
     height={470}
     alt=""
     priority={1}
     className={styles.restaurant_img}
    />
   </div>
   <div className={styles.restaurant_name_cont}>
    <div className={styles.restaurant_name}>{restaurant_data.Name}</div>
   </div>
   <div className={styles.restaurant_desc_cont}>
    <div className={styles.restaurant_desc}>{restaurant_data.Desc}</div>
   </div>

   <div className={styles.btns_cont}>
    {restaurant_data.URL ? (
     <a
      href={restaurant_data.URL}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.view_website_btn}
     >
      {STRINGS.view_website}
     </a>
    ) : (
     <div
      onClick={() => alert_Predict_Func(STRINGS.websitenotavailable)}
      className={styles.view_website_urlnotfound_btn}
     >
      {STRINGS.view_website}
     </div>
    )}
    <div className={styles.add_to_cart_btn} onClick={handleAddToCartRestaurant}>
     <button>{STRINGS.add_to_cart_txt}</button>
    </div>
   </div>
  </div>
 );
};
