import { userTimezone } from "../model/constant";

const indian_Conversion = () => {
 return new Date().toLocaleString("en-US", { timeZone: userTimezone });
};
const indian_ConversionWith_Date = (currentSelectedDate) => {
 return new Date(currentSelectedDate).toLocaleString("en-US", { timeZone: userTimezone });
};
const formatizerDate = (orderDate1) => {
 return `${orderDate1.split("/")[2]}-${orderDate1.split("/")[0].padStart(2, "0")}-${orderDate1
  .split("/")[1]
  .padStart(2, "0")}`;
};

export const cutofftimeDependency = async (s_ctime, foodCategory, currentSelectedDate) => {
 console.log("before new====>", currentSelectedDate);
 let orderDate1 = indian_ConversionWith_Date(currentSelectedDate).split(",")[0];
 console.log("before====>", orderDate1);
 orderDate1 = formatizerDate(orderDate1);
 //  console.error("afterr=======>");
 let local_ctime = new Date(indian_Conversion());
 console.log("Local Time Zone:", local_ctime);
 let local_ctime1 = indian_Conversion().split(",")[0];
 local_ctime1 = formatizerDate(local_ctime1);

 let nextdate = new Date(indian_Conversion());
 nextdate.setDate(nextdate.getDate() + 1);

 let nextdateWT = nextdate.toLocaleString("en-US", { timeZone: userTimezone }).split(",")[0];
 nextdateWT = formatizerDate(nextdateWT);

 const ampm = s_ctime.getHours() >= 12 ? "PM" : "AM";

 //  console.log(
 //   new Date(indian_Conversion()),
 //   "",
 //   orderDate1,
 //   "OrderDate1AndNextDate================>",
 //   nextdateWT
 //  );
 //  console.log(orderDate1, "OrderDate1AndLocalctime1================>", local_ctime1);
 //  console.log(
 //   currentSelectedDate,
 //   "dropdowndate",
 //   s_ctime,
 //   "cutofftime",
 //   local_ctime,
 //   "localtime"
 //  );
 //  console.log(foodCategory, "foodCategory==========>");
 //  console.log(ampm, "ampm============>");

 switch (foodCategory) {
  case "breakfast":
   if (orderDate1 == local_ctime1) {
    if (ampm == "PM") {
     return 0;
    } else {
     if (local_ctime < s_ctime) {
      return 1;
     } else {
      return 0;
     }
    }
   } else if (orderDate1 == nextdateWT) {
    s_ctime.setDate(s_ctime.getDate() + 1);
    if (local_ctime < s_ctime) {
     return 1;
    } else {
     return 0;
    }
   } else {
    // console.log(typeof orderDate1, formatizerDate(indian_Conversion().split(",")[0]), "else");
    if (orderDate1 >= formatizerDate(indian_Conversion().split(",")[0])) {
     return 1;
    } else {
     return 0;
    }
   }
   break;
  case "lunch":
   if (orderDate1 == local_ctime1) {
    if (local_ctime < s_ctime) {
     return 1;
    } else {
     return 0;
    }
   } else {
    if (orderDate1 >= formatizerDate(indian_Conversion().split(",")[0])) {
     return 1;
    } else {
     return 0;
    }
   }
   break;
  case "dinner":
   //  console.log(orderDate1 == local_ctime1, local_ctime, s_ctime, "dinnerdinnerdinner");
   if (orderDate1 == local_ctime1) {
    if (local_ctime < s_ctime) {
     return 1;
    } else {
     return 0;
    }
   } else {
    if (orderDate1 >= formatizerDate(indian_Conversion().split(",")[0])) {
     return 1;
    } else {
     return 0;
    }
   }
   break;
  case "snack":
   if (orderDate1 >= formatizerDate(indian_Conversion().split(",")[0])) {
    return 1;
   } else {
    return 0;
   }
   break;
  default:
   //  console.log("default");
   break;
 }
};

export const cutofftimeCheck = async (cutofftime, foodCategory, currentSelectedDate) => {
 const breakfastCTime = cutofftime.data.data[0].Breakfast;
 const LunchCTime = cutofftime.data.data[0].Lunch;
 const DinnerCTime = cutofftime.data.data[0].Dinner;
 let s_ctime = new Date(indian_Conversion());
 //  console.log(s_ctime, "s_ctimes_ctimes_ctime", indian_Conversion());

 if (foodCategory == "breakfast") {
  const breakfastCTimeHour = new Date(indian_ConversionWith_Date(breakfastCTime)).getHours();
  const breakfastCTimeMinutes = new Date(
   indian_ConversionWith_Date(breakfastCTime)
  ).getMinutes();
  s_ctime.setHours(breakfastCTimeHour, breakfastCTimeMinutes, 0, 0);
  // console.log(
  //  s_ctime,
  //  breakfastCTimeHour,
  //  "breakfastCTimeHour=============>",
  //  breakfastCTimeMinutes
  // );
 } else if (foodCategory == "lunch") {
  const lunchCTimeHour = new Date(indian_ConversionWith_Date(LunchCTime)).getHours();
  const lunchCTimeMinutes = new Date(indian_ConversionWith_Date(LunchCTime)).getMinutes();
  // console.log(s_ctime, lunchCTimeHour, "lunchCTimeHour=============>", lunchCTimeMinutes);
  s_ctime.setHours(lunchCTimeHour, lunchCTimeMinutes, 0, 0);
 } else if (foodCategory == "dinner") {
  const dinnerCTimeHour = new Date(indian_ConversionWith_Date(DinnerCTime)).getHours();
  const dinnerCTimeMinutes = new Date(indian_ConversionWith_Date(DinnerCTime)).getMinutes();
  // console.log(
  //  s_ctime,
  //  dinnerCTimeHour,
  //  "dinnerCTimeMinutes=============>",
  //  dinnerCTimeMinutes
  // );
  s_ctime.setHours(dinnerCTimeHour, dinnerCTimeMinutes, 0, 0);
  // console.log(
  //  s_ctime,
  //  dinnerCTimeHour,
  //  "dinnerCTimeMinutes=============>",
  //  dinnerCTimeMinutes
  // );
 }
 //  console.log(cutofftime, "cutofftime===============>");
 const cutoff_return_value = await cutofftimeDependency(
  s_ctime,
  foodCategory,
  currentSelectedDate
 );
 return cutoff_return_value;
};

export const mealTypeSegregation = async (cartFood) => {
 console.error("food data getting", cartFood, "got it");
 let breakfast = [];
 let lunch = [];
 let dinner = [];
 let snacks = [];
 cartFood.forEach((item) => {
  switch (item.foodCategory) {
   case "breakfast":
    breakfast.push({
     Main_dish: item.mainDish,
     Starter: item.sideDish,
     Complementary: item.complementary,
     order_quantity: item.orderQty,
     order_price: item.price,
    });
    break;
   case "lunch":
    lunch.push({
     Main_dish: item.mainDish,
     Starter: item.sideDish,
     Complementary: item.complementary,
     order_quantity: item.orderQty,
     order_price: item.price,
    });
    break;
   case "dinner":
    dinner.push({
     Main_dish: item.mainDish,
     Starter: item.sideDish,
     Complementary: item.complementary,
     order_quantity: item.orderQty,
     order_price: item.price,
    });
    break;
   case "snack":
    snacks.push({
     Main_dish: item.mainDish,
     Starter: item.sideDish,
     Complementary: item.complementary,
     order_quantity: item.orderQty,
     order_price: item.price,
    });
    break;
   default:
    break;
  }
 });

 //  console.log(breakfast, "breakfast", lunch, "lunch", dinner, "dinner", snacks, "snacks");
 return {
  breakfast: breakfast,
  lunch: lunch,
  dinner: dinner,
  snacks: snacks,
 };
};

export const cutofftimeCheckPlaceOrder = async (cutofftime, cartFood, currentSelectedDate) => {
 //  console.log(
 //   cutofftime,
 //   cartFood,
 //   currentSelectedDate,
 //   "cutofftimeCheckPlaceOrder==========>"
 //  );
 const meal_type_value = await mealTypeSegregation(cartFood);
 const breakfastCTime = cutofftime.data.data[0].Breakfast;
 const LunchCTime = cutofftime.data.data[0].Lunch;
 const DinnerCTime = cutofftime.data.data[0].Dinner;
 let s_ctime = new Date(indian_Conversion());
 let PlaceOrderCutoffTime = {
  breakfast: 1,
  lunch: 1,
  dinner: 1,
  snack: 1,
 };
 if (meal_type_value.breakfast.length > 0) {
  const breakfastCTimeHour = new Date(indian_ConversionWith_Date(breakfastCTime)).getHours();
  const breakfastCTimeMinutes = new Date(
   indian_ConversionWith_Date(breakfastCTime)
  ).getMinutes();
  // console.log(breakfastCTimeHour, "breakfastCTimeHour=============>");
  s_ctime.setHours(breakfastCTimeHour, breakfastCTimeMinutes, 0, 0);
  PlaceOrderCutoffTime.breakfast = await cutofftimeDependency(
   s_ctime,
   "breakfast",
   currentSelectedDate
  );
 }
 if (meal_type_value.lunch.length > 0) {
  const lunchCTimeHour = new Date(indian_ConversionWith_Date(LunchCTime)).getHours();
  const lunchCTimeMinutes = new Date(indian_ConversionWith_Date(LunchCTime)).getMinutes();
  s_ctime.setHours(lunchCTimeHour, lunchCTimeMinutes, 0, 0);
  PlaceOrderCutoffTime.lunch = await cutofftimeDependency(
   s_ctime,
   "lunch",
   currentSelectedDate
  );
 }
 if (meal_type_value.dinner.length > 0) {
  const dinnerCTimeHour = new Date(indian_ConversionWith_Date(DinnerCTime)).getHours();
  const dinnerCTimeMinutes = new Date(indian_ConversionWith_Date(DinnerCTime)).getMinutes();
  s_ctime.setHours(dinnerCTimeHour, dinnerCTimeMinutes, 0, 0);
  PlaceOrderCutoffTime.dinner = await cutofftimeDependency(
   s_ctime,
   "dinner",
   currentSelectedDate
  );
  // console.log(PlaceOrderCutoffTime.dinner, "PlaceOrderCutoffTime.dinner=============>");
 }
 return PlaceOrderCutoffTime;
};
