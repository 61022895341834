import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { useAlert } from "react-alert";
import Modal from "react-modal";
// react icons
import { IoCloseOutline } from "react-icons/io5";
import { ImCart } from "react-icons/im";
import { SlArrowRight } from "react-icons/sl";
// food components
import { NearbyRestaurant } from "../components/foodcomponents/NearbyRestaurants";
import {
 BreakfastFood,
 LunchFood,
 DinnerFood,
 SnacksFood,
} from "../components/foodcomponents/Food";
import Nav from "../components/foodcomponents/Nav";
import Banner from "../components/foodcomponents/Banner";
import CartTop from "../components/foodcomponents/cart/CartTop";
import CartTopRestaurant from "../components/foodcomponents/cart/CartTopRestaurant";
import CartBottomRestaurant from "../components/foodcomponents/cart/CartBottomRestaurant";
import CartItemRestaurant from "../components/foodcomponents/cart/CartItemRestaurant";
import CartItem from "../components/foodcomponents/cart/CartItem";
import CartBottom from "../components/foodcomponents/cart/CartBottom";
import SearchInput from "../components/foodcomponents/SearchInput";
import DropdownInput from "../components/foodcomponents/DropdownInput";
import DateChangeAlert from "../components/foodcomponents/date_change_alert_msg";
import PlaceOrderMsg from "../components/foodcomponents/place_order_success_fail_msg";
// common components
import SpinnerLoader from "../components/commoncomponents/spinner_loader";
// helper
import checkWindowSize from "../helper/screen_size_check";
// APIs
import {
 getCommonFoodMenuDataApi,
 getNearbyRestaurantDataApi,
 getDatewiseFoodMenuDataApi,
 foodPageItemSearchApi,
 storeFoodOrderDataApi,
 storeRestaurantOrderDataApi,
 getCutOffTimeApi,
 SBH_Delete_Cart,
 SBH_Restaurant_Delete_Cart,
} from "../helper/http_api";
// constant numbers and strings
import { STRINGS } from "../model/constant";
// food menu slider & its styles
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/foodstyles/cartstyles/sliderStyles.css";
// context
import { CartContext } from "../context/cart_context";
// food page styles
import styles from "../styles/foodstyles/FoodPage.module.css";
import { getStorage_allData, updateCartAllFood } from "../helper/localstorage_update";
import { useNavigate } from "react-router";
import { cutofftimeCheckPlaceOrder, mealTypeSegregation } from "../helper/cutofftime";
import CutoffPopupView from "../components/foodcomponents/cutoffpopup";
import alertPopupStyles from "../styles/alert_popup.module.css";

Modal.setAppElement("body");
const modalStyles = {
 overlay: {
  backgroundColor: "rgba(0,0,0,0.5)",
 },
};

const FoodPage = () => {
 const alert = useAlert();
 const { isSmallScreen } = checkWindowSize();
 const {
  isOpenFoodCart,
  setIsOpenFoodCart,
  isOpenRestaurantCart,
  setIsOpenRestaurantCart,
  cartFood,
  cartOthers,
  setNearbyRestaurantTab,
  setCurrentSelectedDate,
  cartFoodTotal,
  foodCartRoomNo,
  setCartFood,
  setCartOthers,
  currentSelectedDate,
  setFoodCartQtyAmount,
  IsCartOpen,
  setIsCartOpen,
  modalVerifyMail,
  special_instruction,
  setSpecialInstruction,
  setDateChangeValueforChange,
  DateChangeValueforChange,
  Arrows,
  setArrows,
  userAuthentication_Details,
  setTotalcart_Quantity,
  totalcart_Quantity,
 } = useContext(CartContext);

 // states
 const [Query, setQuery] = useState("");
 const [QueryCalender, setQueryCalender] = useState("");
 const [QueryRestaurant, setQueryRestaurant] = useState("");
 const [ShowDateChangeAlert, setShowDateChangeAlert] = useState(false);
 const [CutoffPopup, setCutoffPopup] = useState({
  popup: false,
  cutoffdata: "",
 });
 const [ChangedDateValue, setChangedDateValue] = useState("");
 const [showSpinner, setShowSpinner] = useState(false);
 const [isButtonDisabled, setIsButtonDisabled] = useState(false);
 const [foodSelector, setFoodSelector] = useState(true);
 const [restaurantSelector, setRestaurantSelector] = useState(false);
 const [commonBreakfastData, setCommonBreakfastData] = useState([]);
 const [commonLunchData, setCommonLunchData] = useState([]);
 const [commonDinnerData, setCommonDinnerData] = useState([]);
 const [commonSnacksData, setCommonSnacksData] = useState([]);
 const [commonNearByRestaurantData, setCommonNearByRestaurantData] = useState([]);
 const [showPlaceOrderMsg, setShowPlaceOrderMsg] = useState(false);
 const [orderPlacedSuccess, setOrderPlacedSuccess] = useState(false);
 const [isAlertShowing, setIsAlertShowing] = useState(false);
 const [sliderKey, setSliderKey] = useState(0);

 const navigate = useNavigate();
 /* slider settings */
 const generateSliderSettings = (data, slidesToShow) => {
  return {
   key: sliderKey,
   dots: false,
   infinite: false,
   speed: 500,
   slidesToShow: slidesToShow,
   slidesToScroll: data?.length > slidesToShow ? slidesToShow : 1,
   draggable: data?.length > slidesToShow ? true : false,
   arrows: !(isOpenFoodCart || isOpenRestaurantCart || modalVerifyMail || Arrows),
  };
 };
 const sliderSettingsBreakfast = generateSliderSettings(commonBreakfastData, 5);
 const sliderSettingsLunch = generateSliderSettings(commonLunchData, 5);
 const sliderSettingsDinner = generateSliderSettings(commonDinnerData, 5);
 const sliderSettingsSnacks = generateSliderSettings(commonSnacksData, 5);

 // food cart close handle
 const closeModal_cart = () => {
  updateCartAllFood({ key: 9, value: 0 }, userAuthentication_Details.loggedIn_user_email);
  setIsCartOpen(false);
  setIsOpenFoodCart(false);
 };
 // restaurant cart close handle
 const closeModal_cart_restaurant = () => {
  updateCartAllFood({ key: 9, value: 0 }, userAuthentication_Details.loggedIn_user_email);
  setIsCartOpen(false);
  setIsOpenRestaurantCart(false);
 };

 /* Get common food menu data api controller */
 const getCommonFoodMenuData = async (type, page, size) => {
  let params = {
   page_number: page,
   page_size: size,
   meal_type: type,
  };
  try {
   const response = await getCommonFoodMenuDataApi(params);
   console.log(response, "getCommonFoodMenuDataApi=================>", params);
   if (response?.data?.status === true) {
    if (response?.data?.data_breakfast) {
     setCommonBreakfastData(response?.data?.data_breakfast);
     await getCommonFoodMenuData("lunch", 1, 100);
    } else if (response?.data?.data_lunch) {
     setCommonLunchData(response?.data?.data_lunch);
     await getCommonFoodMenuData("dinner", 1, 100);
    } else if (response?.data?.data_dinner) {
     setCommonDinnerData(response?.data?.data_dinner);
     await getCommonFoodMenuData("snack", 1, 100);
    } else if (response?.data?.data_snack) {
     setCommonSnacksData(response?.data?.data_snack);
     setShowSpinner(false);
    }
   } else if (response?.data?.status === false) {
    switch (response.data.meal_type) {
     case "breakfast":
      setCommonBreakfastData([]);
      await getCommonFoodMenuData("lunch", 1, 100);
      break;
     case "lunch":
      setCommonLunchData([]);
      await getCommonFoodMenuData("dinner", 1, 100);
      break;
     case "dinner":
      setCommonDinnerData([]);
      await getCommonFoodMenuData("snack", 1, 100);
      break;
     case "snack":
      setCommonSnacksData([]);
      setShowSpinner(false);
      break;
     default:
      setCommonBreakfastData([]);
      setCommonLunchData([]);
      setCommonDinnerData([]);
      setCommonSnacksData([]);
      setShowSpinner(false);
      break;
    }
    console.log(response?.data?.message);
   }
  } catch (error) {
   setShowSpinner(false);
   console.error("##getCommonFoodMenuData got an error ===> ", error);
  }
 };

 // **Get nearby restaurant data api controller /;
 const getNearbyRestaurantData = async (decider, page, size) => {
  let params = {
   page_number: page,
   page_size: size,
   decider: decider,
  };
  try {
   const response = await getNearbyRestaurantDataApi(params);
   if (response?.data?.status === true) {
    if (response?.data?.data) {
     setCommonNearByRestaurantData(response?.data?.data);
    } else {
     // do nothing
    }
   } else if (response?.data?.status === false) {
    console.log(response?.data?.message);
   }
  } catch (error) {
   setShowSpinner(false);
   console.error("##getNearbyRestaurantData got an error ===> ", error);
  }
 };
 /* Get datewise food menu data api controller */
 const getDatewiseFoodMenuData = async (type, date_payload) => {
  let params = {
   meal_type: type,
   payload: date_payload,
  };
  try {
   const response = await getDatewiseFoodMenuDataApi(params);
   if (response?.data?.status === true) {
    if (response?.data?.data_breakfast) {
     setCommonBreakfastData(response?.data?.data_breakfast);
     await getDatewiseFoodMenuData("lunch", {
      date: currentSelectedDate,
     });
    } else if (response?.data?.data_lunch) {
     setCommonLunchData(response?.data?.data_lunch);
     await getDatewiseFoodMenuData("dinner", {
      date: currentSelectedDate,
     });
    } else if (response?.data?.data_dinner) {
     setCommonDinnerData(response?.data?.data_dinner);
     await getDatewiseFoodMenuData("snack", {
      date: currentSelectedDate,
     });
    } else if (response?.data?.data_snack) {
     setCommonSnacksData(response?.data?.data_snack);
     setShowSpinner(false);
    } else {
     console.log("new error");
     // do nothing
    }
   } else if (response?.data?.status === false) {
    console.error("getting error called");
    switch (response.data.meal_type) {
     case "breakfast":
      setCommonBreakfastData([]);
      await getDatewiseFoodMenuData("lunch", {
       date: currentSelectedDate,
      });
      break;
     case "lunch":
      setCommonLunchData([]);
      await getDatewiseFoodMenuData("dinner", {
       date: currentSelectedDate,
      });
      break;
     case "dinner":
      setCommonDinnerData([]);
      await getDatewiseFoodMenuData("snack", {
       date: currentSelectedDate,
      });
      break;
     case "snack":
      setCommonSnacksData([]);
      setShowSpinner(false);
      break;
     default:
      setCommonBreakfastData([]);
      setCommonLunchData([]);
      setCommonDinnerData([]);
      setCommonSnacksData([]);
      setShowSpinner(false);
      break;
    }
   }
  } catch (error) {
   setShowSpinner(false);
   console.log("##getDatewiseFoodMenuData got an error ===> ", error);
  }
 };

 /* food page search data api controller */
 const foodPageItemSearch = async (type, query, page, size, value) => {
  let params = {
   search_type: type,
   search_query: query,
   page_number: page,
   page_size: size,
   date_value: value,
  };
  console.log(params.search_query, "foodPageItemSearchquery============>");
  try {
   if (params.search_query) {
    setShowSpinner(true);
    const response = await foodPageItemSearchApi(params);
    console.log(response, params.search_type, "foodPageItemSearch============>");
    setShowSpinner(false);
    if (response?.data?.status === true) {
     if (params.search_type == "calendar" || params.search_type == "common") {
      setCommonBreakfastData(response?.data?.data_breakfast);
      setCommonLunchData(response?.data?.data_lunch);
      setCommonDinnerData(response?.data?.data_dinner);
      setCommonSnacksData(response?.data?.data_snack);
     } else if (params.search_type == "restaurant") {
      setCommonNearByRestaurantData(response?.data?.data);
     }
    } else if (response?.data?.status === false) {
     alert_Predict_Func(STRINGS.item_not_found);
     console.error(response?.data?.message);
    }
   }
  } catch (error) {
   setShowSpinner(false);
   console.error("##getDatewiseFoodMenuData got an error ===> ", error);
  }
 };

 /* API controller for storing food order data in database */
 const storeFoodOrderData = async () => {
  const meal_typeValue = await mealTypeSegregation(cartFood);
  console.log(meal_typeValue, "storeFoodOrderData");
  try {
   setShowSpinner(true);
   const response = await storeFoodOrderDataApi({
    user_name: userAuthentication_Details.loggedIn_user_name,
    user_email: userAuthentication_Details.loggedIn_user_email,
    breakfast: meal_typeValue.breakfast,
    lunch: meal_typeValue.lunch,
    snack: meal_typeValue.snacks,
    dinner: meal_typeValue.dinner,
    spl_ins: special_instruction,
    room_no: foodCartRoomNo !== 0 ? foodCartRoomNo : userAuthentication_Details.roomIds[0],
    user_phn_no: parseInt(userAuthentication_Details.loggedIn_user_phone),
    order_date: currentSelectedDate,
    totalAmount: cartFoodTotal,
    order_status: 1,
   });
   setShowSpinner(false);
   console.log(response, "storeFoodOrderDataApi================>");
   const { status } = response.data;
   if (status === true) {
    updateCartAllFood({ key: 6 }, userAuthentication_Details.loggedIn_user_email);
    setShowPlaceOrderMsg(true);
    setOrderPlacedSuccess(true);
    closeModal_cart(false);
    setCartFood([]);
   } else if (status === false) {
    setShowPlaceOrderMsg(true);
    setOrderPlacedSuccess(false);
    closeModal_cart(false);
   }
  } catch (error) {
   setShowSpinner(false);
   alert_Predict_Func("Oops! Something unexpected happened.");
   console.error("Error placing food order:", error);
  }
 };

 /* API controller for storing restaurant order details in database */
 const storeRestaurantOrderData = async (resDetails) => {
  try {
   setShowSpinner(true);
   const response = await storeRestaurantOrderDataApi({
    user_name: userAuthentication_Details.loggedIn_user_name,
    user_email: userAuthentication_Details.loggedIn_user_email,
    res_details: resDetails,
    room_no: foodCartRoomNo !== 0 ? foodCartRoomNo : userAuthentication_Details.roomIds[0],
    user_phn_no: parseInt(userAuthentication_Details.loggedIn_user_phone),
    order_date: currentSelectedDate,
    order_status: 1,
   });
   console.error(response.data, "storeRestaurantOrderData=============>");
   setShowSpinner(false);
   const { status } = response?.data;
   if (status === true) {
    updateCartAllFood({ key: 8 }, userAuthentication_Details.loggedIn_user_email);
    setShowPlaceOrderMsg(true);
    setOrderPlacedSuccess(true);
    closeModal_cart_restaurant(false);
    // setFoodCartQtyAmount(0);
    setCartOthers([]);
   } else if (status === false) {
    setShowPlaceOrderMsg(true);
    setOrderPlacedSuccess(false);
    closeModal_cart_restaurant(false);
   }
  } catch (error) {
   setShowSpinner(false);
   alert_Predict_Func("Oops! Something unexpected happened.");
   console.error("Error placing restaurant order:", error);
  }
 };

 useLayoutEffect(() => {
  setShowSpinner(true);
 }, []);
 // api call helpers
 const callAPIs_unregistered = async () => {
  // setShowSpinner(true);
  await getCommonFoodMenuData("breakfast", 1, 100);
  // await getCommonFoodMenuData("lunch", 1, 100);
  // await getCommonFoodMenuData("dinner", 1, 100);
  // await getCommonFoodMenuData("snack", 1, 100);
  await getNearbyRestaurantData(false, 1, 100);
 };

 const callAPIs_registered = async () => {
  if (currentSelectedDate) {
   //  setShowSpinner(true);
   await getDatewiseFoodMenuData("breakfast", {
    date: currentSelectedDate,
   });
   //  await getDatewiseFoodMenuData("lunch", {
   //   date: currentSelectedDate,
   //  });
   //  await getDatewiseFoodMenuData("dinner", {
   //   date: currentSelectedDate,
   //  });
   //  await getDatewiseFoodMenuData("snack", {
   //   date: currentSelectedDate,
   //  });
  }
  await getNearbyRestaurantData(false, 1, 50);
 };

 // search helpers
 const onSearchCommonFood = () => {
  foodPageItemSearch("common", Query.trim(), 1, 50, "");
 };
 const onSearchCalenderFood = () => {
  foodPageItemSearch("calendar", QueryCalender.trim(), 1, 50, currentSelectedDate);
 };
 const onSearchRestaurant = () => {
  foodPageItemSearch("restaurant", QueryRestaurant.trim(), 1, 50, "");
 };

 const AuthCheckData = () => {
  if (userAuthentication_Details?.booking_details?.length) {
   callAPIs_registered();
  } else {
   callAPIs_unregistered();
  }
 };

 // call api and update the data when the page is load
 useEffect(() => {
  console.error("page renderingg=====>");
  AuthCheckData();
  setSliderKey((prev) => prev + 1);
 }, [currentSelectedDate]);

 const backNavigate_Check = (e) => {
  if (e?.state != null) {
   let value = getStorage_allData(9, userAuthentication_Details.loggedIn_user_email);
   if (value == 1 || value == 2) {
    // updateCartAllFood({ key: 9, value: 0 }, userAuthentication_Details.loggedIn_user_email);
    setIsCartOpen(false);
    setIsOpenRestaurantCart(false);
    setIsOpenFoodCart(false);
    navigate("/food_page");
   }
   //  return prev;
  }
 };
 useEffect(() => {
  window.addEventListener("popstate", backNavigate_Check);
 }, []);
 /* handlers */
 const handleFoodSearchChange = (e) => {
  setSliderKey((prev) => prev + 1);
  const query = e.target.value;
  if (query !== "") {
   setQuery(e.target.value);
  } else {
   setQuery("");
   callAPIs_unregistered();
  }
 };
 const handleCalenderFoodSearchChange = (e) => {
  const query = e.target.value;
  setSliderKey((prev) => prev + 1);
  if (query !== "") {
   setQueryCalender(e.target.value);
  } else {
   setQueryCalender("");
   callAPIs_registered();
  }
 };
 const handleRestaurantSearchChange = (e) => {
  setSliderKey((prev) => prev + 1);
  const query = e.target.value;
  if (query !== "") {
   setQueryRestaurant(e.target.value);
  } else {
   setQueryRestaurant("");
   callAPIs_unregistered();
  }
 };
 const handlePlaceOrderClick = async () => {
  const cutofftime = await getCutOffTimeApi();
  if (cutofftime.data.status) {
   console.log("success========>");
   const ReturnValue = await cutofftimeCheckPlaceOrder(
    cutofftime,
    cartFood,
    currentSelectedDate
   );
   if (ReturnValue) {
    console.log(ReturnValue, "returnvalue");
    if (ReturnValue.breakfast == 0 || ReturnValue.lunch == 0 || ReturnValue.dinner == 0) {
     setCutoffPopup({
      popup: true,
      cutoffdata: ReturnValue,
     });
    } else {
     await storeFoodOrderData();
    }
   } else {
    console.log("elsePart");
    alert_Predict_Func("Please choose another day");
   }
  } else {
   alert_Predict_Func(cutofftime.data.message);
   console.log(cutofftime, "getCutOffTimeApielsepart==========>");
  }
 };
 const handlePlaceOrderClickRestaurant = async () => {
  const isEmptyDescription = cartOthers.some((item) => !item?.food_desc?.trim());
  if (isEmptyDescription) {
   alert_Predict_Func("Please enter food description for added cart items.");
   return;
  }
  let overall_Cart_Data = cartOthers.map((ele) => {
   return { resId: ele.restId, food_desc: ele.food_desc };
  });
  await storeRestaurantOrderData(overall_Cart_Data);
 };
 const handleSpecialInstruc = (e) => {
  setSpecialInstruction(e.target.value);
  updateCartAllFood(
   { key: 3, value: e.target.value },
   userAuthentication_Details.loggedIn_user_email
  );
 };
 const handleClickOrderPlaceMsg = () => {
  setShowPlaceOrderMsg(false);
 };
 const handleOkDateChange = async () => {
  setShowDateChangeAlert(false);
  setArrows(false);
  setCartFood([]);
  setCartOthers([]);
  await SBH_Delete_Cart({ user_email: userAuthentication_Details.loggedIn_user_email });
  await SBH_Restaurant_Delete_Cart({
   user_email: userAuthentication_Details.loggedIn_user_email,
  });
  setTotalcart_Quantity(0);
  // updateCartAllFood({ key: 6 }, userAuthentication_Details.loggedIn_user_email);
  setDateChangeValueforChange(ChangedDateValue);
  setCurrentSelectedDate(ChangedDateValue);
  localStorage.setItem("verified_user_selected_date", ChangedDateValue);
  // let date_check = ChangedDateValue;
  // updateCartAllFood({ key: 8 }, userAuthentication_Details.loggedIn_user_email);
 };
 const handleBookingDateChange = (e) => {
  console.log(
   e.target.value,
   "handleBookingDateChange111========>",
   currentSelectedDate,
   "another value",
   new Date(currentSelectedDate) != new Date(e.target.value),
   totalcart_Quantity
  );
  if (new Date(currentSelectedDate) != new Date(e.target.value) && totalcart_Quantity > 0) {
   setShowDateChangeAlert(true);
   setArrows(true);
   setChangedDateValue(e.target.value);
  } else {
   const date = e.target.value;
   localStorage.setItem("verified_user_selected_date", e.target.value);
   setCurrentSelectedDate(date);
   setDateChangeValueforChange(e.target.value);
   //  date_check = e.target.value;
  }
 };
 const handleFoodSelector = async () => {
  setShowSpinner(true);
  setTimeout(() => {
   if (foodSelector === false) {
    AuthCheckData();
    setFoodSelector(true);
    setRestaurantSelector(false);
    setNearbyRestaurantTab(false);
   } else {
    setIsButtonDisabled(true);
   }
   setShowSpinner(false);
  }, 500);
 };
 const handleRestaurantSelector = () => {
  setShowSpinner(true);
  setTimeout(() => {
   if (restaurantSelector === false) {
    AuthCheckData();
    setRestaurantSelector(true);
    setNearbyRestaurantTab(true);
    setFoodSelector(false);
   } else {
    setIsButtonDisabled(true);
   }
   setShowSpinner(false);
  }, 500);
 };

 /* place order message */
 if (showPlaceOrderMsg) {
  return (
   <PlaceOrderMsg
    onClickContinue={handleClickOrderPlaceMsg}
    orderPlacedSuccess={orderPlacedSuccess}
   />
  );
 }

 /* show Alert message handler  */
 const alert_Predict_Func = (msg) => {
  if (!isAlertShowing) {
   setIsAlertShowing(true);
   alert.show(msg, {
    timeout: 3000,
    onClose: () => setIsAlertShowing(false),
   });
  }
 };

 const handleOkCutoffTimePopup = async () => {
  console.log(cartFood, "cartFood=====>");
  let breakfastdata = [];
  let lunchdata = [];
  let dinnerdata = [];
  let snackdata = cartFood.filter((item) => item.foodCategory == "snack");
  if (CutoffPopup.cutoffdata.breakfast == 1) {
   breakfastdata = cartFood.filter((item) => item.foodCategory == "breakfast");
   console.log(breakfastdata, "breakfastdate======>");
  }
  if (CutoffPopup.cutoffdata.lunch == 1) {
   lunchdata = cartFood.filter((item) => item.foodCategory == "lunch");
   console.log(lunchdata, "lunchdata======>");
  }
  if (CutoffPopup.cutoffdata.dinner == 1) {
   dinnerdata = cartFood.filter((item) => item.foodCategory == "dinner");
   console.log(dinnerdata, "dinnerdata======>");
  }
  setCartFood([...dinnerdata, ...lunchdata, ...breakfastdata, ...snackdata]);
  // updateCartAllFood(
  //  {
  //   key: 1,
  //   value: [...dinnerdata, ...lunchdata, ...breakfastdata, ...snackdata],
  //  },
  //  userAuthentication_Details.loggedIn_user_email
  // );
  setCutoffPopup({
   popup: false,
   cutoffdata: "",
  });
 };

 return (
  <div className={styles.food_page_overall_view}>
   {showSpinner && <SpinnerLoader />}
   <Nav alert_Predict_Func={alert_Predict_Func} spinner_Check={() => setShowSpinner(false)} />
   <Banner />
   <div className={styles.select_date_overall_view}>
    {userAuthentication_Details?.booking_details?.length > 0 && (
     <DropdownInput
      onChange={handleBookingDateChange}
      selectedDate={
       DateChangeValueforChange
        ? DateChangeValueforChange
        : userAuthentication_Details.booking_details
      }
      dates={userAuthentication_Details.booking_details}
     />
    )}
   </div>
   <div className={styles.section_selector_cont}>
    <div className={styles.section_selector_inner_cont}>
     <div
      className={styles.sbh_food_selector}
      tabIndex={0}
      onKeyDown={(event) => {
       if (event.key == "Enter") {
        handleFoodSelector();
       }
      }}
      style={
       foodSelector
        ? { borderBottom: "7px solid #B9975B" }
        : { borderBottom: "2px solid #747264", color: "#AD9D80" }
      }
      onClick={handleFoodSelector}
      disabled={isButtonDisabled}
     >
      <span style={{ cursor: "pointer" }}>SBH Food</span>
     </div>
     <div
      className={styles.nearby_restaurant_selector}
      tabIndex={0}
      onKeyDown={(event) => {
       if (event.key == "Enter") {
        handleRestaurantSelector();
       }
      }}
      style={
       restaurantSelector
        ? { borderBottom: "7px solid  #B9975B" }
        : { borderBottom: "2px solid #747264", color: "#AD9D80" }
      }
      onClick={handleRestaurantSelector}
      disabled={isButtonDisabled}
     >
      {isSmallScreen ? (
       <span style={{ cursor: "pointer" }}>Others</span>
      ) : (
       <span style={{ cursor: "pointer" }}>Other Restaurants</span>
      )}
     </div>
    </div>
   </div>

   {foodSelector &&
    (commonBreakfastData.length !== 0 ||
     commonLunchData.length !== 0 ||
     commonDinnerData.length !== 0 ||
     commonSnacksData.length !== 0) && (
     <div className={styles.search_date_cont}>
      {userAuthentication_Details?.booking_details?.length > 0 ? (
       <SearchInput
        onSearchFood={onSearchCalenderFood}
        onChange={handleCalenderFoodSearchChange}
        placeholder={"Search Food items..."}
       />
      ) : (
       <SearchInput
        onSearchFood={onSearchCommonFood}
        onChange={handleFoodSearchChange}
        placeholder={"Search Food items..."}
       />
      )}
     </div>
    )}
   {restaurantSelector && (
    <div className={styles.search_date_cont}>
     <SearchInput
      onSearchFood={onSearchRestaurant}
      onChange={handleRestaurantSearchChange}
      placeholder={"Search Restaurant..."}
     />
    </div>
   )}
   {!IsCartOpen && (
    <>
     {foodSelector &&
      commonBreakfastData.length === 0 &&
      commonLunchData.length === 0 &&
      commonDinnerData.length === 0 &&
      commonSnacksData.length === 0 && (
       <div className={styles.NoDatafoundOverallView}>
        {STRINGS.no_data_found_msg_registered}
       </div>
      )}

     {foodSelector && commonBreakfastData.length >= 1 && (
      <div className={styles.food_items_main_cont}>
       <div className={styles.category_and_result_cont}>
        <div className={styles.category}>{STRINGS.breakfast_title}</div>
       </div>

       {isSmallScreen ? (
        <div className={styles.food_items_grid}>
         {commonBreakfastData?.map((breakfast_food) => {
          return (
           <BreakfastFood
            breakfast_food={breakfast_food}
            alert_Predict_Func={(msg) => alert_Predict_Func(msg)}
           />
          );
         })}
        </div>
       ) : (
        <div className={styles.sliderContainer}>
         <Slider {...sliderSettingsBreakfast}>
          {commonBreakfastData?.map((breakfast_food, index) => (
           <div key={index} className={styles.slide}>
            <BreakfastFood
             breakfast_food={breakfast_food}
             alert_Predict_Func={(msg) => alert_Predict_Func(msg)}
            />
           </div>
          ))}
         </Slider>
        </div>
       )}
      </div>
     )}

     {foodSelector && commonLunchData.length >= 1 && (
      <div className={styles.food_items_main_cont}>
       {commonLunchData.length >= 1 && (
        <div className={styles.category_and_result_cont}>
         <div className={styles.category}>{STRINGS.lunch_title}</div>
         {/* <div className={styles.results}>
          {commonLunchData.length > 1
           ? `${commonLunchData.length} Results`
           : `${commonLunchData.length} Result`}
         </div> */}
        </div>
       )}
       {isSmallScreen ? (
        <div className={styles.food_items_grid}>
         {commonLunchData?.map((lunch_food) => {
          return (
           <LunchFood
            lunch_food={lunch_food}
            alert_Predict_Func={(msg) => alert_Predict_Func(msg)}
           />
          );
         })}
        </div>
       ) : (
        <div className={styles.sliderContainer}>
         <Slider {...sliderSettingsLunch}>
          {commonLunchData?.map((lunch_food, index) => (
           <div key={index} className={styles.slide}>
            <LunchFood
             lunch_food={lunch_food}
             alert_Predict_Func={(msg) => alert_Predict_Func(msg)}
            />
           </div>
          ))}
         </Slider>
        </div>
       )}
      </div>
     )}

     {foodSelector && commonDinnerData.length >= 1 && (
      <div className={styles.food_items_main_cont}>
       {/* food category name and items count */}
       {commonDinnerData?.length >= 1 && (
        <div className={styles.category_and_result_cont}>
         <div className={styles.category}>{STRINGS.dinner_title}</div>
         {/* <div className={styles.results}>
          {commonDinnerData?.length > 1
           ? `${commonDinnerData?.length} Results`
           : `${commonDinnerData?.length} Result`}
         </div> */}
        </div>
       )}
       {isSmallScreen ? (
        <div className={styles.food_items_grid}>
         {commonDinnerData?.map((dinner_food) => {
          return (
           <DinnerFood
            dinner_food={dinner_food}
            alert_Predict_Func={(msg) => alert_Predict_Func(msg)}
           />
          );
         })}
        </div>
       ) : (
        <div className={styles.sliderContainer}>
         <Slider {...sliderSettingsDinner}>
          {commonDinnerData?.map((dinner_food) => (
           <div className={styles.slide}>
            <DinnerFood
             dinner_food={dinner_food}
             alert_Predict_Func={(msg) => alert_Predict_Func(msg)}
            />
           </div>
          ))}
         </Slider>
        </div>
       )}
      </div>
     )}

     {foodSelector && commonSnacksData.length >= 1 && (
      <div className={styles.food_items_main_cont}>
       {commonSnacksData?.length >= 1 && (
        <div className={styles.category_and_result_cont}>
         <div className={styles.category}>{STRINGS.snacks_title}</div>
         {/* <div className={styles.results}>
          {commonSnacksData?.length > 1
           ? `${commonSnacksData?.length} Results`
           : `${commonSnacksData?.length} Result`}
         </div> */}
        </div>
       )}

       {isSmallScreen ? (
        <div className={styles.food_items_grid}>
         {commonSnacksData?.map((snacks_food) => {
          return (
           <SnacksFood
            snacks_food={snacks_food}
            alert_Predict_Func={(msg) => alert_Predict_Func(msg)}
           />
          );
         })}
        </div>
       ) : (
        <div className={styles.sliderContainer}>
         <Slider {...sliderSettingsSnacks}>
          {commonSnacksData?.map((snacks_food) => (
           <div className={styles.slide}>
            <SnacksFood
             snacks_food={snacks_food}
             alert_Predict_Func={(msg) => alert_Predict_Func(msg)}
            />
           </div>
          ))}
         </Slider>
        </div>
       )}
      </div>
     )}
    </>
   )}

   {!IsCartOpen && restaurantSelector && (
    <div className={styles.nearby_restaurant_items_main_cont}>
     <div className={styles.category_and_result_cont}>
      <div className={styles.category}>
       {isSmallScreen ? STRINGS.other_restaurants_mobile : STRINGS.other_restaurants}
      </div>
      {/* <div className={styles.results}>
       {commonNearByRestaurantData?.length > 1
        ? `${commonNearByRestaurantData.length} Results`
        : `${commonNearByRestaurantData.length} Result`}
      </div> */}
     </div>
     <div className={styles.nearby_restaurant_items_grid}>
      {commonNearByRestaurantData.map((restaurant_data, index) => (
       <NearbyRestaurant
        key={index}
        restaurant_data={restaurant_data}
        restaurantIndex={index}
        alert_Predict_Func={(msg) => alert_Predict_Func(msg)}
       />
      ))}
     </div>
    </div>
   )}

   {isOpenFoodCart && (
    <Modal
     isOpen={isOpenFoodCart}
     style={modalStyles}
     onRequestClose={closeModal_cart}
     contentLabel=""
     className={styles.modal_cart_details}
    >
     {cartFood.length >= 1 ? (
      <div>
       <CartTop />
       {isSmallScreen === false ? (
        <div
         style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
         }}
        >
         <div className={styles.cart_item_bottom1}>
          <div className={styles.cart_item_cont}>
           <div className={styles.your_order_txt}>{STRINGS.your_order_txt}</div>
           {cartFood.map((cart_food, index) => (
            <CartItem key={cart_food.id} cart_food={cart_food} Cart_Index={index} />
           ))}
           <div className={styles.special_instruction_cont}>
            <div className={styles.special_ins_txt}>{STRINGS.special_ins_txt}</div>
            <div className={styles.special_ins_input_cont}>
             <textarea
              onChange={handleSpecialInstruc}
              value={special_instruction}
              // defaultValue={special_instruction}
              className={styles.special_ins_user_input}
              rows="5"
              cols="50"
              maxLength="250"
             />
            </div>
           </div>
          </div>
          <div className={styles.cart_bottom_cont1}>
           <CartBottom />
           <div
            className={styles.placeorder_btn_cont}
            onClick={handlePlaceOrderClick}
            tabIndex={0}
            onKeyDown={(event) => {
             if (event.key == "Enter") {
              return;
             }
            }}
            style={{ outlineColor: "rgba(51, 24, 18, 1)" }}
           >
            <div
             style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "90%",
             }}
             className={styles.food_cart_landscape}
            >
             <div>{STRINGS.order_place_btn_txt}</div>
             <div className={styles.arrow_right}>
              <SlArrowRight size={20} />
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       ) : (
        <div className={styles.cart_item_cont}>
         <div className={styles.your_order_txt}>{STRINGS.your_order_txt}</div>
         {cartFood.map((cart_food, index) => (
          <CartItem key={cart_food.id} cart_food={cart_food} Cart_Index={index} />
         ))}
         <div className={styles.special_instruction_cont}>
          <div className={styles.special_ins_txt}>{STRINGS.special_ins_txt}</div>
          <div className={styles.special_ins_input_cont}>
           <textarea
            className={styles.special_ins_user_input}
            rows="4"
            cols="35"
            maxLength="250"
           />
          </div>
         </div>
         <CartBottom />
         <div
          className={styles.placeorder_btn_cont}
          onClick={handlePlaceOrderClick}
          tabIndex={0}
          onKeyDown={(event) => {
           if (event.key == "Enter") {
            return;
           }
          }}
          style={{ outlineColor: "rgba(51, 24, 18, 1)" }}
         >
          <div
           style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "90%",
           }}
          >
           <div>{STRINGS.order_place_btn_txt}</div>
           <div className={styles.arrow_right}>
            <SlArrowRight size={20} />
           </div>
          </div>
         </div>
        </div>
       )}
      </div>
     ) : (
      <>
       {isSmallScreen ? (
        <>
         <div className={styles.close_icon_cont} onClick={closeModal_cart}>
          <IoCloseOutline className={styles.close_icon} />
         </div>
         <div
          style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           height: "100vh",
           fontFamily: "Inter",
           fontSize: "30px",
           color: "#b9975b",
           fontWeight: "bold",
          }}
         >
          <div
           style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
           }}
          >
           <ImCart color="#b9975b" size={60} />
           <div style={{ marginTop: "20px" }}>{STRINGS.empty_cart_txt}</div>
          </div>
         </div>
        </>
       ) : (
        <>
         <div className={styles.close_icon_cont} onClick={closeModal_cart}>
          <IoCloseOutline className={styles.close_icon} />
         </div>
         <div
          style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           height: "100vh",
           fontFamily: "Inter",
           fontSize: "30px",
           color: "#b9975b",
           fontWeight: "bold",
          }}
         >
          <div
           style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
           }}
          >
           <ImCart color="#b9975b" size={60} />
           <div style={{ marginTop: "20px" }}>{STRINGS.empty_cart_txt}</div>
          </div>
         </div>
        </>
       )}
      </>
     )}
    </Modal>
   )}

   {isOpenRestaurantCart && (
    <Modal
     isOpen={isOpenRestaurantCart}
     style={modalStyles}
     onRequestClose={closeModal_cart_restaurant}
     contentLabel=""
     className={styles.modal_cart_details}
    >
     {cartOthers.length >= 1 ? (
      <>
       <CartTopRestaurant />
       {isSmallScreen === false ? (
        <div
         style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
         }}
        >
         <div className={styles.cart_item_bottom1}>
          {/* item list */}
          <div className={styles.cart_item_cont}>
           <div className={styles.your_order_txt}>your order</div>
           {cartOthers.map((cart_others, index) => (
            <CartItemRestaurant
             key={cart_others._id}
             cart_others={cart_others}
             index={index}
            />
           ))}
          </div>
          <div className={styles.cart_bottom_cont2}>
           <CartBottomRestaurant />
           <div
            className={styles.placeorder_btn_cont}
            onClick={handlePlaceOrderClickRestaurant}
           >
            <div>Place Your Order</div>
            <div className={styles.arrow_right}>
             <SlArrowRight size={20} />
            </div>
           </div>
          </div>
         </div>
        </div>
       ) : (
        <div className={styles.cart_item_cont}>
         <div className={styles.your_order_txt}>your order</div>
         {cartOthers.map((cart_others, index) => (
          <CartItemRestaurant key={cart_others._id} cart_others={cart_others} index={index} />
         ))}
         <CartBottomRestaurant />
         <div className={styles.placeorder_btn_cont} onClick={handlePlaceOrderClickRestaurant}>
          <div>Place Your Order</div>
          <div className={styles.arrow_right}>
           <SlArrowRight size={20} />
          </div>
         </div>
        </div>
       )}
      </>
     ) : (
      <>
       {isSmallScreen ? (
        <>
         <div className={styles.close_icon_cont} onClick={closeModal_cart_restaurant}>
          <IoCloseOutline className={styles.close_icon} />
         </div>
         <div
          style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           height: "100vh",
           fontFamily: "Inter",
           fontSize: "30px",
           color: "#b9975b",
           fontWeight: "bold",
          }}
         >
          <div
           style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
           }}
          >
           <ImCart color="#b9975b" size={60} />
           <div style={{ marginTop: "20px" }}>{STRINGS.empty_cart_txt}</div>
          </div>
         </div>
        </>
       ) : (
        <>
         <div className={styles.close_icon_cont} onClick={closeModal_cart_restaurant}>
          <IoCloseOutline className={styles.close_icon} />
         </div>
         <div
          style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           height: "100vh",
           fontFamily: "Inter",
           fontSize: "30px",
           color: "#b9975b",
           fontWeight: "bold",
          }}
         >
          <div
           style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
           }}
          >
           <ImCart color="#b9975b" size={60} />
           <div style={{ marginTop: "20px" }}>{STRINGS.empty_cart_txt}</div>
          </div>
         </div>
        </>
       )}
      </>
     )}
    </Modal>
   )}

   {isOpenFoodCart && (
    <Modal
     isOpen={isOpenFoodCart}
     style={modalStyles}
     onRequestClose={closeModal_cart}
     contentLabel=""
     className={styles.modal_cart_details}
    >
     {cartFood.length >= 1 ? (
      <div>
       <CartTop />
       {isSmallScreen === false ? (
        <div
         style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
         }}
        >
         <div className={styles.cart_item_bottom1}>
          <div className={styles.cart_item_cont}>
           <div className={styles.your_order_txt}>{STRINGS.your_order_txt}</div>
           {cartFood.map((cart_food, index) => (
            <CartItem key={cart_food.id} cart_food={cart_food} Cart_Index={index} />
           ))}
           <div className={styles.special_instruction_cont}>
            <div className={styles.special_ins_txt}>{STRINGS.special_ins_txt}</div>
            <div className={styles.special_ins_input_cont}>
             <textarea
              onChange={handleSpecialInstruc}
              value={special_instruction}
              // defaultValue={special_instruction}
              className={styles.special_ins_user_input}
              rows="5"
              cols="50"
              maxLength="250"
             />
            </div>
           </div>
          </div>
          <div className={styles.cart_bottom_cont1}>
           <CartBottom />
           <div
            className={styles.placeorder_btn_cont}
            onClick={handlePlaceOrderClick}
            tabIndex={0}
            onKeyDown={(event) => {
             if (event.key == "Enter") {
              return;
             }
            }}
            style={{ outlineColor: "rgba(51, 24, 18, 1)" }}
           >
            <div
             style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              // width: "90%",
             }}
             className={styles.food_cart_landscape}
            >
             <div>{STRINGS.order_place_btn_txt}</div>
             <div className={styles.arrow_right}>
              <SlArrowRight size={20} />
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       ) : (
        <div className={styles.cart_item_cont}>
         <div className={styles.your_order_txt}>{STRINGS.your_order_txt}</div>
         {cartFood.map((cart_food, index) => (
          <CartItem key={cart_food.id} cart_food={cart_food} Cart_Index={index} />
         ))}
         <div className={styles.special_instruction_cont}>
          <div className={styles.special_ins_txt}>{STRINGS.special_ins_txt}</div>
          <div className={styles.special_ins_input_cont}>
           <textarea
            className={styles.special_ins_user_input}
            rows="4"
            cols="35"
            maxLength="250"
           />
          </div>
         </div>
         <CartBottom />
         <div
          className={styles.placeorder_btn_cont}
          onClick={handlePlaceOrderClick}
          tabIndex={0}
          onKeyDown={(event) => {
           if (event.key == "Enter") {
            return;
           }
          }}
          style={{ outlineColor: "rgba(51, 24, 18, 1)" }}
         >
          <div
           style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "90%",
           }}
          >
           <div>{STRINGS.order_place_btn_txt}</div>
           <div className={styles.arrow_right}>
            <SlArrowRight size={20} />
           </div>
          </div>
         </div>
        </div>
       )}
      </div>
     ) : (
      <>
       {isSmallScreen ? (
        <>
         <div className={styles.close_icon_cont} onClick={closeModal_cart}>
          <IoCloseOutline className={styles.close_icon} />
         </div>
         <div
          style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           height: "100vh",
           fontFamily: "Inter",
           fontSize: "30px",
           color: "#b9975b",
           fontWeight: "bold",
          }}
         >
          <div
           style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
           }}
          >
           <ImCart color="#b9975b" size={60} />
           <div style={{ marginTop: "20px" }}>{STRINGS.empty_cart_txt}</div>
          </div>
         </div>
        </>
       ) : (
        <>
         <div className={styles.close_icon_cont} onClick={closeModal_cart}>
          <IoCloseOutline className={styles.close_icon} />
         </div>
         <div
          style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           height: "100vh",
           fontFamily: "Inter",
           fontSize: "30px",
           color: "#b9975b",
           fontWeight: "bold",
          }}
         >
          <div
           style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
           }}
          >
           <ImCart color="#b9975b" size={60} />
           <div style={{ marginTop: "20px" }}>{STRINGS.empty_cart_txt}</div>
          </div>
         </div>
        </>
       )}
      </>
     )}
    </Modal>
   )}

   {isOpenRestaurantCart && (
    <Modal
     isOpen={isOpenRestaurantCart}
     style={modalStyles}
     onRequestClose={closeModal_cart_restaurant}
     contentLabel=""
     className={styles.modal_cart_details}
    >
     {cartOthers.length >= 1 ? (
      <>
       <CartTopRestaurant />
       {isSmallScreen === false ? (
        <div
         style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
         }}
        >
         <div className={styles.cart_item_bottom1}>
          {/* item list */}
          <div className={styles.cart_item_cont}>
           <div className={styles.your_order_txt}>your order</div>
           {cartOthers.map((cart_others, index) => (
            <CartItemRestaurant
             key={cart_others._id}
             cart_others={cart_others}
             index={index}
            />
           ))}
          </div>
          <div className={styles.cart_bottom_cont2}>
           <CartBottomRestaurant />
           <div
            className={styles.placeorder_btn_cont}
            onClick={handlePlaceOrderClickRestaurant}
           >
            <div>Place Your Order</div>
            <div className={styles.arrow_right}>
             <SlArrowRight size={20} />
            </div>
           </div>
          </div>
         </div>
        </div>
       ) : (
        <div className={styles.cart_item_cont}>
         <div className={styles.your_order_txt}>your order</div>
         {cartOthers.map((cart_others, index) => (
          <CartItemRestaurant key={cart_others._id} cart_others={cart_others} index={index} />
         ))}
         <CartBottomRestaurant />
         <div className={styles.placeorder_btn_cont} onClick={handlePlaceOrderClickRestaurant}>
          <div>Place Your Order</div>
          <div className={styles.arrow_right}>
           <SlArrowRight size={20} />
          </div>
         </div>
        </div>
       )}
      </>
     ) : (
      <>
       {isSmallScreen ? (
        <>
         <div className={styles.close_icon_cont} onClick={closeModal_cart_restaurant}>
          <IoCloseOutline className={styles.close_icon} />
         </div>
         <div
          style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           height: "100vh",
           fontFamily: "Inter",
           fontSize: "30px",
           color: "#b9975b",
           fontWeight: "bold",
          }}
         >
          <div
           style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
           }}
          >
           <ImCart color="#b9975b" size={60} />
           <div style={{ marginTop: "20px" }}>{STRINGS.empty_cart_txt}</div>
          </div>
         </div>
        </>
       ) : (
        <>
         <div className={styles.close_icon_cont} onClick={closeModal_cart_restaurant}>
          <IoCloseOutline className={styles.close_icon} />
         </div>
         <div
          style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           height: "100vh",
           fontFamily: "Inter",
           fontSize: "30px",
           color: "#b9975b",
           fontWeight: "bold",
          }}
         >
          <div
           style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
           }}
          >
           <ImCart color="#b9975b" size={60} />
           <div style={{ marginTop: "20px" }}>{STRINGS.empty_cart_txt}</div>
          </div>
         </div>
        </>
       )}
      </>
     )}
    </Modal>
   )}

   {ShowDateChangeAlert && (
    <Modal
     isOpen={ShowDateChangeAlert}
     style={modalStyles}
     onRequestClose={() => {
      setShowDateChangeAlert(false), setArrows(false);
     }}
     contentLabel=""
     className={alertPopupStyles.dateChangeAlert}
    >
     <DateChangeAlert
      onClickClose={() => {
       setShowDateChangeAlert(false), setArrows(false);
      }}
      onClickOk={() => {
       handleOkDateChange(), setArrows(false);
      }}
     />
    </Modal>
   )}
   {CutoffPopup.popup && (
    <Modal
     isOpen={CutoffPopup.popup}
     style={modalStyles}
     onRequestClose={() =>
      setCutoffPopup({
       popup: false,
       cutoffdata: "",
      })
     }
     contentLabel=""
     className={styles.modal_cart_details123}
    >
     <CutoffPopupView
      meal_type={CutoffPopup.cutoffdata}
      // onClickClose={() => setCutoffPopup(false)}
      onClickOk={handleOkCutoffTimePopup}
     />
    </Modal>
   )}
  </div>
 );
};

export default FoodPage;
