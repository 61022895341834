import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState, useEffect } from "react";
import { BsChevronDoubleDown } from "react-icons/bs";
import { BASE_URL } from "../../helper/base_url";
import { GetAllImagesApi } from "../../helper/http_api";

import styles from "../../styles/home_section_middle.module.css";

const HomeSectionMiddle = ({ scrollToHomeSectionBottom }) => {
  const [imageUrl, setImageUrl] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [carouselItems, setCarouselItems] = useState(3);
  const [rerender, setRerender] = useState(1);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  /* optimised code for getting media data from server */
  const getAllImages = async () => {
    try {
      const response = await GetAllImagesApi({});
      const { status, All_Files } = response.data;

      if (status === "success") {
        const Section2_files = All_Files.Section2_files || [];
        setImageUrl(Section2_files);
        setIsImageLoaded(true);
      } else {
        setIsImageLoaded(false);
      }
    } catch (error) {
      console.error("Error occurred while getting images from server:", error);
      setIsImageLoaded(false);
    }
  };

  useEffect(() => {
    getAllImages();
  }, []);

  useEffect(() => {
    setRerender(rerender + 1);
  }, [imageUrl.length]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 912) {
        setCarouselItems(1);
        setIsSmallScreen(true);
      } else {
        setCarouselItems(3);
        setIsSmallScreen(false);
      }
      if (
        window.matchMedia("(max-height: 575.98px) and (orientation: landscape)")
          .matches
      ) {
        setCarouselItems(3);
        setIsLandscape(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className={styles.body}>
        <div className={styles.page_desc}>
          <p style={{ lineHeight: 1.6 }}>
            We are very excited and happy to invite you to our Sengal Boutique
            Hotel (SBH), located in a potter village in Puducherry. It is also
            close by to the universal town Auroville (The City of Dawn).
          </p>
          <p style={{ lineHeight: 1.6 }}>
            The Brutalist Architecture of SBH stands out in its context, with a
            conscious design choice to build in local and visually familiar
            materials from nature. The layers of Sengal walls, yellow stone
            flooring, hand crafted granite fixtures, blended with a personalized
            garden view, gives an immense sense of Nature breeze in every room.
          </p>
        </div>

        {isImageLoaded && (
          <div className="container-fluid">
            <OwlCarousel
              items={carouselItems}
              className={styles.owl_theme}
              loop
              // nav
              margin={60}
              autoplay={true}
              autoplayTimeout={5000}
              dots={false}
            >
              {rerender &&
                imageUrl?.map((item, index) => {
                  return (
                    <>
                      <div key={index} className={styles.image_cont}>
                        <img
                          crossOrigin="anonymous"
                          src={`${BASE_URL}/sengal_admin/downloadFile/Section2_adminFiles${item.image_url}`}
                          alt="SliderImg"
                          className={styles.image1s}
                        />
                      </div>
                    </>
                  );
                })}
            </OwlCarousel>
          </div>
        )}

        {isSmallScreen || isLandscape ? (
          <></>
        ) : (
          <button
            className={styles.learn_more_button}
            onClick={scrollToHomeSectionBottom}
          >
            <BsChevronDoubleDown size={20} />
          </button>
        )}
      </div>
    </>
  );
};

export default HomeSectionMiddle;
