import { useNavigate } from "react-router-dom";
import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import SpinnerLoader from "../components/commoncomponents/spinner_loader";
import Header_component from "../components/commoncomponents/header_component";
import styles from "../styles/foodstyles/cartstyles/cart_page.module.css";
import { STRINGS, userTimezone } from "../model/constant";
import { CartContext } from "../context/cart_context";
import Sbh_Cart_component from "../components/foodcomponents/cart/Sbh_Cart_component";
import { getCutOffTimeApi, SBH_Get_Cart, SBH_Get_Restaurant_Cart } from "../helper/http_api";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import PlaceorderPopup_Styles from "../styles/foodstyles/cartstyles/cart_page_popup.module.css";
import { IoCloseOutline } from "react-icons/io5";
import PlaceOrderComponent from "../components/foodcomponents/cart/placeOrderComponent";
import { Empty_cart_component } from "../components/foodcomponents/cart/Empty_cart_component";
import { Other_restaurantCart_component } from "../components/foodcomponents/cart/Other_restaurantCart_component";
Modal.setAppElement("body");

// modal styles
const modalStyles = {
 overlay: {
  backgroundColor: "rgba(0,0,0,0.5)",
 },
};
const Cart_page = () => {
 const navigate = useNavigate();
 const {
  userAuthentication_Details,
  currentSelectedDate,
  cartFood,
  setCartFood,
  setSpecialInstruction,
  cartOthers,
  setCartOthers,
 } = useContext(CartContext);
 const alert = useAlert();
 const [showSpinner, setShowSpinner] = useState(false);
 const [tabSelector, setTabSelector] = useState(true);
 const [Breakfastmin, setBreakfastmin] = useState("-");
 const [Lunchmin, setLunchmin] = useState("-");
 const [Dinnermin, setDinnermin] = useState("-");
 const [isAlertShowing, setIsAlertShowing] = useState(false);
 const [showPlaceOrderMsg, setShowPlaceOrderMsg] = useState(false);
 const [orderPlacedSuccess, setOrderPlacedSuccess] = useState(false);
 const [isRestaurant_show, setRestaurant_show] = useState(0);

 /* show Alert message handler  */
 const alert_Predict_Func = (msg) => {
  if (!isAlertShowing) {
   setIsAlertShowing(true);
   alert.show(msg, {
    timeout: 3000,
    onClose: () => setIsAlertShowing(false),
   });
  }
 };

 useLayoutEffect(() => {
  setShowSpinner(true);
 }, []);

 useEffect(() => {
  cutofftimeValuefunction();
  getCartDetails();
 }, []);

 const getCartDetails = () => {
  cartFood?.length ? setTabSelector(true) : cartOthers?.length ? setTabSelector(false) : "";
  // if (tabSelector) {
  //   SBH_Get_Cart({
  //    user_email: userAuthentication_Details?.loggedIn_user_email,
  //    meal_date: currentSelectedDate,
  //   })
  //    .then((data) => {
  //     setShowSpinner(false);
  //     if (data) {
  //      if (data?.status) {
  //       setCartFood([...data.Cart_data]);
  //       setSpecialInstruction(data?.spl_ins ? data.spl_ins : "");
  //       data.Cart_data.length ? setTabSelector(true) : "";
  //       Get_restaurant_api_func(data.Cart_data.length);
  //      } else {
  //       console.log("No data in Food cart");
  //       Get_restaurant_api_func();
  //      }
  //     } else {
  //      alert_Predict_Func(STRINGS.somethingunexpected);
  //     }
  //    })
  //    .catch((err) => {
  //     setShowSpinner(false);
  //     alert_Predict_Func(STRINGS.somethingunexpected);
  //    });
 };

 //  const Get_restaurant_api_func = (isLength) => {
 //   SBH_Get_Restaurant_Cart({
 //    user_email: userAuthentication_Details?.loggedIn_user_email,
 //    meal_date: currentSelectedDate,
 //   }).then((data) => {
 //    if (data) {
 //     if (data?.status) {
 //      setCartOthers([...data.res_details]);
 //      !isLength && data.res_details.length ? setTabSelector(false) : "";
 //     } else {
 //      console.log("No data in restaurant cart");
 //     }
 //    } else {
 //     alert_Predict_Func(STRINGS.somethingunexpected);
 //    }
 //   });
 //  };
 const cutofftimeValuefunction = async () => {
  try {
   const cutofftimeresponsedata = await getCutOffTimeApi();
   setShowSpinner(false);
   if (cutofftimeresponsedata?.data?.status) {
    let options = {
     hour: "numeric",
     minute: "numeric",
     hour12: true,
     timeZone: userTimezone,
    };
    let breakfast = new Date(cutofftimeresponsedata.data.data[0].Breakfast);
    let lunch = new Date(cutofftimeresponsedata.data.data[0].Lunch);
    let dinner = new Date(cutofftimeresponsedata.data.data[0].Dinner);
    let breakfastmin = breakfast.toLocaleTimeString("en-US", options);
    let lunchmin = lunch.toLocaleTimeString("en-US", options);
    let dinnermin = dinner.toLocaleTimeString("en-US", options);
    console.error("time value", breakfast, lunch, dinner);
    setBreakfastmin(breakfastmin);
    setLunchmin(lunchmin);
    setDinnermin(dinnermin);
   } else {
    setBreakfastmin("-");
    setLunchmin("-");
    setDinnermin("-");
   }
  } catch (err) {
   console.log("getting error", err);
  }
 };

 const handleNavigate = () => {
  if (isRestaurant_show == 1) {
   setTabSelector(false), setShowPlaceOrderMsg(false);
  } else {
   //need to handle if cart other completed need to show sbh foods
   isRestaurant_show == 2 ? setTabSelector(true) : navigate("/food_page");
   setShowPlaceOrderMsg(false);
  }
 };

 const goHome = () => {
  navigate("/food_page");
 };

 return (
  <>
   {showSpinner && <SpinnerLoader />}
   <Header_component />
   {showPlaceOrderMsg && (
    <Modal
     isOpen={showPlaceOrderMsg}
     style={modalStyles}
     onRequestClose={() => handleNavigate()}
     contentLabel="Food Modal"
     className={PlaceorderPopup_Styles.modal_verify_mobile}
    >
     <div
      className={PlaceorderPopup_Styles.cancel_icon_view_verify}
      onClick={() => handleNavigate()}
     >
      <IoCloseOutline size={24} className={PlaceorderPopup_Styles.closeicon_verify} />
     </div>
     <PlaceOrderComponent
      isRestaurant_show={isRestaurant_show}
      orderPlacedSuccess={orderPlacedSuccess}
     />
    </Modal>
   )}
   <div className={styles.cart_title}>{STRINGS.cart_title}</div>
   <div className={styles.sub_header}>
    <div className={styles.reserv_room}>
     {STRINGS.reserv_rooms}
     <span className={styles.blacktext}>{userAuthentication_Details?.roomIds.join(", ")}</span>
    </div>
    {tabSelector && cartFood?.length == 0 ? (
     <div className={styles.cutoff_time}>
      Kindly place your SBH food before following times:
      <span>
       <b>Breakfast {window.outerWidth < 1439 ? <>&nbsp;</> : null}-&nbsp;</b>
       {Breakfastmin}
      </span>
      <span>
       <b>Lunch &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-</b> {Lunchmin}
      </span>
      <span>
       <b>Dinner &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-</b> {Dinnermin}
      </span>
     </div>
    ) : (
     <div className={styles.meal_date}>
      {cartOthers?.length == 0 && !tabSelector ? (
       <></>
      ) : (
       <>
        {STRINGS.meal_date} <span className={styles.blacktext}>{currentSelectedDate}</span>
       </>
      )}
     </div>
    )}
   </div>
   <div className={styles.tab_container}>
    <div
     className={tabSelector ? styles.tab_sbh_button : styles.tab_button_dim}
     onClick={() => setTabSelector(true)}
     tabIndex={0}
     onKeyDown={(event) => {
      if (event.key == "Enter") {
       setTabSelector(true);
      }
     }}
    >
     {STRINGS.sbh_food}
    </div>
    <div
     className={tabSelector ? styles.tab_button_dim : styles.tab_sbh_button}
     onClick={() => setTabSelector(false)}
     tabIndex={0}
     onKeyDown={(event) => {
      if (event.key == "Enter") {
       setTabSelector(false);
      }
     }}
    >
     {STRINGS.other_res}
    </div>
   </div>

   {cartFood?.length > 0 && tabSelector ? (
    <Sbh_Cart_component
     cutoff_data={{ Breakfastmin: Breakfastmin, Lunchmin: Lunchmin, Dinnermin: Dinnermin }}
     ShowSpinner={setShowSpinner}
     alert_Predict_Func={alert_Predict_Func}
     setShowPlaceOrderMsg={setShowPlaceOrderMsg}
     setOrderPlacedSuccess={setOrderPlacedSuccess}
     setRestaurant_show={setRestaurant_show}
    />
   ) : cartOthers?.length > 0 && !tabSelector ? (
    <Other_restaurantCart_component
     ShowSpinner={setShowSpinner}
     alert_Predict_Func={alert_Predict_Func}
     setShowPlaceOrderMsg={setShowPlaceOrderMsg}
     setOrderPlacedSuccess={setOrderPlacedSuccess}
     setRestaurant_show={setRestaurant_show}
    />
   ) : (
    <Empty_cart_component goHome={goHome} />
   )}
  </>
 );
};

export default Cart_page;
