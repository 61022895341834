import React from "react";
import styles from "../../../styles/foodstyles/cartstyles/cart_page.module.css";
import { Delete_icon, Minus_icon, Plus_icon } from "../../../assets/svg/cart_svg";
import { STRINGS } from "../../../model/constant";

const Cart_sepration_wrapper = ({
 cartSepration,
 decCartOrderQuantity,
 incCartOrderQty,
 remove_Cart_items,
 userAuthentication_Details,
 currentSelectedDate,
}) => {
 //Need to handle if once user added to cart it will be free userAuthentication_Details?.mapped_Dates[currentSelectedDate] == true;
 return (
  <>
   {cartSepration.map((item, index) => {
    // Calculate price based on cart quantity
    let cartItemPrice =
     userAuthentication_Details?.mapped_Dates[currentSelectedDate] == true &&
     item?.complementary === true
      ? 0 * cartSepration[index].orderQty
      : item?.price * cartSepration[index].orderQty;
    // console.error("getting dataa===>", cartItemPrice);
    return (
     <div key={index}>
      <div className={styles.item_name_container}>
       <div className={styles.item_name}>{item.mainDish}</div>
       {item?.complementary === true &&
       userAuthentication_Details?.booking_details?.length > 0 &&
       userAuthentication_Details?.mapped_Dates[currentSelectedDate] == true ? (
        <div className={styles.costprice}>Free</div>
       ) : (
        <div className={styles.costprice}>Rs.{cartItemPrice}.00</div>
       )}
      </div>
      <div className={styles.side_dish_container}>
       {item.sideDish?.map((item_value, index) => {
        return (
         <div key={index} className={styles.side_dish}>
          {item_value}
         </div>
        );
       })}
      </div>
      <div className={styles.quantity_container}>
       <div className={styles.qty_text}>{STRINGS.qty}</div>
       <div className={styles.qty_control}>
        <div
         className={styles.qty_plusminus}
         onClick={() => {
          decCartOrderQuantity(item.orderQty, item.mainDish, item.foodCategory);
         }}
         style={
          item.orderQty > 1
           ? { cursor: "pointer" }
           : { cursor: "not-allowed", color: "rgba(0, 0, 0, 0.541)" }
         }
        >
         <Minus_icon />
        </div>
        <div className={styles.qty_value}>{item.orderQty}</div>
        <div
         className={styles.qty_plusminus}
         onClick={() => {
          incCartOrderQty(item.orderQty, item.mainDish, item.foodCategory);
         }}
         style={
          item.orderQty < 40
           ? { cursor: "pointer" }
           : { cursor: "not-allowed", color: "rgba(0, 0, 0, 0.541)" }
         }
        >
         <Plus_icon />
        </div>
       </div>
       <div
        className={styles.delete_container}
        onClick={() => remove_Cart_items(item.mainDish, item.foodCategory)}
       >
        <Delete_icon />
        <div className={styles.remove_txt}>{STRINGS.remove}</div>
       </div>
      </div>
      {item.orderQty >= 40 && (
       <div className={styles.max_limit_error_note}>
        {STRINGS.max_limit_error_msg}
       </div>
      )}
     </div>
    );
   })}
  </>
 );
};

export default Cart_sepration_wrapper;
