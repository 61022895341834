import { useNavigate } from "react-router-dom";
import Styles from "../styles/foodstyles/pagenot_found.module.css";

const Page_Not_Found = () => {
 const navigate = useNavigate();
 return (
  <div className={Styles.page_Not_Found_Container}>
   <div className={Styles.page_Not_Found_Title}>404 Error</div>

   <div className={Styles.page_Not_Found_SubTitle}>Page not Found</div>

   <div className={Styles.page_Not_Found_Content}>
    Sorry, the page you’re looking for doesn't exist.
   </div>

   <div onClick={() => navigate("/")} className={Styles.page_Not_Found_Button_View}>
    Go Home
   </div>
  </div>
 );
};

export default Page_Not_Found;
