import io from "socket.io-client";
import { APP_CONFIG } from "../model/constant";
import eventEmitter from "../store/evenemitter_Intializer";

const socket_Create_Func = async (userData) => {
 console.log("initalizeeee", APP_CONFIG.SOCKET_IO_URL);
 const sockets = io(APP_CONFIG.SOCKET_IO_URL, {
  path: "/sengalSocketIo/socket.io",
  jsonp: true,
  secure: true,
  rejectUnauthorized: false,
 });

 socketInstance_Events(sockets, userData);
};

const socketInstance_Events = (socket, userData) => {
 socket.on("connect", () => {
  console.log("CLIENT IS CONNECTED", socket.connected);
  socket.emit("room", userData);
 });
 socket.on("logout", (userData) => {
  console.log("Logged out triggering====>", userData);
  eventEmitter.emit("Logout_Event", userData);
 });
 socket.on("disconnect", (err) => {
  console.log("socket Closing===>", err?.message);
 });
};

export { socket_Create_Func };
