import React, { useContext } from "react";
import { CartContext } from "../../context/cart_context";
import styles from "../../styles/foodstyles/Banner.module.css";

const Banner = () => {
  const { isRegistered } = useContext(CartContext);

  // username
  const verified_user_name = localStorage.getItem("verified_user_name");
  return (
    <section className={styles.banner_section}>
      <div className={styles.banner_cont}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "#AD9D80",
            rowGap: "10px",
          }}
          className={styles.banner_content}
        >
          {isRegistered && (
            <div className={styles.text3}>Welcome, {verified_user_name}</div>
          )}

          <div className={styles.text1}>Hungry? We've Got You Covered</div>
          <div className={styles.text2}>
            Effortless Ordering, Unmatched Flavors
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
