import React, { useState, useRef } from "react";
import { IMAGES } from "../helper/images";
import { Parallax } from "react-parallax";
import { CiUser } from "react-icons/ci";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { CiMobile3 } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { userRegisterApi, userForgotPasswordApi } from "../helper/http_api";
import { useAlert } from "react-alert";
import HeaderWithIcon from "../components/commoncomponents/header_home_icon";
import styles from "../styles/register.module.css";
import { setBookFunc } from "../helper/temporary_data";

function Register() {
 const InputRef = useRef(null);

 const [showPassword, setShowPassword] = useState(false);
 const [showConfirmPassword, setConfirmPassword] = useState(false);
 const [userSignUpData, setUserSignUpData] = useState({
  userName: "",
  userMobile: "",
  userEmail: "",
  userPassword: "",
  userConfirmPassword: "",
 });

 const alert = useAlert(); // Initialize the useAlert hook

 const [showSpinner, setShowSpinner] = useState(false);
 const [isButtonDisabled, setIsButtonDisabled] = useState(false);
 // const [registerDataStored, setRegisterDataStored] = useState(false);
 const navigate = useNavigate();

 const handleChange = (e) => {
  const { name, value } = e.target;

  /* custom validity for password */
  if (name === "userPassword") {
   if (value.trim().length === 0) {
    e.target.setCustomValidity("Please enter a valid password.");
   } else if (value.length < 8) {
    e.target.setCustomValidity("Password must be at least 8 characters long.");
   } else if (value.length > 16) {
    e.target.setCustomValidity("Password cannot be longer than 16 characters.");
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity("Password is a required field.");
   }
  }

  /* custom validity for confirm password */
  if (name === "userConfirmPassword") {
   if (value.trim().length === 0) {
    e.target.setCustomValidity("Please enter a valid confirm password.");
   } else if (value.length < 8) {
    e.target.setCustomValidity("Confirm password must be at least 8 characters long.");
   } else if (value.length > 16) {
    e.target.setCustomValidity("Confirm password cannot be longer than 16 characters.");
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity("Confirm password is a required field.");
   }
  }

  /* custom validity for email */
  if (name === "userEmail") {
   const emailPattern = /^[a-z0-9._%+]+@[a-z0-9.]+\.[a-z]{2,}$/i;
   if (!emailPattern.test(value)) {
    e.target.setCustomValidity("Please enter a valid email Id.");
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity("Email is a required field.");
   }
  }

  /* custom validity for phone */
  if (name === "userMobile") {
   const isAllZeros = /^\d+$/.test(value) && parseInt(value, 10) === 0;
   const startsWithMultipleZeros = /^0{2,}/.test(value);
   const hasRepeatingDigits =
    /(0{6,}|1{6,}|2{6,}|3{6,}|4{6,}|5{6,}|6{6,}|7{6,}|8{6,}|9{6,})/.test(value);

   if (value.length < 10) {
    e.target.setCustomValidity("Phone number must be 10 digits long.");
   } else if (isAllZeros) {
    e.target.setCustomValidity("Invalid phone number.");
   } else if (startsWithMultipleZeros) {
    e.target.setCustomValidity("Invalid phone number.");
   } else if (hasRepeatingDigits) {
    e.target.setCustomValidity("Invalid phone number.");
   } else {
    e.target.setCustomValidity("");
   }
  }

  /* custom validity for name */
  if (name === "userName") {
   const namePattern = /^[a-zA-Z ]+$/;
   if (!namePattern.test(value)) {
    e.target.setCustomValidity("Please enter alphabetic characters only.");
   } else if (value.length < 3) {
    e.target.setCustomValidity("Name shouldn't be less than 3 characters.");
   } else if (value.length > 26) {
    e.target.setCustomValidity("Name shouldn't be more than 26 characters.");
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity("Name is a required field.");
   }
  }

  setUserSignUpData((prevUserSignUpData) => ({
   ...prevUserSignUpData,
   // [name]: value.trim(),
   [name]: value,
  }));
 };

 const register_api_payload = {
  name: userSignUpData.userName.trim(),
  email: userSignUpData.userEmail,
  phone: parseInt(userSignUpData.userMobile),
  password: userSignUpData.userPassword,
  isAdmin: false,
 };

 const handleSubmit = (e) => {
  e.preventDefault();
  setShowSpinner(true);
  // disabling button
  setIsButtonDisabled(true);

  if (userSignUpData.userPassword !== userSignUpData.userConfirmPassword) {
   alert.success("Passwords does not match. Please try again");
   setShowSpinner(false);
   // enabling button
   setTimeout(() => {
    setIsButtonDisabled(false);
   }, 4000);
  } else {
   const registerPromise = new Promise(async (resolve, reject) => {
    try {
     const response = await userRegisterApi(register_api_payload);
     resolve(response.data);
    } catch (error) {
     reject(error);
    }
   });

   registerPromise
    .then((data) => {
     const {
      success,
      message,
      name,
      phone,
      email,
      _id,
      access_token,
      loginStatus,
      isVerified,
     } = data;
     // setShowSpinner(false);

     setTimeout(() => {
      setShowSpinner(false);
      if (success) {
       const queryParams = new URLSearchParams({
        userMail: email,
        userPhone: phone,
        userName: name,
        userId: _id,
        userAccessToken: access_token,
        userLoginStatus: loginStatus,
        userIsVerified: isVerified,
       });

       !isVerified && navigate(`/user/auth/mail_verify?${queryParams}`);
       sendMailVerificationCode({ email: email });
       // : navigate(`/`);

       // navigate(`/`);
      } else {
       // console.log("### user registration failed !", message);
       // alert(message);
       alert.error(message);
      }
      // enabling button
      setTimeout(() => {
       setIsButtonDisabled(false);
      }, 4000);
     }, 1000);
    })
    .catch((error) => {
     setShowSpinner(false);
     console.error("Error occurred while storing user details in server:", error);
     alert.error("Oops! Something unexpected happened.");
     // enabling button
     setTimeout(() => {
      setIsButtonDisabled(false);
     }, 4000);
    });
  }
 };

 const handleHomeClick = () => {
  setShowSpinner(true);
  setTimeout(() => {
   setShowSpinner(false);
   setBookFunc(false);
   navigate(`/`);
  }, 1000);
 };

 const sendMailVerificationCode = (payload) => {
  // e.preventDefault();
  // console.log(userForgotPasswordData);
  setShowSpinner(true);

  const forgotPasswordPromise = new Promise(async (resolve, reject) => {
   try {
    const response = await userForgotPasswordApi(payload);
    resolve(response.data);
   } catch (error) {
    reject(error);
   }
  });

  forgotPasswordPromise
   .then((data) => {
    // console.log("verification data", data);
    const { success, message } = data;
    // setShowSpinner(false);

    setTimeout(() => {
     setShowSpinner(false);
     if (success) {
      // console.log("### forgot password email match successfull! ");
      // alert(message);
      alert.success(message);
      // const queryParams = new URLSearchParams({
      //   forgotpassword_mail: forgotPassword_api_payload.email,
      //   userId: id,
      // });
      // navigate(`/user/auth/forgot_password_verify?${queryParams}`);
     } else {
      // console.log("### forgot password email match failed ! ", message);
      // alert(message);
      alert.error(message);
     }
    }, 1000);
   })
   .catch((error) => {
    setShowSpinner(false);
    console.error("Error occured while sending otp for mail verification from server", error);
    alert.error("Oops! Something unexpected happened.");
   });
 };

 return (
  <div>
   <Parallax
    // blur={10}
    bgImage={IMAGES.bgImg_section1}
    bgImageAlt="section1"
    // strength={120}
   >
    <div className={styles.content}>
     {/* active spinner */}
     {showSpinner && (
      <div className={styles.spinner_layout}>
       <SpinnerDotted color="white" />
      </div>
     )}

     <HeaderWithIcon HomeIconClick={handleHomeClick} />

     {/* center content */}
     <form onSubmit={handleSubmit} className={styles.form_container}>
      <div className={styles.register_details_cont}>
       <div className={styles.signup_title}>Sign Up</div>
       <div className={styles.user_input}>
        <div className={styles.input_field_cont}>
         <input
          type="text"
          placeholder="Full Name"
          className={styles.input_field1}
          name="userName"
          value={userSignUpData.userName}
          onChange={handleChange}
          required
          autoComplete="off"
          maxLength="26"
          minLength="3"
          pattern="[A-Za-z ]{1,}"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          ref={InputRef}
         />
         <div className={styles.input_icons}>
          <CiUser size={20} />
         </div>
        </div>
        <div className={styles.input_field_cont}>
         <input
          type="tel"
          placeholder="Phone"
          className={styles.input_field2}
          name="userMobile"
          value={userSignUpData.userMobile}
          onChange={handleChange}
          required
          autoComplete="off"
          maxLength="10"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          onInput={(e) => {
           const input = e.target.value;
           const numericInput = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
           e.target.value = numericInput;
          }}
          ref={InputRef}
         />
         <div className={styles.input_icons}>
          <CiMobile3 size={20} />
         </div>
        </div>
        <div className={styles.input_field_cont}>
         <input
          type="email"
          placeholder="Email"
          className={styles.input_field3}
          name="userEmail"
          value={userSignUpData.userEmail}
          onChange={handleChange}
          required
          autoComplete="off"
          maxLength="30"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
         />
         <div className={styles.input_icons}>
          <CiMail size={20} />
         </div>
        </div>

        <div className={styles.input_field_cont}>
         <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          className={styles.input_field4}
          name="userPassword"
          value={userSignUpData.userPassword}
          onChange={handleChange}
          // minLength="8"
          maxLength="16"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          required
          autoComplete="off"
          ref={InputRef}
          //   pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
         />
         <div className={styles.input_icons}>
          {showPassword ? (
           <BsEye
            size={20}
            onClick={() => setShowPassword(false)}
            className={styles.eye_icon}
           />
          ) : (
           <BsEyeSlash
            size={20}
            onClick={() => setShowPassword(true)}
            className={styles.eye_icon}
           />
          )}
         </div>
        </div>
        <div className={styles.input_field_cont}>
         <input
          type={showConfirmPassword ? "text" : "password"}
          placeholder="Confirm Password"
          className={styles.input_field4}
          name="userConfirmPassword"
          value={userSignUpData.userConfirmPassword}
          onChange={handleChange}
          // minLength="8"
          maxLength="16"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          required
          autoComplete="off"
          ref={InputRef}
         />
         <div className={styles.input_icons}>
          {showConfirmPassword ? (
           <BsEye
            size={20}
            onClick={() => setConfirmPassword(false)}
            className={styles.eye_icon}
           />
          ) : (
           <BsEyeSlash
            size={20}
            onClick={() => setConfirmPassword(true)}
            className={styles.eye_icon}
           />
          )}
         </div>
        </div>
       </div>
      </div>
      <div className={styles.login_details_cont1}>
       <div className={styles.below_upper_cont}>
        <div className={styles.submit_btn_cont}>
         <button type="submit" className={styles.btn_submit} disabled={isButtonDisabled}>
          <div className={styles.btn_cont}>
           <div className={styles.btn_name}>Submit</div>
          </div>
         </button>
        </div>
       </div>
       <div className={styles.below_lower_cont}>
        Already have an account?
        <Link to="/user/auth/login" className={styles.login_link}>
         <span className={styles.login_txt}>Sign In</span>
        </Link>
       </div>
      </div>
     </form>
    </div>
   </Parallax>
  </div>
 );
}

export default Register;
