import { useContext, useState, useRef, useEffect, useCallback } from "react";
import Logo from "../../assets/img/logo/logo_sengal.png";
import { Link } from "react-router-dom";
import { PiShoppingCartThin } from "react-icons/pi";
import { IoCloseOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
import { CiMobile3 } from "react-icons/ci";
import Modal from "react-modal";
import { useAlert } from "react-alert";
import { CartContext } from "../../context/cart_context";
import SpinnerLoader from "../commoncomponents/spinner_loader";
import {
 foodPageVerifyMailApi,
 resendVerifyCodeApi,
 setNewPasswordApi,
 userForgotPasswordApi,
 userForgotPasswordVerifyApi,
 userLoginApi,
 userLogoutAPI,
 userRegisterApi,
} from "../../helper/http_api";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import styles from "../../styles/foodstyles/Nav.module.css";
import SignOutAlert from "./sign_out_alert_msg";
import { STRINGS, userTimezone } from "../../model/constant";
import {
 getLocalStorage,
 removeLocalStorage,
 setLocalStorage,
 updateCartAllFood,
 updateStorage_allData,
} from "../../helper/localstorage_update";
import { useNavigate } from "react-router";
import loginStyles from "../../styles/login_component.module.css";
import registerSyles from "../../styles/register_component.module.css";
import forgotpasswordStyles from "../../styles/fogotpassword_component.module.css";
import otpverifyStyles from "../../styles/otp_verify_component.module.css";
import newpasswordStyles from "../../styles/set_newPassword_component.module.css";
import alertPopupStyles from "../../styles/alert_popup.module.css";
import authmobileVerifyStyles from "../../styles/authmobileVerify_component.module.css";
import ConfirmTemplate from "../commoncomponents/confirm_popup";
// import alertPopupStyles from "../../"
// bind modal to body
Modal.setAppElement("body");

// modal styles
const modalStyles = {
 overlay: {
  backgroundColor: "rgba(0,0,0,0.5)",
 },
};

// let newIndia_Date = new Date()
//  .toLocaleString("en-US", { timeZone: userTimezone })
//  .split(",")[0];
// console.log("getting perfect format", newIndia_Date);
// const year = newIndia_Date.split("/")[2];
// const month = newIndia_Date.split("/")[0].padStart(2, "0"); // Adding 1 because getMonth() returns zero-based month
// const day = newIndia_Date.split("/")[1].padStart(2, "0");
// const formattedCurrentDate = `${year}-${month}-${day}`;
let timerID;

const Nav = ({ alert_Predict_Func, spinner_Check }) => {
 const InputRef = useRef(null);
 const alert = useAlert(); // Initialize the useAlert hook
 const Navigate = useNavigate();

 const {
  isOpenFoodCart,
  setIsOpenFoodCart,
  setVerifiedMailUserData,
  nearbyRestaurantTab,
  isOpenRestaurantCart,
  setIsOpenRestaurantCart,
  modalVerifyMail,
  setModalVerifyMail,
  foodCartQtyAmount,
  restaurantCartQtyAmount,
  totalcart_Quantity,
  cartFood,
  cartOthers,
  setCartFood,
  setUserDetails,
  setIsCartOpen,
  setCartOthers,
  setCartFoodTotal,
  setFoodCartRoomNo,
  setSpecialInstruction,
  setRestaurantCartQtyAmount,
  setCurrentSelectedDate,
  setDateChangeValueforChange,
  setArrows,
  setUserAuthentication_Details,
  userAuthentication_Details,
  setRegisteredPopup,
  registeredPopup,
  modalVerifyMobile,
  setModalVerifyMobile,
  updateLoggedIn_Func,
  updateMobileVerify_Func,
  logout_Func,
 } = useContext(CartContext);

 const [showSpinner, setShowSpinner] = useState(false);
 const [mobileVerify, setMobileVerify] = useState({ phone: "" });
 const [verifyMailData, setVerifyMailData] = useState({
  userEmail: "",
  userPassword: "",
 });
 const [showSignOutAlert, setShowSignOutAlert] = useState(false);
 const [isExpired, setIsExpired] = useState(false);
 const [showPassword, setShowPassword] = useState(false);
 const [showsignupPassword, setShowsignupPassword] = useState(false);
 const [showConfirmPassword, setConfirmPassword] = useState(false);
 const [userSignUpData, setUserSignUpData] = useState({
  userName: "",
  userMobile: "",
  userEmail: "",
  userPassword: "",
  userConfirmPassword: "",
 });
 const [userForgotPasswordData, setUserForgotPasswordData] = useState({
  userEmail: "",
 });
 const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);
 const [userForgotPasswordVerifyData, setUserForgotPasswordVerifyData] = useState({
  verifyCode: "",
 });
 const [otpVerifyPopup, setOtpVerifyPopup] = useState(false);
 const [showVerifyCode, setVerifyCode] = useState(false);
 const [countdown, setCountdown] = useState(0);
 const [tempUsers_Data, setTempUser_Data] = useState({});
 const [userNewPasswordData, setUserNewPasswordData] = useState({
  newPassword: "",
  confirmPassword: "",
 });
 const [newpasswordPopup, setNewpasswordPopup] = useState(false);
 const [showNewPassword, setNewPassword] = useState(false);
 const [shownewConfirmPassword, setNewConfirmPassword] = useState(false);

 useEffect(() => {
  if (countdown > 0) {
   timerID = setInterval(() => {
    setCountdown(countdown - 1);
   }, 1000);
  }
  return () => {
   clearInterval(timerID);
  };
 }, [countdown]);

 // open modal
 const openModal_verifymail = () => {
  setModalVerifyMail(true);
 };
 // close modal
 const closeModal_verifymail = () => {
  setVerifyMailData({
   userEmail: "",
  });
  setModalVerifyMail(false);
  setArrows(false);
 };

 const indian_DatesConversion = (dates) => {
  let overallDate = dates.map((date) => {
   return `${dates.split("T")[0]}`;
  });
  return overallDate;
 };
 const setAllFoodCart_Data = (data) => {
  let All_FoodDetails = {};
  All_FoodDetails[data?.user_email] = {
   sbh_food: [],
   nearby_food: [],
   sbh_special_instruction: "",
   roomNumber: 0,
   sbh_totalPrice: "",
   nearbycart_Qty: 0,
   current_State: 0,
  };
  localStorage.setItem("All_FoodDetails", JSON.stringify(All_FoodDetails));
 };

 const webApplicationState_check = (current_State) => {
  // console.log("current statuse", current_State);
  switch (current_State) {
   case 0:
    break;
   case 1:
    setIsCartOpen(true);
    setIsOpenFoodCart(true);
    break;
   case 2:
    setIsCartOpen(true);
    setIsOpenRestaurantCart(true);
    break;
  }
 };
 const getAllFoodCart_Data = (value) => {
  const user_email = localStorage.getItem("verified_user_email");
  const All_FoodDetails = JSON.parse(localStorage.getItem("All_FoodDetails"));
  // console.log("get All_FoodDetails", All_FoodDetails);
  if (value) {
   All_FoodDetails && All_FoodDetails[user_email]
    ? setAllFoodCart_Data({ user_email: user_email })
    : "";
  } else {
   if (All_FoodDetails && All_FoodDetails[user_email]) {
    setCartFood(All_FoodDetails[user_email].sbh_food);
    setCartOthers(All_FoodDetails[user_email].nearby_food);
    setFoodCartRoomNo(All_FoodDetails[user_email].roomNumber);
    setCartFoodTotal(All_FoodDetails[user_email].sbh_totalPrice);
    setSpecialInstruction(All_FoodDetails[user_email].sbh_special_instruction);
    // setRestaurantCartQtyAmount(All_FoodDetails[user_email].nearbycart_Qty);
    webApplicationState_check(All_FoodDetails[user_email].current_State);
   }
  }
 };

 const handleChange = (e) => {
  const { name, value } = e.target;
  /* custom validity for password */
  if (name === "userPassword") {
   if (value.trim().length === 0) {
    e.target.setCustomValidity("Please enter a valid password.");
   } else if (value.length < 8) {
    e.target.setCustomValidity("Password must be at least 8 characters long.");
   } else if (value.length > 16) {
    e.target.setCustomValidity("Password cannot be longer than 16 characters.");
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity("Password is a required field.");
   }
  }

  /* custom validity for email */
  if (name === "userEmail") {
   const emailPattern = /^[a-z0-9._%+]+@[a-z0-9.]+\.[a-z]{2,}$/i;
   if (!emailPattern.test(value)) {
    e.target.setCustomValidity("Please enter a valid email Id.");
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity("Email is a required field.");
   }
  }

  setVerifyMailData((prevUserLoginData) => ({
   ...prevUserLoginData,
   [name]: value,
  }));
 };

 const handleLogout = async (userData) => {
  let sessionData = await getLocalStorage(["sessionID"]);
  console.log("getting user session id", sessionData?.sessionID, userData?.sessionID);
  // if (sessionData?.sessionID != userData?.sessionID) {
  setShowSignOutAlert(false);
  setIsExpired(false);
  setShowSpinner(true);
  try {
   const response = await userLogoutAPI({
    userId: userAuthentication_Details.loggedInUserId,
    sessionID: sessionData?.sessionID,
   });
   let { success } = response?.data;
   setShowSpinner(false);
   if (success) {
    logout_Func();
    alert_Predict_Func(STRINGS.loggedoutToast);
   } else {
    alert_Predict_Func(STRINGS.somethingunexpected);
   }
  } catch (error) {
   setShowSpinner(false);
   alert_Predict_Func(STRINGS.somethingunexpected);
  }
 };

 const handleFoodCartOpen = () => {
  // setIsCartOpen(true);
  setIsOpenRestaurantCart(false);
  //  setIsOpenFoodCart(true);
  Navigate("/food_page/cart", { state: { param: "" } });
 };

 const handleCartOthersOpen = () => {
  // setIsCartOpen(true);
  // setIsOpenFoodCart(false);
  // setIsOpenRestaurantCart(true);
  Navigate("/food_page/cart", { state: { param: "" } });
 };

 const handleRegister = () => {
  setUserSignUpData({
   userName: "",
   userMobile: "",
   userEmail: "",
   userPassword: "",
   userConfirmPassword: "",
  });
  setShowsignupPassword(false);
  setConfirmPassword(false);
  setShowPassword(false);
  setVerifyMailData({
   userEmail: "",
  });
  setModalVerifyMail(false);
  setArrows(true);
  setRegisteredPopup(true);
 };

 const handleLogin = () => {
  setShowsignupPassword(false);
  setRegisteredPopup(false);
  setUserSignUpData({
   userName: "",
   userMobile: "",
   userEmail: "",
   userPassword: "",
   userConfirmPassword: "",
  });
  openModal_verifymail();
 };

 /**
  * Handle close call function
  * @param {*} value
  */
 const handleClose_Call = (value) => {
  switch (value) {
   case 0:
    setVerifyMailData({
     userEmail: "",
    });
    setModalVerifyMail(false);
    setShowPassword(false);
    setArrows(false);
    break;
   case 1:
    setRegisteredPopup(false);
    setUserSignUpData({
     userName: "",
     userMobile: "",
     userEmail: "",
     userPassword: "",
     userConfirmPassword: "",
    });
    setShowsignupPassword(false);
    setConfirmPassword(false);
    setArrows(false);
    break;
   case 2:
    setOtpVerifyPopup(false);
    setUserForgotPasswordVerifyData({
     verifyCode: "",
    });
    setVerifyCode(false);
    clearInterval(timerID);
    setCountdown(0);
    setArrows(false);
    break;
   case 3:
    setForgotPasswordPopup(false);
    setUserForgotPasswordData({
     email: "",
    });
    setArrows(false);
    break;
   case 4:
    setVerifyMailData({
     userEmail: "",
    });
    setShowPassword(false);
    setUserForgotPasswordData({
     userEmail: "",
    });
    setModalVerifyMail(false);
    setForgotPasswordPopup(true);
    setArrows(true);
    break;
   case 5:
    setUserNewPasswordData({
     newPassword: "",
     confirmPassword: "",
    });
    setNewConfirmPassword(false);
    setNewPassword(false);
    setNewpasswordPopup(false);
    setArrows(false);
    break;
   case 6:
    setMobileVerify({ phone: "" });
    setModalVerifyMobile(false);
    setArrows(false);
    break;
  }
 };

 /**
  * @description Handle Login state check
  * @param {*} e
  */
 const handle_LoginChange = (e) => {
  const { name, value } = e.target;
  /* custom validity for password */
  if (name === "userPassword") {
   if (value.trim().length === 0) {
    e.target.setCustomValidity(STRINGS.entervalidepwd);
   } else if (value.length < 8) {
    e.target.setCustomValidity(STRINGS.pwdatleastChar);
   } else if (value.length > 16) {
    e.target.setCustomValidity(STRINGS.pwdNotgreater);
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity(STRINGS.pwdRequired);
   }
  }

  /* custom validity for email */
  if (name === "userEmail") {
   const emailPattern = /^[a-z0-9._%+]+@[a-z0-9.]+\.[a-z]{2,}$/i;
   if (!emailPattern.test(value)) {
    e.target.setCustomValidity(STRINGS.validmail);
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity(STRINGS.emailRequired);
   }
  }

  setVerifyMailData((prevUserLoginData) => ({
   ...prevUserLoginData,
   [name]: value,
  }));
 };

 /**
  * @description Handle Submit login
  * @param {*} e
  */
 const handleLogin_Submit = async (e) => {
  e.preventDefault();
  setShowSpinner(true);
  const login_api_payload = {
   email: verifyMailData.userEmail,
   password: verifyMailData.userPassword,
  };
  if (login_api_payload.password.length < 8) {
   alert_Predict_Func("Password mismatch");
   setShowSpinner(false);
  } else {
   setShowSpinner(false);
   try {
    const response = await userLoginApi(login_api_payload);
    let { success, message, _id } = response?.data;
    if (success) {
     updateLoggedIn_Func(response?.data);
     if (message === "User login successfully") {
      message = STRINGS.welcomeBackLoggedin;
     }
     alert_Predict_Func(message);
     handleClose_Call(0);
    } else {
     if (message === "Email or Password doesn't exists") {
      message = "Please check your email and password.";
      alert_Predict_Func(message);
     } else if (
      message == "OTP has been not verified yet, OTP code sent your email please verify"
     ) {
      handleClose_Call(0);
      const response = await userForgotPasswordApi({
       email: verifyMailData.userEmail,
      });
      let { success, message } = response?.data;
      if (success) {
       setTempUser_Data({ userId: _id, viaForgot: 2 });
       setOtpVerifyPopup(true);
       setArrows(true);
       setCountdown(180);
       alert_Predict_Func(
        "OTP has been not verified yet, OTP code sent your email please verify"
       );
      } else {
       alert_Predict_Func(message);
      }
     } else {
      //  handleClose_Call(0);
      alert_Predict_Func(message);
     }
     // console.log("Error occurred while verifying user login from server", response);
    }
   } catch (error) {
    setShowSpinner(false);
    console.error("Error occurred while storing user details in server:", error);
    alert_Predict_Func(STRINGS.somethingunexpected);
   }
  }
 };

 /**
  * @description Handle Register change
  * @param {*} e
  */
 const handle_RegisterChange = (e) => {
  const { name, value } = e.target;

  /* custom validity for password */
  if (name === "userPassword") {
   if (value.trim().length === 0) {
    e.target.setCustomValidity(STRINGS.entervalidepwd);
   } else if (value.length < 8) {
    e.target.setCustomValidity(STRINGS.pwdatleastChar);
   } else if (value.length > 16) {
    e.target.setCustomValidity(STRINGS.pwdNotgreater);
   } else {
    e.target.setCustomValidity("");
   }
   if (value.length === 0) {
    e.target.setCustomValidity(STRINGS.pwdRequired);
   }
  }

  /* custom validity for confirm password */
  if (name === "userConfirmPassword") {
   if (value.trim().length === 0) {
    e.target.setCustomValidity(STRINGS.confirmpwd);
   } else if (value.length < 8) {
    e.target.setCustomValidity(STRINGS.confirmpwdatleast);
   } else if (value.length > 16) {
    e.target.setCustomValidity(STRINGS.confirmpwdnotgreater);
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity(STRINGS.confirmrequired);
   }
  }

  /* custom validity for email */
  if (name === "userEmail") {
   const emailPattern = /^[a-z0-9._%+]+@[a-z0-9.]+\.[a-z]{2,}$/i;
   if (!emailPattern.test(value)) {
    e.target.setCustomValidity(STRINGS.validmail);
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity(STRINGS.emailRequired);
   }
  }

  /* custom validity for phone */
  if (name === "userMobile") {
   const isAllZeros = /^\d+$/.test(value) && parseInt(value, 10) === 0;
   const startsWithMultipleZeros = /^0{2,}/.test(value);
   const hasRepeatingDigits =
    /(0{6,}|1{6,}|2{6,}|3{6,}|4{6,}|5{6,}|6{6,}|7{6,}|8{6,}|9{6,})/.test(value);

   if (value.length < 10) {
    e.target.setCustomValidity(STRINGS.phn10digit);
   } else if (isAllZeros) {
    e.target.setCustomValidity(STRINGS.invalidphn);
   } else if (startsWithMultipleZeros) {
    e.target.setCustomValidity(STRINGS.invalidphn);
   } else if (hasRepeatingDigits) {
    e.target.setCustomValidity(STRINGS.invalidphn);
   } else {
    e.target.setCustomValidity("");
   }
  }

  /* custom validity for name */
  if (name === "userName") {
   const namePattern = /^[a-zA-Z ]+$/;
   if (!namePattern.test(value)) {
    e.target.setCustomValidity(STRINGS.alphabeticchar);
   } else if (value.length < 3) {
    e.target.setCustomValidity(STRINGS.nameshouldnotlessthan3);
   } else if (value.length > 26) {
    e.target.setCustomValidity(STRINGS.nameshoudnotmorethan26);
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity(STRINGS.namerequired);
   }
  }

  setUserSignUpData((prevUserSignUpData) => ({
   ...prevUserSignUpData,
   [name]: value,
  }));
 };

 /**
  * @description Handle Register submit
  * @param {*} e
  */
 const handleRegister_Submit = async (e) => {
  e.preventDefault();
  setShowSpinner(true);
  if (userSignUpData.userPassword !== userSignUpData.userConfirmPassword) {
   alert_Predict_Func(STRINGS.pwdnotmatch);
   setShowSpinner(false);
  } else {
   try {
    const register_api_payload = {
     name: userSignUpData.userName.trim(),
     email: userSignUpData.userEmail,
     phone: parseInt(userSignUpData.userMobile),
     password: userSignUpData.userPassword,
     isAdmin: false,
    };
    const response = await userRegisterApi(register_api_payload);
    if (response?.data) {
     const {
      success,
      message,
      name,
      phone,
      email,
      _id,
      access_token,
      loginStatus,
      isVerified,
     } = response?.data;
     if (success) {
      setTempUser_Data({
       userMail: email,
       userPhone: phone,
       userName: name,
       userId: _id,
       userAccessToken: access_token,
       userLoginStatus: loginStatus,
       userIsVerified: isVerified,
      });
      try {
       const response = await userForgotPasswordApi({ email: email });
       let { success, message } = response?.data;
       setShowSpinner(false);
       if (success) {
        handleClose_Call(1);
        setUserSignUpData({
         userName: "",
         userMobile: "",
         userEmail: "",
         userPassword: "",
         userConfirmPassword: "",
        });
        setOtpVerifyPopup(true);
        setArrows(true);
        setCountdown(180);
        alert_Predict_Func(message);
       } else {
        alert_Predict_Func(message);
       }
      } catch (error) {
       console.log("register3", response?.data, error.message);
       alert_Predict_Func(STRINGS.somethingunexpected);
      }
     } else {
      setShowSpinner(false);
      alert_Predict_Func(message);
     }
    } else {
     if (response?.response?.status == 422) {
      alert_Predict_Func(STRINGS.validmail);
     } else {
      // handleClose_Call(1);
      alert_Predict_Func(STRINGS.somethingunexpected);
     }
     setShowSpinner(false);
    }
   } catch (error) {
    setShowSpinner(false);
    // handleClose_Call(1);
    console.error("Error occurred while storing user details in server:", error);
    alert_Predict_Func(STRINGS.somethingunexpected);
   }
  }
 };

 /**
  * @description Handle OTP changes
  * @param {*} e
  */
 const handle_OTPChange = (e) => {
  const { name, value } = e.target;
  console.log("veri", value.length != 6);
  if (value.length != 6) {
   e.target.setCustomValidity(STRINGS.verificationCode);
  } else {
   e.target.setCustomValidity("");
  }
  setUserForgotPasswordVerifyData((prevUserForgotPasswordVerifyData) => ({
   ...prevUserForgotPasswordVerifyData,
   [name]: value.toUpperCase(),
  }));
 };

 /**
  * @description Handle OTP submit
  * @param {*} e
  */
 const handleOTP_Submit = async (e) => {
  e.preventDefault();
  setShowSpinner(true);
  try {
   const mailVerify_api_payload = {
    verificationCode: userForgotPasswordVerifyData.verifyCode,
    id: tempUsers_Data.userId,
   };
   const response = await userForgotPasswordVerifyApi(mailVerify_api_payload);
   const { success, message } = response?.data;
   setShowSpinner(false);
   if (success) {
    handleClose_Call(2);
    if (tempUsers_Data?.viaForgot == 2) {
     setTempUser_Data({});
     updateLoggedIn_Func(response?.data);
    } else if (tempUsers_Data?.viaForgot) {
     setArrows(true);
     setNewpasswordPopup(true);
    } else {
     alert_Predict_Func(STRINGS.registeration_success);
     tempUsers_Data._id = tempUsers_Data.userId;
     updateLoggedIn_Func(tempUsers_Data);
     setTempUser_Data({});
    }
   } else {
    alert_Predict_Func(message);
   }
  } catch (error) {
   setShowSpinner(false);
   //  handleClose_Call(2);
   console.log("Error occurred while user mail verification in server", error);
   alert_Predict_Func(STRINGS.somethingunexpected);
  }
 };

 /**
  * @description Handle resend code
  */
 const handleResendCode = async () => {
  setShowSpinner(true);
  clearInterval(timerID);
  try {
   const resendVerifyCode_api_payload = {
    email: tempUsers_Data.userMail,
   };
   const response = await resendVerifyCodeApi(resendVerifyCode_api_payload);
   const { success, message } = response?.data;
   setShowSpinner(false);
   if (success) {
    setCountdown(180);
    alert_Predict_Func(message);
   } else {
    alert_Predict_Func(message);
   }
  } catch (error) {
   setShowSpinner(false);
   console.error("Error occurred:", error);
   alert_Predict_Func(STRINGS.somethingunexpected);
  }
 };

 /**
  * @description Handle forgot password change
  * @param {*} e
  */
 const forgotPassword_HandleChange = (e) => {
  const { name, value } = e.target;

  /* custom validity for email */
  if (name === "userEmail") {
   const emailPattern = /^[a-z0-9._%+]+@[a-z0-9.]+\.[a-z]{2,}$/i;
   if (!emailPattern.test(value)) {
    e.target.setCustomValidity(STRINGS.validmail);
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity(STRINGS.emailRequired);
   }
  }

  setUserForgotPasswordData((prevUserForgotPasswordData) => ({
   ...prevUserForgotPasswordData,
   [name]: value,
  }));
 };
 /**
  * @description Handle forgot password submit
  * @param {*} e
  */
 const handleForgot_Submit = async (e) => {
  e.preventDefault();
  setShowSpinner(true);
  try {
   const forgotPassword_api_payload = {
    email: userForgotPasswordData.userEmail,
   };
   const response = await userForgotPasswordApi(forgotPassword_api_payload);
   const { success, message, id } = response?.data;
   setShowSpinner(false);
   if (success) {
    handleClose_Call(3);
    setTempUser_Data({
     userId: id,
     viaForgot: true,
     userMail: userForgotPasswordData.userEmail,
    });
    setOtpVerifyPopup(true);
    setArrows(true);
    setCountdown(180);
    alert_Predict_Func(message);
   } else {
    alert_Predict_Func(message);
   }
  } catch (error) {
   //  handleClose_Call(3);
   setShowSpinner(false);
   console.error(
    "Error occurred while verifying user mail for forgot password in server",
    error
   );
   alert_Predict_Func("Oops! Something unexpected happened.");
  }
 };

 /**
  * Description Handle New password change
  * @param {*} e
  */
 const newPassword_HandleChange = (e) => {
  const { name, value } = e.target;

  /* custom validity for new password */
  if (name === "newPassword") {
   if (value.trim().length === 0) {
    e.target.setCustomValidity(STRINGS.entervalidepwd);
   } else if (value.length < 8) {
    e.target.setCustomValidity(STRINGS.pwdatleastChar);
   } else if (value.length > 16) {
    e.target.setCustomValidity(STRINGS.pwdNotgreater);
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity(STRINGS.pwdRequired);
   }
  }
  /* custom validity for confirm password */
  if (name === "confirmPassword") {
   if (value.trim().length === 0) {
    e.target.setCustomValidity(STRINGS.confirmpwd);
   } else if (value.length < 8) {
    e.target.setCustomValidity(STRINGS.confirmpwdatleast);
   } else if (value.length > 16) {
    e.target.setCustomValidity(STRINGS.confirmpwdnotgreater);
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity(STRINGS.confirmrequired);
   }
  }

  setUserNewPasswordData((prevUserNewPasswordData) => ({
   ...prevUserNewPasswordData,
   [name]: value,
  }));
 };

 const handleNewpassword_Submit = async (e) => {
  e.preventDefault();
  setShowSpinner(true);
  if (userNewPasswordData.newPassword !== userNewPasswordData.confirmPassword) {
   alert_Predict_Func(STRINGS.newanconfirm);
   setShowSpinner(false);
  } else {
   try {
    const forgotPassword_setNewPassword_api_payload = {
     id: tempUsers_Data.userId,
     newPassword: userNewPasswordData.newPassword,
     confirmPassword: userNewPasswordData.confirmPassword,
    };
    const response = await setNewPasswordApi(forgotPassword_setNewPassword_api_payload);
    const { success, message } = response?.data;
    setShowSpinner(false);
    if (success) {
     alert_Predict_Func("Your password has changed successfully");
     handleClose_Call(5);
     setTempUser_Data({});
     setArrows(true);
     setModalVerifyMail(true);
    } else {
     alert_Predict_Func(message);
    }
   } catch (error) {
    //  handleClose_Call(5);
    setShowSpinner(false);
    console.error("Error occurred while setting user password in server", error);
    alert_Predict_Func(STRINGS.somethingunexpected);
   }
  }
 };

 const handleVerifyMobile_Change = async (e) => {
  const { value } = e.target;
  /* custom validity for phone */
  if (value === "userMobile") {
   const isAllZeros = /^\d+$/.test(value) && parseInt(value, 10) === 0;
   const startsWithMultipleZeros = /^0{2,}/.test(value);
   const hasRepeatingDigits =
    /(0{6,}|1{6,}|2{6,}|3{6,}|4{6,}|5{6,}|6{6,}|7{6,}|8{6,}|9{6,})/.test(value);

   if (value.length < 10) {
    e.target.setCustomValidity(STRINGS.phn10digit);
   } else if (isAllZeros) {
    e.target.setCustomValidity(STRINGS.invalidphn);
   } else if (startsWithMultipleZeros) {
    e.target.setCustomValidity(STRINGS.invalidphn);
   } else if (hasRepeatingDigits) {
    e.target.setCustomValidity(STRINGS.invalidphn);
   } else {
    e.target.setCustomValidity("");
   }
  }
  setMobileVerify((prevmobileAuth) => ({
   ...prevmobileAuth,
   phone: value,
  }));
 };
 /**
  * @description Handle verify mobile auth
  * @param {*} e
  */
 const handleVerifyMobile_Auth_Submit = async (e) => {
  e.preventDefault();
  setShowSpinner(true);
  const login_api_payload = {
   phone: mobileVerify.phone,
  };
  try {
   const response = await foodPageVerifyMailApi(login_api_payload);
   let { status, message } = response?.data;
   setShowSpinner(false);
   console.error("Entering food response===>", status, response?.data);
   if (status) {
    updateMobileVerify_Func(response?.data, mobileVerify.phone);
    alert_Predict_Func(STRINGS.welcomeidentity);
    handleClose_Call(6);
   } else {
    if (message == "Booking not found") {
     alert_Predict_Func(STRINGS.nobookingrecord);
    } else {
     alert_Predict_Func(STRINGS.somethingunexpected);
    }
    //  console.log("Error occurred while verifying user login from server", response);
   }
  } catch (error) {
   setShowSpinner(false);
   console.error("Error occurred while storing user details in server:", error);
   alert_Predict_Func(STRINGS.somethingunexpected);
  }
 };
 return (
  <div className={styles.nav_cont1}>
   {/* active spinner */}
   {showSpinner && <SpinnerLoader />}
   <div className={styles.nav_cont2}>
    {/* logo */}
    <Link href="#" className={styles.nav_link}>
     <img src={Logo} width={75} height={75} alt="" />
    </Link>
    {/* cart & sign in */}
    <div className={styles.cart_signIn_cont}>
     {/* cart */}
     {userAuthentication_Details?.booking_details?.length > 0 &&
      (nearbyRestaurantTab ? (
       <div
        onClick={handleCartOthersOpen}
        className={styles.cart_cont}
        tabIndex={0}
        onKeyDown={(event) => {
         if (event.key == "Enter") {
          handleCartOthersOpen();
         }
        }}
       >
        <PiShoppingCartThin size={45} />
        <div className={styles.cart_item_count}>{totalcart_Quantity}</div>
       </div>
      ) : (
       <div
        onClick={handleFoodCartOpen}
        className={styles.cart_cont}
        tabIndex={0}
        onKeyDown={(event) => {
         if (event.key == "Enter") {
          handleFoodCartOpen();
         }
        }}
       >
        <PiShoppingCartThin size={45} />
        <div className={styles.cart_item_count}>{totalcart_Quantity}</div>
       </div>
      ))}

     {/* sign in */}
     {userAuthentication_Details?.loggedInUserId ? (
      <div
       className={styles.signIn_cont}
       onClick={() => {
        setShowSignOutAlert(true), setArrows(true);
       }}
       tabIndex={0}
       onKeyDown={(event) => {
        if (event.key == "Enter") {
         setShowSignOutAlert(true);
         setArrows(true);
        }
       }}
      >
       sign out
      </div>
     ) : (
      <div
       className={styles.signIn_cont}
       onClick={openModal_verifymail}
       tabIndex={0}
       onKeyDown={(event) => {
        if (event.key == "Enter") {
         openModal_verifymail();
        }
       }}
      >
       {STRINGS.Sign_in_text}
      </div>
     )}
    </div>
   </div>
   {modalVerifyMail && (
    <Modal
     isOpen={modalVerifyMail}
     style={modalStyles}
     //  onRequestClose={() => handleClose_Call(0)}
     contentLabel="Food Modal Lunch"
     className={styles.modal_verify_mail}
    >
     <div className={styles.close_icon_cont} onClick={() => handleClose_Call(0)}>
      <IoCloseOutline className={styles.close_icon} />
     </div>
     <form onSubmit={handleLogin_Submit} className={loginStyles.form_container}>
      <div className={loginStyles.login_details_cont}>
       <div className={loginStyles.login_title}>Sign In</div>
       <div className={loginStyles.user_input}>
        <div className={loginStyles.input_field_cont}>
         <input
          type="email"
          placeholder="Email"
          className={loginStyles.input_field2}
          name="userEmail"
          value={loginStyles.userEmail}
          onChange={handle_LoginChange}
          required
          autoComplete="off"
          maxLength="30"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          ref={InputRef}
         />
         <div className={loginStyles.input_icons}>
          <CiMail size={20} />
         </div>
        </div>
        <div className={loginStyles.input_field_cont}>
         <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          className={loginStyles.input_field2}
          name="userPassword"
          value={verifyMailData.userPassword}
          onChange={handleChange}
          // minLength="8"
          maxLength="16"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          required
          autoComplete="off"
          ref={InputRef}
         />
         <div className={loginStyles.input_icons}>
          {showPassword ? (
           <BsEye
            size={20}
            onClick={() => setShowPassword(false)}
            className={loginStyles.eye_icon}
           />
          ) : (
           <BsEyeSlash
            size={20}
            onClick={() => setShowPassword(true)}
            className={loginStyles.eye_icon}
           />
          )}
         </div>
        </div>
        <div onClick={() => handleClose_Call(4)} className={loginStyles.text_link}>
         <div className={loginStyles.forgot_password_txt}>Forgot Password?</div>
        </div>
       </div>
      </div>
      <div className={loginStyles.login_details_cont1}>
       <div className={loginStyles.below_upper_cont}>
        <button type="submit" className={loginStyles.btn_submit}>
         <div className={loginStyles.btn_cont}>
          <div className={loginStyles.btn_name}>Submit</div>
         </div>
        </button>
       </div>
       <div className={loginStyles.below_lower_cont}>
        Don't have an account?
        <div onClick={handleRegister} className={loginStyles.signup_link}>
         <span className={loginStyles.signup_txt}>Sign Up</span>
        </div>
       </div>
      </div>
     </form>
    </Modal>
   )}

   {modalVerifyMobile && (
    <Modal
     isOpen={modalVerifyMobile}
     style={modalStyles}
     //  onRequestClose={() => handleClose_Call(6)}
     contentLabel="Food Modal"
     className={authmobileVerifyStyles.modal_verify_mobile}
    >
     <div
      className={authmobileVerifyStyles.cancel_icon_view_verify}
      onClick={() => handleClose_Call(6)}
     >
      <IoCloseOutline className={authmobileVerifyStyles.closeicon_verify} />
     </div>
     <form
      onSubmit={handleVerifyMobile_Auth_Submit}
      className={authmobileVerifyStyles.form_container_verify}
     >
      <div className={authmobileVerifyStyles.mail_verify_title}>
       {STRINGS.occupant_identity}
      </div>
      <div className={authmobileVerifyStyles.input_field_cont}>
       <input
        type="tel"
        placeholder={STRINGS.bookingPhonenumber}
        className={authmobileVerifyStyles.input_field_verifymobile}
        name="Mobile"
        value={mobileVerify.phone}
        onChange={handleVerifyMobile_Change}
        required
        autoComplete="off"
        maxLength="10"
        style={{ paddingRight: "30px" }} // text overlaying the icon issue
        onInput={(e) => {
         const input = e.target.value;
         const numericInput = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
         e.target.value = numericInput;
        }}
        ref={InputRef}
       />
       <div className={authmobileVerifyStyles.input_icons_verify}>
        <CiMobile3 size={20} />
       </div>
      </div>
      {/* submit btn */}
      <div className={authmobileVerifyStyles.submit_btn_cont_verify}>
       <button type="submit" className={authmobileVerifyStyles.btn_submit}>
        <div className={styles.btn_cont}>
         <div className={styles.btn_name}>Submit</div>
        </div>
       </button>
      </div>
     </form>
    </Modal>
   )}
   {registeredPopup && (
    <Modal
     isOpen={registeredPopup}
     style={modalStyles}
     //  onRequestClose={() => handleClose_Call(1)}
     contentLabel="Food Modal Lunch"
     className={registerSyles.modal_register_view}
    >
     <div className={styles.close_icon_cont} onClick={() => handleClose_Call(1)}>
      <IoCloseOutline className={styles.close_icon} />
     </div>
     <form onSubmit={handleRegister_Submit} className={registerSyles.form_container}>
      <div className={registerSyles.register_details_cont}>
       <div className={registerSyles.signup_title}>Sign Up</div>
       <div className={registerSyles.user_input}>
        <div className={registerSyles.input_field_cont}>
         <input
          type="text"
          placeholder="Full Name"
          className={registerSyles.input_field1}
          name="userName"
          value={registerSyles.userName}
          onChange={handle_RegisterChange}
          required
          autoComplete="off"
          maxLength="26"
          minLength="3"
          pattern="[A-Za-z ]{1,}"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          ref={InputRef}
         />
         <div className={registerSyles.input_icons}>
          <CiUser size={20} />
         </div>
        </div>
        <div className={registerSyles.input_field_cont}>
         <input
          type="tel"
          placeholder="Phone"
          className={registerSyles.input_field2}
          name="userMobile"
          value={userSignUpData.userMobile}
          onChange={handle_RegisterChange}
          required
          autoComplete="off"
          maxLength="10"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          onInput={(e) => {
           const input = e.target.value;
           const numericInput = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
           e.target.value = numericInput;
          }}
          ref={InputRef}
         />
         <div className={registerSyles.input_icons}>
          <CiMobile3 size={20} />
         </div>
        </div>
        <div className={registerSyles.input_field_cont}>
         <input
          type="email"
          placeholder="Email"
          className={registerSyles.input_field3}
          name="userEmail"
          value={userSignUpData.userEmail}
          onChange={handle_RegisterChange}
          required
          autoComplete="off"
          maxLength="30"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
         />
         <div className={registerSyles.input_icons}>
          <CiMail size={20} />
         </div>
        </div>

        <div className={registerSyles.input_field_cont}>
         <input
          type={showsignupPassword ? "text" : "password"}
          placeholder="Password"
          className={registerSyles.input_field4}
          name="userPassword"
          value={userSignUpData.userPassword}
          onChange={handle_RegisterChange}
          maxLength="16"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          required
          autoComplete="off"
          ref={InputRef}
         />
         <div className={registerSyles.input_icons}>
          {showsignupPassword ? (
           <BsEye
            size={20}
            onClick={() => setShowsignupPassword(false)}
            className={registerSyles.eye_icon}
           />
          ) : (
           <BsEyeSlash
            size={20}
            onClick={() => setShowsignupPassword(true)}
            className={registerSyles.eye_icon}
           />
          )}
         </div>
        </div>
        <div className={registerSyles.input_field_cont}>
         <input
          type={showConfirmPassword ? "text" : "password"}
          placeholder="Confirm Password"
          className={registerSyles.input_field4}
          name="userConfirmPassword"
          value={userSignUpData.userConfirmPassword}
          onChange={handle_RegisterChange}
          // minLength="8"
          maxLength="16"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          required
          autoComplete="off"
          ref={InputRef}
         />
         <div className={registerSyles.input_icons}>
          {showConfirmPassword ? (
           <BsEye
            size={20}
            onClick={() => setConfirmPassword(false)}
            className={registerSyles.eye_icon}
           />
          ) : (
           <BsEyeSlash
            size={20}
            onClick={() => setConfirmPassword(true)}
            className={registerSyles.eye_icon}
           />
          )}
         </div>
        </div>
       </div>
      </div>
      <div className={registerSyles.login_details_cont1}>
       <div className={registerSyles.below_upper_cont}>
        <div className={registerSyles.submit_btn_cont}>
         <button type="submit" className={registerSyles.btn_submit}>
          <div className={registerSyles.btn_cont}>
           <div className={registerSyles.btn_name}>Submit</div>
          </div>
         </button>
        </div>
       </div>
       <div className={registerSyles.below_lower_cont}>
        Already have an account?
        <div onClick={handleLogin} className={registerSyles.login_link}>
         <span className={registerSyles.login_txt}>Sign In</span>
        </div>
       </div>
      </div>
     </form>
    </Modal>
   )}
   {forgotPasswordPopup && (
    <Modal
     isOpen={forgotPasswordPopup}
     style={modalStyles}
     //  onRequestClose={() => handleClose_Call(3)}
     contentLabel="Food Modal Lunch"
     className={forgotpasswordStyles.modal_forgot_register_view}
    >
     <div className={styles.close_icon_cont} onClick={() => handleClose_Call(3)}>
      <IoCloseOutline className={styles.close_icon} />
     </div>
     <form onSubmit={handleForgot_Submit} className={forgotpasswordStyles.form_container}>
      <div className={forgotpasswordStyles.forgotPassword1_details_cont}>
       <div className={forgotpasswordStyles.forgotPassword_title}>Forgot Password</div>
       <div className={forgotpasswordStyles.user_input}>
        <div className={forgotpasswordStyles.input_field_cont}>
         <input
          type="email"
          placeholder="Email"
          className={forgotpasswordStyles.input_field1}
          name="userEmail"
          value={userForgotPasswordData.userEmail}
          onChange={forgotPassword_HandleChange}
          required
          autoComplete="off"
          maxLength="30"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          ref={InputRef}
         />
         <div className={forgotpasswordStyles.input_icons}>
          <CiMail size={20} />
         </div>
        </div>
       </div>
      </div>
      <div className={forgotpasswordStyles.login_details_cont1}>
       <div className={forgotpasswordStyles.below_upper_cont}>
        <div className={forgotpasswordStyles.submit_btn_cont}>
         <button type="submit" className={forgotpasswordStyles.btn_submit}>
          <div className={forgotpasswordStyles.btn_cont}>
           <div className={forgotpasswordStyles.btn_name}>Submit</div>
          </div>
         </button>
        </div>
       </div>
       <div className={forgotpasswordStyles.below_lower_cont}>
        Enter your registered email id.
       </div>
      </div>
     </form>
    </Modal>
   )}
   {otpVerifyPopup && (
    <Modal
     isOpen={otpVerifyPopup}
     style={modalStyles}
     //  onRequestClose={() => handleClose_Call(2)}
     contentLabel="Food Modal Lunch"
     className={otpverifyStyles.modal_otpverify_view}
    >
     <div className={styles.close_icon_cont} onClick={() => handleClose_Call(2)}>
      <IoCloseOutline className={styles.close_icon} />
     </div>
     <form onSubmit={handleOTP_Submit} className={otpverifyStyles.form_container}>
      <div className={otpverifyStyles.forgotPassword2_details_cont}>
       <div className={otpverifyStyles.forgotPassword_title}>
        {tempUsers_Data?.viaForgot == true ? "Forgot Password" : "Email Verification"}
       </div>
       <div className={otpverifyStyles.user_input}>
        <div className={otpverifyStyles.input_field_cont}>
         <input
          type={showVerifyCode ? "text" : "password"}
          placeholder="Verification Code"
          className={otpverifyStyles.input_field1}
          name="verifyCode"
          value={userForgotPasswordVerifyData.verifyCode}
          onChange={handle_OTPChange}
          required
          maxLength="6"
          autoComplete="off"
         />
         <div className={otpverifyStyles.input_icons}>
          {showVerifyCode ? (
           <BsEye
            size={20}
            onClick={() => setVerifyCode(false)}
            className={otpverifyStyles.eye_icon}
           />
          ) : (
           <BsEyeSlash
            size={20}
            onClick={() => setVerifyCode(true)}
            className={otpverifyStyles.eye_icon}
           />
          )}
         </div>
        </div>
        <div className={otpverifyStyles.resend_code1}>
         {countdown > 0 ? (
          `Resend Code in ${Math.floor(countdown / 60)}:${(countdown % 60)
           .toString()
           .padStart(2, "0")} minutes`
         ) : (
          <div onClick={handleResendCode} className={otpverifyStyles.resend_code2}>
           Resend Code
          </div>
         )}
        </div>
       </div>
      </div>
      <div className={otpverifyStyles.login_details_cont1}>
       <div className={otpverifyStyles.below_upper_cont}>
        <div className={otpverifyStyles.submit_btn_cont}>
         <button type="submit" className={otpverifyStyles.btn_submit}>
          <div className={otpverifyStyles.btn_cont}>
           <div className={otpverifyStyles.btn_name}>Submit</div>
          </div>
         </button>
        </div>
       </div>
       <div className={otpverifyStyles.below_lower_cont}>We've sent a code to your mail.</div>
      </div>
     </form>
    </Modal>
   )}
   {newpasswordPopup && (
    <Modal
     isOpen={newpasswordPopup}
     style={modalStyles}
     //  onRequestClose={() => handleClose_Call(5)}
     contentLabel="Food Modal Lunch"
     className={newpasswordStyles.modal_newpassword_view}
    >
     <div className={styles.close_icon_cont} onClick={() => handleClose_Call(5)}>
      <IoCloseOutline className={styles.close_icon} />
     </div>
     <form onSubmit={handleNewpassword_Submit} className={newpasswordStyles.form_container}>
      <div className={newpasswordStyles.forgotPassword3_details_cont}>
       <div className={newpasswordStyles.forgotPassword_title}>Forgot Password</div>
       <div className={newpasswordStyles.user_input}>
        <div className={newpasswordStyles.input_field_cont}>
         <input
          type={showNewPassword ? "text" : "password"}
          placeholder="New Password"
          className={newpasswordStyles.input_field1}
          name="newPassword"
          value={userNewPasswordData.newPassword}
          onChange={newPassword_HandleChange}
          required
          // minLength="8"
          maxLength="16"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          autoComplete="off"
          ref={InputRef}
         />
         <div className={newpasswordStyles.input_icons}>
          {showNewPassword ? (
           <BsEye
            size={20}
            onClick={() => setNewPassword(false)}
            className={newpasswordStyles.eye_icon}
           />
          ) : (
           <BsEyeSlash
            size={20}
            onClick={() => setNewPassword(true)}
            className={newpasswordStyles.eye_icon}
           />
          )}
         </div>
        </div>
        <div className={newpasswordStyles.input_field_cont}>
         <input
          type={shownewConfirmPassword ? "text" : "password"}
          placeholder="Confirm Password"
          className={newpasswordStyles.input_field2}
          name="confirmPassword"
          value={userNewPasswordData.confirmPassword}
          onChange={newPassword_HandleChange}
          required
          // minLength="8"
          maxLength="16"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          autoComplete="off"
          ref={InputRef}
         />
         <div className={newpasswordStyles.input_icons}>
          {shownewConfirmPassword ? (
           <BsEye
            size={20}
            onClick={() => setNewConfirmPassword(false)}
            className={newpasswordStyles.eye_icon}
           />
          ) : (
           <BsEyeSlash
            size={20}
            onClick={() => setNewConfirmPassword(true)}
            className={newpasswordStyles.eye_icon}
           />
          )}
         </div>
        </div>
       </div>
      </div>
      <div className={newpasswordStyles.login_details_cont1}>
       <div className={newpasswordStyles.below_upper_cont}>
        <div className={newpasswordStyles.submit_btn_cont}>
         <button type="submit" className={newpasswordStyles.btn_submit}>
          <div className={newpasswordStyles.btn_cont}>
           <div className={newpasswordStyles.btn_name}>Submit</div>
          </div>
         </button>
        </div>
       </div>
       <div className={newpasswordStyles.below_lower_cont}>
        Enter a new password and confirm it.
       </div>
      </div>
     </form>
    </Modal>
   )}
   {showSignOutAlert && (
    <ConfirmTemplate
     confirmDialog_msg={
      cartFood?.length || cartOthers?.length ? STRINGS.signout_alert_msg : STRINGS.logout
     }
     confirmOk={() => {
      handleLogout(), setArrows(false);
     }}
     confirmCancel={() => {
      setShowSignOutAlert(false), setArrows(false);
     }}
    />
   )}
  </div>
 );
};

export default Nav;
