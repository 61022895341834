import React, { useState, useRef, useContext } from "react";
import { IMAGES } from "../helper/images";
import { Parallax } from "react-parallax";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { userForgotPasswordApi, userLoginApi } from "../helper/http_api";
import { useAlert } from "react-alert";
import { setBookFunc, getBookFunc } from "../helper/temporary_data";
import { CartContext } from "../context/cart_context";

import HeaderWithIcon from "../components/commoncomponents/header_home_icon";
import styles from "../styles/login.module.css";

function Login() {
 const { updateLoggedIn_Func } = useContext(CartContext);
 const InputRef = useRef(null);

 const [showPassword, setShowPassword] = useState(false);
 const [isButtonDisabled, setIsButtonDisabled] = useState(false);
 const [userLoginData, setUserLoginData] = useState({
  userEmail: "",
  userPassword: "",
 });

 const alert = useAlert();

 const [showSpinner, setShowSpinner] = useState(false);
 const navigate = useNavigate();

 // let loginStatus = false;

 const handleChange = (e) => {
  const { name, value } = e.target;

  /* custom validity for password */
  if (name === "userPassword") {
   if (value.trim().length === 0) {
    e.target.setCustomValidity("Please enter a valid password.");
   } else if (value.length < 8) {
    e.target.setCustomValidity("Password must be at least 8 characters long.");
   } else if (value.length > 16) {
    e.target.setCustomValidity("Password cannot be longer than 16 characters.");
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity("Password is a required field.");
   }
  }

  /* custom validity for email */
  if (name === "userEmail") {
   const emailPattern = /^[a-z0-9._%+]+@[a-z0-9.]+\.[a-z]{2,}$/i;
   if (!emailPattern.test(value)) {
    e.target.setCustomValidity("Please enter a valid email Id.");
   } else {
    e.target.setCustomValidity("");
   }

   if (value.length === 0) {
    e.target.setCustomValidity("Email is a required field.");
   }
  }

  setUserLoginData((prevUserLoginData) => ({
   ...prevUserLoginData,
   [name]: value,
  }));
 };

 const login_api_payload = {
  email: userLoginData.userEmail,
  password: userLoginData.userPassword,
  // isAdmin: false,
 };

 const handleSubmit = (e) => {
  e.preventDefault();
  setShowSpinner(true);
  // disabling button
  setIsButtonDisabled(true);

  if (login_api_payload.password.length < 8) {
   alert.error("Password mismatch");
   setShowSpinner(false);
   // enabling button
   setTimeout(() => {
    setIsButtonDisabled(false);
   }, 4000);
  } else {
   const loginPromise = new Promise(async (resolve, reject) => {
    try {
     const response = await userLoginApi(login_api_payload);
     // console.log("Login response: " + JSON.stringify(response));
     resolve(response.data);
    } catch (error) {
     reject(error);
    }
   });
   loginPromise
    .then((data) => {
     // console.log("Login Api response data: ", data);
     let { success, message, email, _id } = data;
     // setShowSpinner(false);

     setTimeout(async () => {
      setShowSpinner(false);
      if (success) {
       updateLoggedIn_Func(data);
       if (message === "User login successfully") {
        message = "User logged in successfully";
       }
       alert.error(message);
       // alert.success(message);
       if (!getBookFunc()) {
        navigate(`/`);
       } else {
        navigate("/user/booking_details");
       }
      } else if (
       message == "OTP has been not verified yet, OTP code sent your email please verify"
      ) {
       const queryParams = new URLSearchParams({
        userId: _id,
       });
       navigate(`/user/auth/mail_verify?${queryParams}`);
       sendMailVerificationCode({ email: email });
      } else {
       if (message === "Email or Password doesn't exists") {
        message = "Please check your email and password.";
       }
       alert.error(message);
      }
      // enabling button
      setTimeout(() => {
       setIsButtonDisabled(false);
      }, 4000);
     }, 1000);
    })
    .catch((error) => {
     setShowSpinner(false);
     console.error("Error occurred while verifying user login from server", error);
     alert.error("Oops! Something unexpected happened.");
     // enabling button
     setTimeout(() => {
      setIsButtonDisabled(false);
     }, 4000);
    });
  }
 };
 const sendMailVerificationCode = (payload) => {
  // e.preventDefault();
  // console.log(userForgotPasswordData);
  setShowSpinner(true);

  const forgotPasswordPromise = new Promise(async (resolve, reject) => {
   try {
    const response = await userForgotPasswordApi(payload);
    resolve(response.data);
   } catch (error) {
    reject(error);
   }
  });

  forgotPasswordPromise
   .then((data) => {
    // console.log("verification data", data);
    const { success, message } = data;
    // setShowSpinner(false);

    setTimeout(() => {
     setShowSpinner(false);
     if (success) {
      // console.log("### forgot password email match successfull! ");
      // alert(message);
      alert.success(message);
      // const queryParams = new URLSearchParams({
      //   forgotpassword_mail: forgotPassword_api_payload.email,
      //   userId: id,
      // });
      // navigate(`/user/auth/forgot_password_verify?${queryParams}`);
     } else {
      // console.log("### forgot password email match failed ! ", message);
      // alert(message);
      alert.error(message);
     }
    }, 1000);
   })
   .catch((error) => {
    setShowSpinner(false);
    console.error("Error occured while sending otp for mail verification from server", error);
    alert.error("Oops! Something unexpected happened.");
   });
 };
 const handleHomeClick = () => {
  setShowSpinner(true);
  setTimeout(() => {
   setShowSpinner(false);
   setBookFunc(false);
   navigate(`/`);
  }, 1000);
 };

 return (
  <div>
   <Parallax
    // blur={10}
    bgImage={IMAGES.bgImg_section1}
    bgImageAlt="section1"
    // strength={120}
   >
    <div className={styles.content}>
     {/* active spinner */}
     {showSpinner && (
      <div className={styles.spinner_layout}>
       <SpinnerDotted color="white" />
      </div>
     )}

     <HeaderWithIcon HomeIconClick={handleHomeClick} />

     <form onSubmit={handleSubmit} className={styles.form_container}>
      <div className={styles.login_details_cont}>
       <div className={styles.login_title}>Sign In</div>
       <div className={styles.user_input}>
        <div className={styles.input_field_cont}>
         <input
          type="email"
          placeholder="Email"
          className={styles.input_field2}
          name="userEmail"
          value={userLoginData.userEmail}
          onChange={handleChange}
          required
          autoComplete="off"
          maxLength="30"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          // pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
          ref={InputRef}
          // onInvalid={(e) =>
          //   e.target.setCustomValidity(
          //     "Please enter a valid email address"
          //   )
          // }
          // onInput={(e) => e.target.setCustomValidity("")}
         />
         <div className={styles.input_icons}>
          <CiMail size={20} />
         </div>
        </div>
        <div className={styles.input_field_cont}>
         <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          className={styles.input_field2}
          name="userPassword"
          value={userLoginData.userPassword}
          onChange={handleChange}
          // minLength="8"
          maxLength="16"
          style={{ paddingRight: "30px" }} // text overlaying the icon issue
          required
          autoComplete="off"
          ref={InputRef}
          //   pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
         />
         <div className={styles.input_icons}>
          {showPassword ? (
           <BsEye
            size={20}
            onClick={() => setShowPassword(false)}
            className={styles.eye_icon}
           />
          ) : (
           <BsEyeSlash
            size={20}
            onClick={() => setShowPassword(true)}
            className={styles.eye_icon}
           />
          )}
         </div>
        </div>
        <Link to="/user/auth/forgot_password" className={styles.text_link}>
         <div className={styles.forgot_password_txt}>Forgot Password?</div>
        </Link>
       </div>
      </div>
      {/* abc */}
      <div className={styles.login_details_cont1}>
       <div className={styles.below_upper_cont}>
        <div className={styles.submit_btn_cont}>
         <button type="submit" className={styles.btn_submit} disabled={isButtonDisabled}>
          <div className={styles.btn_cont}>
           <div className={styles.btn_name}>Submit</div>
          </div>
         </button>
        </div>
       </div>
       <div className={styles.below_lower_cont}>
        Don't have an account?
        <Link to="/user/auth/register" className={styles.signup_link}>
         <span className={styles.signup_txt}>Sign Up</span>
        </Link>
       </div>
      </div>
     </form>
    </div>
   </Parallax>
  </div>
 );
}

export default Login;
