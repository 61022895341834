import React from "react";
import { VscSignOut } from "react-icons/vsc";
import styles from "../../styles/confirm_popup.module.css";

function ConfirmTemplate(props) {
  return (
    <div className={styles.confirm_dialog}>
      <div>
        <VscSignOut size={30} color="#ccc" />
      </div>
      <p>{props.confirmDialog_msg}</p>
      <div>
        <button className={styles.cancelButton} onClick={props.confirmCancel}>
          NO
        </button>
        <button className={styles.confirmButton} onClick={props.confirmOk}>
          YES
        </button>
      </div>
    </div>
  );
}

export default ConfirmTemplate;
