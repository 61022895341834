import React, { Component } from "react";

class Arrow_svg extends Component {
 render() {
  return (
   <svg
    width="20"
    height="20"
    viewBox="0 0 25 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
   >
    <path
     d="M9.88013 1.34473L7.9043 3.36197L18.348 13.4482L7.9043 23.5344L9.88013 25.5516L22.5819 13.4482L9.88013 1.34473Z"
     fill="white"
    />
   </svg>
  );
 }
}

export default Arrow_svg;
