import React from "react";

const Delete_icon = () => {
 return (
  <svg
   width="25"
   height="25"
   viewBox="0 0 25 25"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d="M10.3254 3.80005H14.6754C14.6754 3.2232 14.4462 2.66998 14.0383 2.26209C13.6305 1.8542 13.0772 1.62505 12.5004 1.62505C11.9235 1.62505 11.3703 1.8542 10.9624 2.26209C10.5545 2.66998 10.3254 3.2232 10.3254 3.80005ZM8.87539 3.80005C8.87539 2.83864 9.25731 1.91661 9.93713 1.23679C10.6169 0.556967 11.539 0.175049 12.5004 0.175049C13.4618 0.175049 14.3838 0.556967 15.0637 1.23679C15.7435 1.91661 16.1254 2.83864 16.1254 3.80005H23.3754C23.5677 3.80005 23.7521 3.87643 23.888 4.0124C24.024 4.14836 24.1004 4.33277 24.1004 4.52505C24.1004 4.71733 24.024 4.90174 23.888 5.0377C23.7521 5.17366 23.5677 5.25005 23.3754 5.25005H21.8471L20.1158 20.2488C19.9934 21.3091 19.4855 22.2873 18.6888 22.9974C17.8921 23.7075 16.8621 24.1 15.7948 24.1H9.20599C8.13872 24.1 7.10873 23.7075 6.31199 22.9974C5.51525 22.2873 5.00737 21.3091 4.88499 20.2488L3.15369 5.25005H1.62539C1.43311 5.25005 1.2487 5.17366 1.11274 5.0377C0.976774 4.90174 0.900391 4.71733 0.900391 4.52505C0.900391 4.33277 0.976774 4.14836 1.11274 4.0124C1.2487 3.87643 1.43311 3.80005 1.62539 3.80005H8.87539ZM11.0504 9.60005C11.0504 9.40777 10.974 9.22336 10.838 9.0874C10.7021 8.95143 10.5177 8.87505 10.3254 8.87505C10.1331 8.87505 9.9487 8.95143 9.81274 9.0874C9.67677 9.22336 9.60039 9.40777 9.60039 9.60005V18.3C9.60039 18.4923 9.67677 18.6767 9.81274 18.8127C9.9487 18.9487 10.1331 19.025 10.3254 19.025C10.5177 19.025 10.7021 18.9487 10.838 18.8127C10.974 18.6767 11.0504 18.4923 11.0504 18.3V9.60005ZM14.6754 8.87505C14.4831 8.87505 14.2987 8.95143 14.1627 9.0874C14.0268 9.22336 13.9504 9.40777 13.9504 9.60005V18.3C13.9504 18.4923 14.0268 18.6767 14.1627 18.8127C14.2987 18.9487 14.4831 19.025 14.6754 19.025C14.8677 19.025 15.0521 18.9487 15.188 18.8127C15.324 18.6767 15.4004 18.4923 15.4004 18.3V9.60005C15.4004 9.40777 15.324 9.22336 15.188 9.0874C15.0521 8.95143 14.8677 8.87505 14.6754 8.87505Z"
    fill="#937031"
   />
  </svg>
 );
};

const Minus_icon = () => {
 return (
  <svg width="11" height="1" viewBox="0 0 11 1" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M0 0.5H11" stroke="black" />
  </svg>
 );
};
const Plus_icon = () => {
 return (
  <svg
   width="11"
   height="11"
   viewBox="0 0 11 11"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path d="M5.5 0V11M0 5.5H11" stroke="black" />
  </svg>
 );
};

const Empty_cart = ({ className }) => {
 return (
  <svg
   width="49"
   height="49"
   className={className}
   viewBox="0 0 49 49"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d="M44.9269 28.3557L48.9484 10.6613C49.2387 9.38368 48.2676 8.1671 46.9575 8.1671H13.5437L12.764 4.35514C12.5697 3.405 11.7336 2.72266 10.7638 2.72266H2.04167C0.914071 2.72266 0 3.63673 0 4.76432V6.12543C0 7.25303 0.914071 8.1671 2.04167 8.1671H7.98658L13.9625 37.3829C12.5329 38.2051 11.5694 39.7467 11.5694 41.5143C11.5694 44.1453 13.7023 46.2782 16.3333 46.2782C18.9644 46.2782 21.0972 44.1453 21.0972 41.5143C21.0972 40.1809 20.5488 38.9763 19.666 38.1115H37.5006C36.6179 38.9763 36.0694 40.1809 36.0694 41.5143C36.0694 44.1453 38.2023 46.2782 40.8333 46.2782C43.4644 46.2782 45.5972 44.1453 45.5972 41.5143C45.5972 39.6282 44.5008 37.9982 42.9108 37.2264L43.3801 35.1613C43.6705 33.8837 42.6994 32.6671 41.3893 32.6671H18.5551L17.9983 29.9449H42.936C43.8893 29.9449 44.7156 29.2852 44.9269 28.3557Z"
    fill="#937031"
   />
  </svg>
 );
};

export { Delete_icon, Minus_icon, Plus_icon, Empty_cart };
