/* added alert template */
import React from "react";
import ReactDOM from "react-dom/client";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "../src/components/commoncomponents/alert_popup";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import CartProvider from "./context/cart_context";

const alertOptions = {
 position: positions.TOP_CENTER,
 timeout: 4000,
 offset: "300px",
 transition: transitions.FADE,
 containerStyle: {
  zIndex: 100,
 },
};

const Root = () => (
 <CartProvider>
  <AlertProvider template={AlertTemplate} {...alertOptions}>
   <App />
  </AlertProvider>
 </CartProvider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
 <>
  <Root />
 </>
);
