import styles from "../../styles/foodstyles/date_change_alert_msg.module.css";
import { STRINGS } from "../../model/constant";

const CutoffPopupView = ({ meal_type, onClickOk }) => {
return (
    <div className={styles.date_change_alert_main_cont}>
        <div className={styles.title_view}>{STRINGS.alert_title}</div>
        <div className={styles.overall_view_date_change_popup}>
            {`Your ${meal_type.breakfast == 0 ? "breakfast": ''}${(meal_type.breakfast == 0 && meal_type.lunch == 0) ? ',': ''} 
            ${meal_type.lunch == 0 ? "lunch" : ''}${(meal_type.breakfast == 0 && meal_type.lunch == 1 && meal_type.dinner == 0) ? ',': ''}${(meal_type.lunch == 0 && meal_type.dinner == 0) ? ',': ''} 
            ${meal_type.dinner == 0 ? "dinner" : ''} ordering time is past it's cut-off timing. Please choose a different day or clicking "OK" will remove the items that are no longer available.`}
        </div>
        <div className={styles.cancel_overall_view}>
            <div onClick={onClickOk} className={styles.ok_button_view}>
            {STRINGS.date_change_alert_btn_txt}
            </div>
        </div>
    </div>
);
};

export default CutoffPopupView;
