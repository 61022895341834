import React, { useEffect, useState } from "react";
import { SpinnerDotted } from "spinners-react";
import logo from "../assets/img/logo/logo_sengal.png";
import logo2 from "../assets/img/beverages_images/ProudlyServingStarbucks.jpg";
import logo3 from "../assets/img/beverages_images/coffee.webp";
import logo4 from "../assets/img/beverages_images/Hibiscus-Iced.jpg";
import logo5 from "../assets/img/beverages_images/lemon-mojito.jpeg";
// import { FaMobileAlt } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import "../styles/beverages_menu.css";
import { getBeveragesDetailsApi } from "../helper/http_api";

function BeveragesMenu() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [getColdBeverages, setGetColdBeverages] = useState([]);
  const [getHotBeverages, setGetHotBeverages] = useState([]);
  const [getContactDetails, setGetContactDetails] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 912) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }

      // Check for landscape orientation with a max-width of 767 pixels
      const isLandscapeOrientation = window.matchMedia(
        "(max-height: 575.98px) and (orientation: landscape)"
      ).matches;
      setIsLandscape(isLandscapeOrientation);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setShowSpinner(true);
    getBeveragesDetailsApi()
      .then((responseData) => {
        setShowSpinner(false);
        const fetchBeveragesDetails = responseData;
        // console.log("## Beverages Page Data ===> ", responseData);
        const sortedHotBeverages =
          fetchBeveragesDetails.data?.Hot_Beverages.sort(
            (a, b) => b.drink_Price - a.drink_Price
          );
        const sortedColdBeverages =
          fetchBeveragesDetails.data?.Cold_Beverages.sort(
            (a, b) => b.drink_Price - a.drink_Price
          );
        setGetColdBeverages(sortedColdBeverages);
        setGetHotBeverages(sortedHotBeverages);
        setGetContactDetails(fetchBeveragesDetails.data?.Contact_Us);
        // console.log(fetchBeveragesDetails.data);
      })
      .catch((error) => {
        setShowSpinner(false);
        console.log(
          "Error occured while loading beverages menu data from server",
          error
        );
      });
  }, []);

  return (
    <div id="beverages_main">
      {/* active spinner */}
      {showSpinner && (
        <div className="spinner_layout">
          <SpinnerDotted color="white" />
        </div>
      )}
      <div className="container-fluid" style={{ height: "auto" }}>
        {/* Logo and Contact */}

        {isSmallScreen ? (
          <div
            className="row header_info"
            id="header_logo"
            style={{ width: "120%" }}
          >
            {/* <div
              className="d-flex justify-content-center"
              id="header_content bg-primary"
            > */}

            <div
              className="col-md-4 contact_us_section"
              style={{
                // backgroundColor: "pink",
                position: "relative",
                right: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h4 className="heading_beverages">Contact Us</h4>
                {/* <h6>{getContactDetails?.contact_Name}</h6> */}
                <h6>Service Help Desk</h6>
                {/* <h6>Intercom: {getContactDetails?.Intercom}</h6> */}
                <h6>Intercom: 111</h6>
                <h6>
                  {/* <FaMobileAlt /> {getContactDetails?.mobile_Number1} */}
                  <MdCall size={20} /> +91 93840 31008
                </h6>
                <h6>
                  {/* <FaMobileAlt /> {getContactDetails?.mobile_Number2} */}
                  <MdCall size={20} /> +91 93848 61008
                </h6>
              </div>
              <div className="">
                <img
                  src={logo}
                  className="img-fluid logo_img"
                  alt="Company Logo"
                  style={{ height: "100px", width: "100px" }}
                />
              </div>
            </div>
            {/* </div> */}
          </div>
        ) : (
          <div
            className="row header_info"
            id="header_logo"
            style={{ width: "120%" }}
          >
            <div
              className="d-flex justify-content-center"
              id="header_content bg-primary"
            >
              <div className="col-md-4">
                <img
                  src={logo}
                  className="img-fluid logo_img"
                  alt="Company Logo"
                />
              </div>
              <div className="col-md-4 contact_us_section ">
                <h4 className="heading_beverages">Contact Us</h4>
                {/* <h6>{getContactDetails?.contact_Name}</h6> */}
                <h6>Service Help Desk</h6>
                {/* <h6>Intercom: {getContactDetails?.Intercom}</h6> */}
                <h6>Intercom: 111</h6>
                <h6>
                  {/* <FaMobileAlt /> {getContactDetails?.mobile_Number1} */}
                  <MdCall size={20} /> +91 93840 31008
                </h6>
                <h6>
                  {/* <FaMobileAlt /> {getContactDetails?.mobile_Number2} */}
                  <MdCall size={20} /> +91 93848 61008
                </h6>
              </div>
            </div>
          </div>
        )}

        {/* Hot Beverages */}
        <div
          className="row justify-content-center beverages_section_hot"
          style={{ width: "130%" }}
        >
          <div className="col-md-4">
            <h1 className="heading_beverages">Hot Beverages</h1>
            {isSmallScreen ? (
              <div
                className="col-md-4 mt-4 mb-3"
                id="beverages_images"
                style={isSmallScreen ? { margin: "0 0 0 35px" } : {}}
              >
                <img
                  src={logo2}
                  className="img-fluid logo_img2"
                  alt="Beverages"
                />
                <br></br>
                <div
                  className="mt-4 mb-3"
                  id="beverages_images_two"
                  style={isSmallScreen ? { margin: "0 35px 0 35px" } : {}}
                >
                  <img
                    src={logo3}
                    className="img-fluid logo_img2"
                    alt="Beverages"
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {getHotBeverages?.map((data, index) => (
              <div
                class="row justify-content-start mt-3"
                key={index}
                style={{ width: "200%" }}
              >
                <div class="col-4">
                  <h6>{data.drink_Name}</h6>
                </div>
                <div class="col-4">
                  <h6>₹{data.drink_Price}</h6>
                </div>
              </div>
            ))}
          </div>
          {isSmallScreen ? (
            <></>
          ) : (
            <div
              className="col-md-4 mt-5"
              id="beverages_images"
              // style={isSmallScreen ? { margin: "0 0 0 35px" } : {}}
            >
              <img
                src={logo2}
                className="img-fluid logo_img2"
                alt="Beverages"
              />
              <br></br>
              <div
                className="mt-5"
                id="beverages_images_two"
                // style={isSmallScreen ? { margin: "0 35px 0 35px" } : {}}
              >
                <img
                  src={logo3}
                  className="img-fluid logo_img2"
                  alt="Beverages"
                />
              </div>
            </div>
          )}
        </div>
        {/* Cold Beverages */}
        <div
          className="row justify-content-center beverages_section_cold"
          style={{ width: "120%" }}
        >
          <h1
            className="heading_beverages"
            id="cold_drinks"
            style={{ marginLeft: "40.8vw" }}
          >
            Cold Beverages
          </h1>
          {isSmallScreen ? (
            <div
              className="mt-4 mb-2"
              id="beverages_images"
              style={isSmallScreen ? { margin: "0 0 0 35px" } : {}}
            >
              <img
                src={logo4}
                className="img-fluid logo_img3"
                alt="Beverages"
              />
              <br></br>
              <div
                className="mt-4 mb-2"
                id="beverages_images_three"
                style={isSmallScreen ? { margin: "0 35px 0 35px" } : {}}
              >
                <img
                  src={logo5}
                  className="img-fluid logo_img3"
                  alt="Beverages"
                />
              </div>
            </div>
          ) : (
            <div
              className="col-md-4 mt-5 mb-2"
              id="beverages_images"
              style={isSmallScreen ? { margin: "0 0 0 35px" } : {}}
            >
              <img
                src={logo4}
                className="img-fluid logo_img3"
                alt="Beverages"
              />
              <br></br>
              <div
                className="mt-5 mb-2"
                id="beverages_images_three"
                style={isSmallScreen ? { margin: "0 35px 0 35px" } : {}}
              >
                <img
                  src={logo5}
                  className="img-fluid logo_img3"
                  alt="Beverages"
                />
              </div>
            </div>
          )}
          <div className="col-md-4">
            {getColdBeverages?.map((data, index) => (
              <div
                class="row justify-content-start mt-3"
                key={index}
                style={{ width: "220%" }}
              >
                <h6 class="col-4">{data.drink_Name}</h6>
                <h6 class="col-4">₹{data.drink_Price}</h6>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BeveragesMenu;
