import React, { useContext, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { CartContext } from "../../../context/cart_context";
import styles from "../../../styles/foodstyles/cartstyles/CartTop.module.css";
import sengalLogo from "../../../assets/img/logo/logo_sengal.png";
import { updateCartAllFood } from "../../../helper/localstorage_update";

const CartTop = () => {
 const {
  setIsOpenRestaurantCart,
  setIsCartOpen,
  currentSelectedDate,
  setFoodCartRoomNo,
  foodCartRoomNo,
  userAuthentication_Details,
 } = useContext(CartContext);
 const verified_user_roomIds = userAuthentication_Details.roomIds;
 const [selectedRoom, setSelectedRoom] = useState(foodCartRoomNo);
 // Convert the roomIds string to an array
 const roomIdArray = verified_user_roomIds;

 // Function to handle room selection
 const handleRoomSelection = (event) => {
  const selectedRoomNo = event.target.value;
  setSelectedRoom(selectedRoomNo); // Update selected room state
  setFoodCartRoomNo(selectedRoomNo); // Update context with selected room
  updateCartAllFood(
   { key: 4, value: selectedRoomNo },
   userAuthentication_Details.loggedIn_user_email
  );
 };

 // console.log("verified_user_roomIds cartTop page ===> ", roomIdArray);

 return (
  <div
   style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
   }}
  >
   <div className={styles.cart_top_main_cont}>
    <div className={styles.cart_left_cont}>
     <div style={{ width: "100%", marginBottom: "5px", marginTop: "5px" }}>
      <img alt="company_logo" height={60} width={60} src={sengalLogo} />
     </div>
     <div className={styles.cart_title_text}>Cart</div>
     <div className={styles.delivery_room_no_cont}>
      <div className={styles.delivery_room_no_txt}>Delivery Room Number</div>
      <select
       className={styles.delivery_room_no_dropdown_cont}
       onChange={handleRoomSelection}
       value={foodCartRoomNo}
      >
       {roomIdArray.map((roomId) => (
        <option key={roomId} value={roomId}>
         {roomId}
        </option>
       ))}
      </select>
     </div>
     <div className={styles.delivery_date}>Delivery Date : {currentSelectedDate}</div>
    </div>
    <div
     onClick={() => {
      setIsOpenRestaurantCart(false), setIsCartOpen(false);
     }}
     className={styles.cart_close_icon_cont}
    >
     <IoCloseOutline className={styles.cart_close_icon} />
    </div>
   </div>
  </div>
 );
};

export default CartTop;
