import React, { useContext, useState } from "react";
import styles from "../../../styles/foodstyles/cartstyles/restaurant_cart_page.module.css";
import { STRINGS } from "../../../model/constant";
import { CartContext } from "../../../context/cart_context";
import { Delete_icon } from "../../../assets/svg/cart_svg";
import Arrow_svg from "../../../assets/svg/arrow_svg";
import {
 SBH_Restaurant_Cart_Update,
 storeRestaurantOrderDataApi,
} from "../../../helper/http_api";

const Other_restaurantCart_component = ({
 alert_Predict_Func,
 ShowSpinner,
 setShowPlaceOrderMsg,
 setOrderPlacedSuccess,
 setRestaurant_show,
}) => {
 const {
  cartOthers,
  removeItemOthers,
  setCartOthers,
  userAuthentication_Details,
  currentSelectedDate,
  cartFood,
 } = useContext(CartContext);
 const [error, setError] = useState(Array(cartOthers.length).fill(false));

 const handleChange = async (e, value, index) => {
  const inputValue = e.target.value;
  if (inputValue.trim() === "") {
   // Set error for the specific index
   setError((prevError) => {
    const updatedError = [...prevError];
    updatedError[index] = true;
    return updatedError;
   });
  } else {
   // Clear error for the specific index
   setError((prevError) => {
    const updatedError = [...prevError];
    updatedError[index] = false;
    return updatedError;
   });
  }
  const neww = value;
  const newvalue = { ...neww, food_desc: inputValue };
  cartOthers[index] = newvalue;
  setCartOthers([...cartOthers]);
  await SBH_Restaurant_Cart_Update(foodCart_Structure_Formation(cartOthers));

  //    updateCartAllFood(
  //     { key: 2, value: cartOthers },
  //     userAuthentication_Details.loggedIn_user_email
  //    );
 };

 const foodCart_Structure_Formation = (cartData) => {
  let cartPayload = {
   user_email: userAuthentication_Details.loggedIn_user_email,
   res_details: cartData,
   meal_date: currentSelectedDate,
  };
  return cartPayload;
 };

 /* API controller for storing restaurant order details in database */
 const storeRestaurantOrderData = async (resDetails) => {
  try {
   ShowSpinner(true);
   const response = await storeRestaurantOrderDataApi({
    user_name: userAuthentication_Details.loggedIn_user_name,
    user_email: userAuthentication_Details.loggedIn_user_email,
    res_details: resDetails,
    room_no: userAuthentication_Details.roomIds[0],
    user_phn_no: parseInt(userAuthentication_Details.loggedIn_user_phone),
    order_date: currentSelectedDate,
    order_status: 1,
   });
   console.error(response.data, "storeRestaurantOrderData=============>");
   ShowSpinner(false);
   const { status } = response?.data;
   if (status === true) {
    //     updateCartAllFood({ key: 8 }, userAuthentication_Details.loggedIn_user_email);
    SBH_Restaurant_Cart_Update(foodCart_Structure_Formation([]))
     .then(() => {
      setShowPlaceOrderMsg(true);
      setOrderPlacedSuccess(true);
      setRestaurant_show(cartFood?.length ? 2 : 0);
      setCartOthers([]);
     })
     .catch((err) => {
      console.log("Error in cart updation");
     });
   } else if (status === false) {
    setShowPlaceOrderMsg(true);
    setOrderPlacedSuccess(false);
   }
  } catch (error) {
   ShowSpinner(false);
   alert_Predict_Func(STRINGS.somethingunexpected);
   console.error("Error placing restaurant order:", error);
  }
 };

 const handlePlaceOrderClickRestaurant = async () => {
  const isEmptyDescription = cartOthers.some((item) => !item?.food_desc?.trim());
  if (isEmptyDescription) {
   alert_Predict_Func("Please enter food description for added cart items.");
   return;
  }
  let overall_Cart_Data = cartOthers.map((ele) => {
   return { resId: ele.restId, food_desc: ele.food_desc };
  });
  await storeRestaurantOrderData(overall_Cart_Data);
 };

 return (
  <div className={styles.cart_maincontainer}>
   <div className={styles.left_cart_container}>
    <div className={styles.inner_left_container}>
     <div className={styles.cart_container_header}>{STRINGS.yourorders}</div>
     {cartOthers.map((item, index) => {
      return (
       <div key={index}>
        <div className={styles.item_name_container}>
         <div className={styles.item_name}>{item.restName}</div>
         <div
          className={styles.delete_container}
          onClick={() => removeItemOthers(item.restId, item.restName)}
         >
          <Delete_icon />
          <div className={styles.remove_txt}>{STRINGS.remove}</div>
         </div>
        </div>
        <div className={styles.splc_instruc_title}>{STRINGS.foodorder_description}</div>
        <textarea
         onChange={(e) => {
          handleChange(e, item, index);
         }}
         value={cartOthers[index]?.food_desc ? cartOthers[index]?.food_desc : ""}
         className={styles.spcl_instruct}
         rows="5"
         cols="50"
         maxLength="250"
         required
        />
        {error && error[index] && (
         <div className={styles.max_limit_error_note}>Please enter food description.</div>
        )}
        <div className={styles.divider_line} />
       </div>
      );
     })}
    </div>
   </div>
   <div className={styles.rightCart_full_container}>
    <div className={styles.rightcart_container}>
     <div className={styles.inner_right_container}>{STRINGS.restaurant_place}</div>
    </div>
    <div
     onClick={handlePlaceOrderClickRestaurant}
     tabIndex={0}
     onKeyDown={(event) => {
      if (event.key == "Enter") {
       handlePlaceOrderClickRestaurant();
      }
     }}
     className={styles.cart_place_order}
    >
     {STRINGS.order_place_btn_txt}
     <Arrow_svg />
    </div>
   </div>
  </div>
 );
};

export { Other_restaurantCart_component };
