/* home bg images */
import bgImg_section1 from "../assets/img/bg_images/bgimg_sec1_drk.jpg";
import bgImg_section2 from "../assets/img/bg_images/bgImg_section2.png";
import bgImg_section3 from "../assets/img/bg_images/bgImg_section3dim3.jpg";

/* sengal logo */
import logo from "../assets/img/logo/logo_sengal.png";

/* food page place order confirm or fail img */
import order_confirm from "../assets/img/food_page_images/order_success.png";
import order_fail from "../assets/img/food_page_images/order_fail.png";

/* complementary food tag img */
import complementary_food_tag_img from "../assets/img/food_page_images/complementary_img.png";

export const IMAGES = {
  bgImg_section1,
  bgImg_section2,
  bgImg_section3,
  logo,
  order_confirm,
  order_fail,
  complementary_food_tag_img,
};

/* Logo, Section1_video Section2_images and Section3_images are coming from server */
