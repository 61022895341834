import React, { useContext, useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { CartContext } from "../../../context/cart_context";
import styles from "../../../styles/foodstyles/cartstyles/CartTop.module.css";
import sengalLogo from "../../../assets/img/logo/logo_sengal.png";
import { updateCartAllFood } from "../../../helper/localstorage_update";
import { getCutOffTimeApi } from "../../../helper/http_api";
import { userTimezone } from "../../../model/constant";

const CartTop = () => {
 const {
  setIsOpenFoodCart,
  setFoodCartRoomNo,
  setIsCartOpen,
  userAuthentication_Details,
  currentSelectedDate,
  foodCartRoomNo,
 } = useContext(CartContext);
 console.error("roodmids data", userAuthentication_Details);
 const roomIdArray = userAuthentication_Details.roomIds;
 const [selectedRoom, setSelectedRoom] = useState(userAuthentication_Details.roomIds[0]); // State to store selected room
 const [Breakfastmin, setBreakfastmin] = useState("-");
 const [Lunchmin, setLunchmin] = useState("-");
 const [Dinnermin, setDinnermin] = useState("-");

 const cutofftimeValuefunction = async () => {
  try {
   const cutofftimeresponsedata = await getCutOffTimeApi();
   if (cutofftimeresponsedata?.data?.status) {
    let options = {
     hour: "numeric",
     minute: "numeric",
     hour12: true,
     timeZone: userTimezone,
    };
    let breakfast = new Date(cutofftimeresponsedata.data.data[0].Breakfast);
    let lunch = new Date(cutofftimeresponsedata.data.data[0].Lunch);
    let dinner = new Date(cutofftimeresponsedata.data.data[0].Dinner);
    //     console.log(cutofftimeresponsedata, "cutofftimeresponsedata=========>");
    let breakfastmin = breakfast.toLocaleTimeString("en-US", options);
    let lunchmin = lunch.toLocaleTimeString("en-US", options);
    let dinnermin = dinner.toLocaleTimeString("en-US", options);
    setBreakfastmin(breakfastmin);
    setLunchmin(lunchmin);
    setDinnermin(dinnermin);
   } else {
    setBreakfastmin("-");
    setLunchmin("-");
    setDinnermin("-");
   }
  } catch (err) {
   //    console.log("getting error", err);
  }
 };

 useEffect(() => {
  cutofftimeValuefunction();
 }, []);

 //  console.log("verified_user_roomIds cartTop page ===> ", roomIdArray);

 // Function to handle room selection
 const handleRoomSelection = (event) => {
  const selectedRoomNo = event.target.value;
  setSelectedRoom(selectedRoomNo); // Update selected room state
  //   console.log("change===>", selectedRoomNo);
  setFoodCartRoomNo(selectedRoomNo); // Update context with selected room
  updateCartAllFood({ key: 4, value: selectedRoomNo });
 };

 const closeCartFunc = () => {
  setIsOpenFoodCart(false);
  setIsCartOpen(false);
  updateCartAllFood({ key: 9, value: 0 }, userAuthentication_Details.loggedIn_user_email);
 };

 return (
  <div
   style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
   }}
  >
   <div className={styles.cart_top_main_cont}>
    <div className={styles.cart_left_cont}>
     <div style={{ width: "100%", marginTop: "5px" }}>
      <img alt="company_logo" height={60} width={60} src={sengalLogo} />
     </div>
     <div className={styles.cart_title_text}>Cart</div>
     <div className={styles.cart_Info_view}>
      <div className={styles.cart_Info_left_view}>
       <div className={styles.delivery_room_no_cont}>
        <div className={styles.delivery_room_no_txt}>Delivery Room Number</div>
        <select
         className={styles.delivery_room_no_dropdown_cont}
         style={{ outlineColor: "rgba(51, 24, 18, 1)" }}
         onChange={handleRoomSelection} // Call handleRoomSelection on change
         value={foodCartRoomNo} // Set value to selectedRoom state
        >
         {roomIdArray.map((roomId) => (
          <option key={roomId} value={roomId}>
           {roomId}
          </option>
         ))}
        </select>
       </div>
       <div className={styles.delivery_date}>Delivery Date : {currentSelectedDate}</div>
      </div>
      <div className={styles.cart_Info_right_view}>
       <div className={styles.cart_Info_cutoffView}>
        *Breakfast order should be placed before {Breakfastmin}
       </div>
       <div className={styles.cart_Info_cutoffView}>
        *Lunch order should be placed before {Lunchmin}
       </div>
       <div className={styles.cart_Info_cutoffView}>
        *Dinner order should be placed before {Dinnermin}
       </div>
      </div>
     </div>
    </div>
    <div onClick={closeCartFunc} className={styles.cart_close_icon_cont}>
     <IoCloseOutline className={styles.cart_close_icon} />
    </div>
   </div>
  </div>
 );
};

export default CartTop;
