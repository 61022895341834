import React, { useState } from "react";
import "../styles/customer_feedback_form.css";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { SpinnerDotted } from "spinners-react";
import { customerFeedbackFormApi } from "../helper/http_api";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  HiEmojiSad,
  HiEmojiHappy,
  HiOutlineEmojiHappy,
  HiOutlineEmojiSad,
} from "react-icons/hi";

import Logo from "../assets/img/logo/logo_sengal.png";

export default function CustomerFeedbackForm() {
  const [inputType_ci, setInputType_ci] = useState("text");
  const [inputType_co, setInputType_co] = useState("text");

  const handleFocus_ci = () => {
    setInputType_ci("date");
  };

  const handleBlur_ci = () => {
    setInputType_ci("text");
  };
  const handleFocus_co = () => {
    setInputType_co("date");
  };

  const handleBlur_co = () => {
    setInputType_co("text");
  };

  const [showSpinner, setShowSpinner] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const sadEmoji = "😢"; //  poor
  const neutralEmoji = "🙂"; //  fair
  const happyEmoji = "😊"; // good
  const joyfulEmoji = "😄"; // excellent

  const emojiStyle = {
    fontSize: "1.4em",
    marginTop: "-6px",
    marginBottom: "0",
    color: "#fbf6ee",
    position: "relative",
    right: "10px",
    // backgroundColor: "yellow",
  };

  const inputColorChanges = {
    backgroundImage:
      "linear-gradient(315deg, #130f40a2 0%rgba (0, 0, 0, 0.804) 00 74%)",
    backgroundColor: "#090818a1",
    color: "#fff",
    border: "2px solid #212529",
    resize: "none",
  };

  const alert = useAlert();
  const navigate = useNavigate();

  // const [formData, setFormData] = useState({
  //   RoomNo: "", // Room_Number
  //   checkInDate: "", // checkInDate
  //   checkOutDate: "", // checkOutDate
  //   email: "", // email_id
  //   phoneNumber: "", // Contact_Number
  //   SBH_architecture: "", // Sengal_Ambience
  //   RoomAmenities: "", // Room_Amenities
  //   RoomCleanliness: "", // Room_Cleanliness
  //   HotelStaffHospitality: "", // Staff_Hospitality
  //   CheckInOutProcess: "", // Checkin_Checkout_Process
  //   BreakfastExperience: "", // BreakFast_Experience
  //   BeveragesServed: "", // Beverages_Served
  //   OverallExperience: "", // Overall_Experience
  //   AdditionalComments: "", // Additional_Suggestion
  //   FeedbackConsent: "", // Consent_Feedback
  // });
  // const [showTextarea1, setShowTextarea1] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(0);
  const [showCommentBox1, setShowCommentBox1] = useState(0);
  const [showCommentBox2, setShowCommentBox2] = useState(0);
  const [showCommentBox3, setShowCommentBox3] = useState(0);
  const [showCommentBox4, setShowCommentBox4] = useState(0);
  const [showCommentBox5, setShowCommentBox5] = useState(0);
  const [showCommentBox6, setShowCommentBox6] = useState(0);
  const [showCommentBox7, setShowCommentBox7] = useState(0);
  const [roomAmenities, setRoomAmenities] = useState("");
  const [consentFeedback, setConsentFeedback] = useState("");
  const [sengal_Amb_des, setSengal_Amb_des] = useState("");
  const [room_Cleanliness, setRoom_Cleanliness] = useState("");
  const [Desc_Other_Amenities, setDesc_Other_Amenities] = useState("");
  const [staff_Hospitality, setStaff_Hospitality] = useState("");
  const [checkin_Checkout_Process, setCheckin_Checkout_Process] = useState("");
  const [breakFast_Experience, setBreakFast_Experience] = useState("");
  const [beverages_Served, setBeverages_Served] = useState("");
  const [overall_Experience, setOverall_Experience] = useState("");
  const [additional_Suggestion, setAdditional_Suggestion] = useState("");

  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
  const currentDate = new Date().toISOString().split("T")[0];

  const validationSchema = Yup.object({
    // RoomNo: Yup.string().required("Please enter your room number"),
    CustomerName: Yup.string()
      .matches(
        /^[A-Za-z\s]+$/,
        "Name should only contain alphabetic characters and spaces"
      )
      .required("Please enter your name"),
    checkInDate: Yup.date()
      .required("Check-in Date is required")
      .max(new Date(), "Check-in Date should be today or in the past"),
    // .min(new Date(), "Check-in Date must be today or later"),
    // checkOutDate: Yup.date().required("Check-out Date is required"),
    checkOutDate: Yup.date()
      .required("Check-out Date is required")
      .min(
        Yup.ref("checkInDate"),
        "Check-out Date must be equal or after Check-in Date"
      ),
    // .min(Yup.ref("checkInDate"), "Check-out Date must be after Check-in Date"),
    email: Yup.string()
      .matches(emailRegex, "Please enter a valid email id")
      .required("Please enter your email id"),
    // phoneNumber: Yup.string().required("Please enter your phone number"),
    // SBH_architecture: Yup.string().required("Please select a rating"),
    // RoomAmenities: Yup.string().required("Please select a rating"),
    // RoomCleanliness: Yup.string().required("Please select a rating"),
    // HotelStaffHospitality: Yup.string().required("Please select a rating"),
    // CheckInOutProcess: Yup.string().required("Please select a rating"),
    // BreakfastExperience: Yup.string().required("Please select a rating"),
    // BeveragesServed: Yup.string().required("Please select a rating"),
    OverallExperience: Yup.string().required("Please select a rating"),
    AdditionalComments: "", // Additional_Suggestion
    // FeedbackConsent: Yup.string().required("Please select a Yes or No"),
  });

  const selectType = (data) => {
    // console.log("data ==== ", data);
    let final_data;
    switch (data) {
      case 1:
        final_data = "Poor";
        break;
      case 2:
        final_data = "Fair";
        break;
      case 3:
        final_data = "Good";
        break;
      case 4:
        final_data = "Excellent";
        break;
      default:
        final_data = "Good";
        break;
    }
    return final_data;
  };

  const onSubmit = (values, { resetForm }) => {
    // Handle form submission
    const config = {
      // Room_Number: values?.RoomNo,
      CustomerName: values?.CustomerName,
      checkInDate: values?.checkInDate,
      checkOutDate: values?.checkOutDate,
      Email_Id: values?.email,
      Contact_Number: values?.phoneNumber,
      Sengal_Ambience: {
        Rating: selectType(showCommentBox),
        Description: sengal_Amb_des,
      },
      // Room_Amenities: roomAmenities,
      Room_Amenities: {
        Rating: selectType(showCommentBox7),
        Description: roomAmenities,
      },
      Desc_Other_Amenities: Desc_Other_Amenities,
      Room_Cleanliness: {
        Rating: selectType(showCommentBox1),
        Description: room_Cleanliness,
      },
      Staff_Hospitality: {
        Rating: selectType(showCommentBox2),
        Description: staff_Hospitality,
      },
      Checkin_Checkout_Process: {
        Rating: selectType(showCommentBox3),
        Description: checkin_Checkout_Process,
      },
      BreakFast_Experience: {
        Rating: selectType(showCommentBox4),
        Description: breakFast_Experience,
      },
      Beverages_Served: {
        Rating: selectType(showCommentBox5),
        Description: beverages_Served,
      },
      Overall_Experience: {
        Rating: selectType(showCommentBox6),
        Description: overall_Experience,
      },
      Additional_Suggestion: additional_Suggestion,
      Consent_Feedback: consentFeedback,
      //   Representative_ToContact: 1,
    };
    // console.log("Form submitted with values:", config);
    ApiCallSubmitForm(config);
    // resetForm();
  };

  //   const formik = useFormik({
  //     initialValues,
  //     validationSchema,
  //     onSubmit,
  //   });
  //   const handleRadioChange1 = (e) => {
  //     console.log("handleRadioChange1 called");
  // Check if the selected value is 'Poor' or 'Fair'
  // console.log("Selected Radio Button value", e);
  // const isPoorOrFair = e.target.value === "Poor" || e.target.value === "Fair";
  // console.log("isPoorOrFair???", isPoorOrFair);
  // Update the state to show/hide the textarea accordingly
  // setShowTextarea1(isPoorOrFair);
  //   };

  //   const handleSubmit = (e) => {
  //     console.log("handleSubmit called", e);
  //   };

  //   const customerFeedbackForm_api_payload = {};

  const ApiCallSubmitForm = (payload) => {
    // console.log("Payload ====>", payload);
    // e.preventDefault();
    setShowSpinner(true);
    // disabling button
    setIsButtonDisabled(true);

    const customerFeedbackFormPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await customerFeedbackFormApi(payload);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });

    customerFeedbackFormPromise
      .then((data) => {
        const { success, message } = data;
        // setShowSpinner(false);

        setTimeout(() => {
          if (success) {
            // console.log("Feedback Form Data ==>", data.Data);
            setShowSpinner(false);
            alert.success("Thank You for Your Valuable Feedback");
            // navigate(`/customer_feed_back_form`);
            // navigate(`/`);
            setIsFormSubmitted(true);
          } else {
            alert.error("Form Submission Failed.");
          }
          // enabling button
          setTimeout(() => {
            setIsButtonDisabled(false);
          }, 4000);
        }, 1000);
      })
      .catch((error) => {
        setShowSpinner(false);
        console.error(
          "Error occurred while storing customer feedback data in server",
          error
        );
        // enabling button
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 4000);
      });
    // }
  };

  if (isFormSubmitted) {
    return (
      <div id="customer_feedback_main">
        {/* active spinner */}
        {showSpinner && (
          <div className="spinner_layout">
            <SpinnerDotted color="white" />
          </div>
        )}
        <div className="d-flex justify-content-center align-items-center thank_you">
          <div className="container-md text-center border-2 ">
            {/* <div class="card-header">
      Featured
    </div> */}
            <div className="card-body">
              <h5 className="card-title thank_you_title">
                Thank You for Your Valuable Feedback
              </h5>
              <p className="card-text mt-4 thank_you_text">
                Thank you for taking the time to share your valuable feedback
                with us. Your insights are incredibly important to us, and we
                appreciate your thoughtful comments. We are committed to
                continuously improving based on input from valued customers like
                you.
              </p>
              <p className="card-text mt-4 thank_you_text">
                Your feedback plays a crucial role in helping us enhance Sengal
                Boutique Hotel and ensure a better experience for all our
                customers.
              </p>
              <p className="card-text mt-4 thank_you_text">
                Once again, thank you for your time and valuable input.
              </p>

              <Link
                to="/"
                // className={styles.register_btn_link}
              >
                <button className="btn btn-success mt-4">Go to home</button>
              </Link>
            </div>
            {/* <div class="card-footer text-body-secondary">
      2 days ago
    </div> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="form_feedback">
      {/* active spinner */}
      {showSpinner && (
        <div className="spinner_layout">
          <SpinnerDotted color="white" />
        </div>
      )}
      <div className="container">
        {/* <div
          className="spinner-grow"
          style={{ width: "100", height: "100" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div> */}

        {/* Content here */}
        <div className="top_cont mb-3">
          <h3 className="form_title">
            Customer Satisfaction Survey
            {/* <small className="text-body-secondary">With faded secondary text</small> */}
          </h3>
          <img
            // src={IMAGES.SengalLogo}
            // crossOrigin="anonymous"
            src={Logo}
            alt="Sengal Logo"
            className="logo"
          />
        </div>
        <p className="lead form_desc">
          Thank you for choosing Sengal Boutique Hotel (SBH) for your stay. We
          hope you had a pleasant stay with us. We value your feedback and would
          greatly appreciate you taking a few moments to share your thoughts
          with us.
        </p>

        <Formik
          initialValues={{
            // RoomNo: "", // Room_Number
            CustomerName: "", // CustomerName
            checkInDate: "", // checkInDate
            checkOutDate: "", // checkOutDate
            email: "", // email_id
            phoneNumber: "", // Contact_Number
            SBH_architecture: "", // Sengal_Ambience
            RoomAmenities: "", // Room_Amenities
            RoomCleanliness: "", // Room_Cleanliness
            HotelStaffHospitality: "", // Staff_Hospitality
            CheckInOutProcess: "", // Checkin_Checkout_Process
            BreakfastExperience: "", // BreakFast_Experience
            BeveragesServed: "", // Beverages_Served
            OverallExperience: "", // Overall_Experience
            AdditionalComments: "", // Additional_Suggestion
            FeedbackConsent: "", // Consent_Feedback
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              {/* <div className="container-fluid mt-4 bg-success d-flex justify-content-start flex-column"> */}
              {/* Room No, Check-in Date, Check-out Date */}
              {/* <div className="container mt-3 bg-body-secondary"> */}
              <div className="row">
                {/* Name */}
                <div className="col-md-4 mb-3">
                  <label htmlFor="CustomerName" className="form-label">
                    <strong className="Input_titles">Customer Name:</strong>
                  </label>
                  <Field
                    name="CustomerName"
                    placeholder="Enter your full name"
                    type="text"
                    className="form-control custom-shadow input_name"
                    style={inputColorChanges}
                    id="CustomerName"
                    maxLength="26"
                    pattern="[A-Za-z ]{1,}"
                    onChange={handleChange("CustomerName")}
                  />
                  <ErrorMessage
                    className="text-danger"
                    component="div"
                    name="CustomerName"
                  />
                </div>
                {/* Check-in date */}
                <div className="col-md-4 mb-3">
                  <label htmlFor="checkInDate" className="form-label">
                    <strong className="Input_titles">Check-in Date:</strong>
                  </label>
                  <Field
                    // type="date"
                    className="form-control custom-shadow input_ci_date"
                    style={inputColorChanges}
                    id="checkInDate"
                    onChange={handleChange}
                    name="checkInDate"
                    placeholder="Enter check in date"
                    max={currentDate}
                    type={inputType_ci}
                    onFocus={handleFocus_ci}
                    onBlur={handleBlur_ci}
                  />

                  <ErrorMessage
                    className="text-danger"
                    component="div"
                    name="checkInDate"
                  />
                </div>
                {/* Check-out date */}
                <div className="col-md-4 mb-3">
                  <label htmlFor="checkOutDate" className="form-label">
                    <strong className="Input_titles">Check-out Date:</strong>
                  </label>
                  <Field
                    // type="date"
                    // type="date"
                    className="form-control custom-shadow input_co_date"
                    style={inputColorChanges}
                    id="checkOutDate"
                    onChange={handleChange}
                    name="checkOutDate"
                    placeholder="Enter check out date"
                    type={inputType_co}
                    onFocus={handleFocus_co}
                    onBlur={handleBlur_co}
                  />
                  <ErrorMessage
                    className="text-danger"
                    component="div"
                    name="checkOutDate"
                  />
                </div>
              </div>
              {/* </div> */}
              {/* Email and Phone */}
              {/* <div className="container mt-3"> */}
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label htmlFor="EmailID" className="form-label">
                    <strong className="Input_titles">Email ID:</strong>
                  </label>
                  <Field
                    type="email"
                    className="form-control custom-shadow input_mail"
                    placeholder="Enter your valid mail id"
                    style={inputColorChanges}
                    id="email"
                    maxLength="30"
                    onChange={handleChange}
                    name="email"
                  />
                  <ErrorMessage
                    className="text-danger"
                    component="div"
                    name="email"
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="PhoneNumber" className="form-label">
                    <strong className="Input_titles">Phone Number:</strong>
                  </label>
                  <Field
                    type="tel"
                    className="form-control custom-shadow input_phone"
                    placeholder="Enter your 10 digits phone number"
                    style={inputColorChanges}
                    id="PhoneNumber"
                    onChange={handleChange}
                    name="phoneNumber"
                    autoComplete="off"
                    maxLength="10"
                    onInput={(e) => {
                      const input = e.target.value;
                      const numericInput = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                      e.target.value = numericInput;
                    }}
                  />
                  <ErrorMessage
                    className="text-danger"
                    component="div"
                    name="phoneNumber"
                  />
                </div>
              </div>
              {/* </div> */}
              {/* 1*/}
              <div className="mb-3">
                <p>
                  <strong className="Input_titles">
                    SBH Architecture and Ambience
                  </strong>
                </p>

                <div className="d-flex flex-row gap-4">
                  <div className="form-check">
                    <Field
                      className="form-check-input poor_radio_btn"
                      type="radio"
                      name="SBH_architecture"
                      id="SBH_architecture_poor"
                      onChange={handleChange}
                      onClick={() => setShowCommentBox(1)}
                      value="1"
                      //   onChange={handleRadioChange1}
                      // defaultChecked
                    />

                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="SBH_architecture_poor"
                    >
                      Poor
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input custom-shadow fair_radio_btn"
                      type="radio"
                      name="SBH_architecture"
                      id="SBH_architecture_fair"
                      onChange={handleChange}
                      onClick={() => setShowCommentBox(2)}
                      value="2"
                      //   onChange={handleRadioChange1}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="SBH_architecture_fair"
                    >
                      Fair
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input custom-shadow good_radio_btn"
                      type="radio"
                      name="SBH_architecture"
                      id="SBH_architecture_good"
                      onClick={() => setShowCommentBox(3)}
                      onChange={handleChange}
                      value="3"
                      //   onChange={handleRadioChange1}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="SBH_architecture_good"
                    >
                      Good
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input custom-shadow excellent_radio_btn"
                      type="radio"
                      name="SBH_architecture"
                      id="SBH_architecture_excellent"
                      onClick={() => setShowCommentBox(4)}
                      value="4"
                      onChange={handleChange}

                      //   onChange={handleRadioChange1}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="SBH_architecture_excellent"
                    >
                      Excellent
                    </label>
                  </div>
                  <div className="emoji_SBH_architecture">
                    {showCommentBox == 1 && (
                      <HiOutlineEmojiSad style={emojiStyle} />
                    )}
                    {/* {showCommentBox == 2 && (
                      <p style={emojiStyle}>{neutralEmoji}</p>
                    )}
                    {showCommentBox == 3 && (
                      <p style={emojiStyle}>{happyEmoji}</p>
                    )} */}
                    {showCommentBox == 4 && (
                      <HiOutlineEmojiHappy style={emojiStyle} />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  className="text-danger"
                  component="div"
                  name="SBH_architecture"
                />
                {(showCommentBox == 1 || showCommentBox == 2) && (
                  <div className="form-group mt-3">
                    <p className="text_box_title">
                      Please provide your valuable suggestions to improve
                    </p>
                    <textarea
                      className="form-control custom-shadow input_textbox"
                      placeholder="Please leave a comment here"
                      id="SBH_architecture_txtArea"
                      defaultValue={""}
                      style={inputColorChanges}
                      rows="4"
                      cols="30"
                      onChange={(e) => setSengal_Amb_des(e.target.value)}
                      //   onClick={()=> setSengal_Amb_des()}
                    />
                    {/* <label htmlFor="SBH_architecture_txtArea">
                        Leave a comment here
                      </label> */}
                  </div>
                )}
              </div>
              {/* 2 */}
              <div className="mb-3">
                <p>
                  <strong className="Input_titles">
                    Room Amenities (Eg: Work desk, Private Wi-Fi, Electrical
                    outlets, Fridge, Kettle, Intercom)
                  </strong>
                </p>

                <div className="d-flex flex-row gap-4">
                  <div className="form-check">
                    <Field
                      className="form-check-input custom-shadow poor_radio_btn"
                      type="radio"
                      name="RoomAmenities"
                      id="RoomAmenities_poor"
                      onChange={handleChange}
                      onClick={() => setShowCommentBox7(1)}
                      value="1"
                      //   onChange={handleRadioChange1}
                      // defaultChecked
                    />

                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="RoomAmenities_poor"
                    >
                      Poor
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input custom-shadow fair_radio_btn"
                      type="radio"
                      name="RoomAmenities"
                      id="RoomAmenities_fair"
                      onChange={handleChange}
                      onClick={() => setShowCommentBox7(2)}
                      value="2"
                      //   onChange={handleRadioChange1}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="RoomAmenities_fair"
                    >
                      Fair
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input custom-shadow good_radio_btn"
                      type="radio"
                      name="RoomAmenities"
                      id="RoomAmenities_good"
                      onClick={() => setShowCommentBox7(3)}
                      onChange={handleChange}
                      value="3"
                      //   onChange={handleRadioChange1}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="RoomAmenities_good"
                    >
                      Good
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input custom-shadow excellent_radio_btn"
                      type="radio"
                      name="RoomAmenities"
                      id="RoomAmenities_excellent"
                      onClick={() => setShowCommentBox7(4)}
                      value="4"
                      onChange={handleChange}

                      //   onChange={handleRadioChange1}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="RoomAmenities_excellent"
                    >
                      Excellent
                    </label>
                  </div>
                  <div className="emoji_RoomAmenities">
                    {showCommentBox7 == 1 && (
                      <HiOutlineEmojiSad style={emojiStyle} />
                    )}
                    {/* {showCommentBox7 == 2 && (
                      <p style={emojiStyle}>{neutralEmoji}</p>
                    )}
                    {showCommentBox7 == 3 && (
                      <p style={emojiStyle}>{happyEmoji}</p>
                    )} */}
                    {showCommentBox7 == 4 && (
                      <HiOutlineEmojiHappy style={emojiStyle} />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  className="text-danger"
                  component="div"
                  name="RoomAmenities"
                />
                {(showCommentBox7 == 1 || showCommentBox7 == 2) && (
                  <div className="form-group mt-3">
                    <p className="text_box_title">
                      Please provide your valuable suggestions to improve
                    </p>
                    <textarea
                      className="form-control custom-shadow input_textbox"
                      placeholder="Please leave a comment here"
                      id="RoomAmenities_txtArea"
                      defaultValue={""}
                      style={inputColorChanges}
                      rows="4"
                      cols="30"
                      onChange={(e) => setDesc_Other_Amenities(e.target.value)}
                      //   onClick={()=> setSengal_Amb_des()}
                    />
                    {/* <label htmlFor="RoomAmenities_txtArea">
                        Leave a comment here
                      </label> */}
                  </div>
                )}
              </div>
              {/* <div className="mb-3">
                  <p>
                    <strong>
                      Room Amenities Experience (Eg: Work desk, Wi-Fi, Fridge,
                      Kettle, Toiletries)
                    </strong>
                  </p>

                  <div className="d-flex flex-row gap-4">
                    <div className="form-check">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="RoomAmenities"
                        id="RoomAmenities_yes"
                        onClick={() => setRoomAmenities("Yes")}
                        value="Yes"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="RoomAmenities_yes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="RoomAmenities"
                        id="RoomAmenities_no"
                        onClick={() => setRoomAmenities("No")}
                        value="No"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="RoomAmenities_no"
                      >
                        No
                      </label>
                    </div>
                  </div>
                  <ErrorMessage
                    className="text-danger"
                    component="div"
                    name="RoomAmenities"
                  />
                  {true && (
                    <div className="mt-3">
                      <p>
                        Would you recommend to add any other amenities to the
                        room?
                      </p>

                      <div className="form-floating mt-2">
                        <textarea
                          className="form-control"
                          placeholder="Leave a comment here"
                          id="RoomAmenities_txtArea"
                          defaultValue={""}
                          style={{ resize: "none" }}
                          rows="4"
                          cols="30"
                          onChange={(e) =>
                            setDesc_Other_Amenities(e.target.value)
                          }
                        />
                        <label htmlFor="RoomAmenities_txtArea">
                          Leave a comment here
                        </label>
                      </div>
                    </div>
                  )}
                </div> */}
              {/* 3 */}
              <div className="mb-3">
                <p>
                  <strong className="Input_titles">Room Cleanliness</strong>
                </p>

                <div className="d-flex flex-row gap-4">
                  <div className="form-check">
                    <Field
                      className="form-check-input custom-shadow poor_radio_btn"
                      type="radio"
                      name="RoomCleanliness"
                      id="RoomClean_poor"
                      value="1"
                      onClick={() => setShowCommentBox1(1)}
                      // defaultChecked
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="RoomClean_poor"
                    >
                      Poor
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input custom-shadow fair_radio_btn"
                      type="radio"
                      name="RoomCleanliness"
                      id="RoomClean_fair"
                      value="2"
                      onClick={() => setShowCommentBox1(2)}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="RoomClean_fair"
                    >
                      Fair
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input custom-shadow good_radio_btn"
                      type="radio"
                      name="RoomCleanliness"
                      id="RoomClean_good"
                      onClick={() => setShowCommentBox1(3)}
                      value="3"
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="RoomClean_good"
                    >
                      Good
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input custom-shadow excellent_radio_btn"
                      type="radio"
                      name="RoomCleanliness"
                      id="RoomClean_excellent"
                      value="4"
                      onClick={() => setShowCommentBox1(4)}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="RoomClean_excellent"
                    >
                      Excellent
                    </label>
                  </div>
                  <div className="emoji_RoomCleanliness">
                    {showCommentBox1 == 1 && (
                      <HiOutlineEmojiSad style={emojiStyle} />
                    )}
                    {/* {showCommentBox1 == 2 && (
                      <p style={emojiStyle}>{neutralEmoji}</p>
                    )}
                    {showCommentBox1 == 3 && (
                      <p style={emojiStyle}>{happyEmoji}</p>
                    )} */}
                    {showCommentBox1 == 4 && (
                      <HiOutlineEmojiHappy style={emojiStyle} />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  className="text-danger"
                  component="div"
                  name="RoomCleanliness"
                />
                {(showCommentBox1 == 1 || showCommentBox1 == 2) && (
                  <div className="form-group mt-3">
                    <p className="text_box_title">
                      Please provide your valuable suggestions to improve
                    </p>
                    <textarea
                      className="form-control custom-shadow input_textbox"
                      placeholder="Please leave a comment here"
                      id="RoomClean_txtArea"
                      defaultValue={""}
                      style={inputColorChanges}
                      onChange={(e) => setRoom_Cleanliness(e.target.value)}
                      rows="4"
                      cols="30"
                    />
                    {/* <label htmlFor="RoomClean_txtArea">
                        Leave a comment here
                      </label> */}
                  </div>
                )}
              </div>
              {/* 4 */}
              <div className="mb-3">
                <p>
                  <strong className="Input_titles">Hospitality</strong>
                </p>

                <div className="d-flex flex-row gap-4">
                  <div className="form-check">
                    <Field
                      className="form-check-input custom-shadow poor_radio_btn"
                      type="radio"
                      name="HotelStaffHospitality"
                      id="HotelStaff_poor"
                      value="1"
                      onClick={() => setShowCommentBox2(1)}
                      // defaultChecked
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="HotelStaff_poor"
                    >
                      Poor
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input fair_radio_btn"
                      type="radio"
                      name="HotelStaffHospitality"
                      id="HotelStaff_fair"
                      value="2"
                      onClick={() => setShowCommentBox2(2)}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="HotelStaff_fair"
                    >
                      Fair
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input good_radio_btn"
                      type="radio"
                      name="HotelStaffHospitality"
                      id="HotelStaff_good"
                      value="3"
                      onClick={() => setShowCommentBox2(3)}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="HotelStaff_good"
                    >
                      Good
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input excellent_radio_btn"
                      type="radio"
                      name="HotelStaffHospitality"
                      id="HotelStaff_excellent"
                      value="4"
                      onClick={() => setShowCommentBox2(4)}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="HotelStaff_excellent"
                    >
                      Excellent
                    </label>
                  </div>
                  <div className="emoji_HotelStaffHospitality">
                    {showCommentBox2 == 1 && (
                      <HiOutlineEmojiSad style={emojiStyle} />
                    )}
                    {/* {showCommentBox2 == 2 && (
                      <p style={emojiStyle}>{neutralEmoji}</p>
                    )}
                    {showCommentBox2 == 3 && (
                      <p style={emojiStyle}>{happyEmoji}</p>
                    )} */}
                    {showCommentBox2 == 4 && (
                      <HiOutlineEmojiHappy style={emojiStyle} />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  className="text-danger"
                  component="div"
                  name="HotelStaffHospitality"
                />
                {(showCommentBox2 == 1 || showCommentBox2 == 2) && (
                  <div className="form-group mt-3">
                    <p className="text_box_title">
                      Please provide your valuable suggestions to improve
                    </p>
                    <textarea
                      className="form-control custom-shadow input_textbox"
                      placeholder="Please leave a comment here"
                      id="HotelStaff_txtArea"
                      defaultValue={""}
                      style={inputColorChanges}
                      rows="4"
                      cols="30"
                      onChange={(e) => setStaff_Hospitality(e.target.value)}
                    />
                    {/* <label htmlFor="HotelStaff_txtArea">
                        Leave a comment here
                      </label> */}
                  </div>
                )}
              </div>
              {/* 5 */}
              <div className="mb-3">
                <p>
                  <strong className="Input_titles">
                    Check-in/Check-out Process
                  </strong>
                </p>

                <div className="d-flex flex-row gap-4">
                  <div className="form-check">
                    <Field
                      className="form-check-input poor_radio_btn"
                      type="radio"
                      name="CheckInOutProcess"
                      id="C_In_Out_process_poor"
                      value="1"
                      onClick={() => setShowCommentBox3(1)}
                      // defaultChecked
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="C_In_Out_process_poor"
                    >
                      Poor
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input fair_radio_btn"
                      type="radio"
                      name="CheckInOutProcess"
                      id="C_In_Out_process_fair"
                      value="2"
                      onClick={() => setShowCommentBox3(2)}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="C_In_Out_process_fair"
                    >
                      Fair
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input good_radio_btn"
                      type="radio"
                      name="CheckInOutProcess"
                      id="C_In_Out_process_good"
                      value="3"
                      onClick={() => setShowCommentBox3(3)}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="C_In_Out_process_good"
                    >
                      Good
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input excellent_radio_btn"
                      type="radio"
                      name="CheckInOutProcess"
                      id="C_In_Out_process_excellent"
                      value="4"
                      onClick={() => setShowCommentBox3(4)}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="C_In_Out_process_excellent"
                    >
                      Excellent
                    </label>
                  </div>
                  <div className="emoji_CheckInOutProcess">
                    {showCommentBox3 == 1 && (
                      <HiOutlineEmojiSad style={emojiStyle} />
                    )}
                    {/* {showCommentBox3 == 2 && (
                      <p style={emojiStyle}>{neutralEmoji}</p>
                    )}
                    {showCommentBox3 == 3 && (
                      <p style={emojiStyle}>{happyEmoji}</p>
                    )} */}
                    {showCommentBox3 == 4 && (
                      <HiOutlineEmojiHappy style={emojiStyle} />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  className="text-danger"
                  component="div"
                  name="CheckInOutProcess"
                />
                {(showCommentBox3 == 1 || showCommentBox3 == 2) && (
                  <div className="form-group mt-3">
                    <p className="text_box_title">
                      Please provide your valuable suggestions to improve
                    </p>
                    <textarea
                      className="form-control custom-shadow input_textbox"
                      placeholder="Please leave a comment here"
                      id="C_In_Out_process_txtArea"
                      defaultValue={""}
                      style={inputColorChanges}
                      rows="4"
                      cols="30"
                      onChange={(e) =>
                        setCheckin_Checkout_Process(e.target.value)
                      }
                    />
                    {/* <label htmlFor="C_In_Out_process_txtArea">
                        Leave a comment here
                      </label> */}
                  </div>
                )}
              </div>
              {/* 6 */}
              <div className="mb-3">
                <p>
                  <strong className="Input_titles">Breakfast</strong>
                </p>

                <div className="d-flex flex-row gap-4">
                  <div className="form-check">
                    <Field
                      className="form-check-input poor_radio_btn"
                      type="radio"
                      name="BreakfastExperience"
                      id="Breakfast_poor"
                      value="1"
                      onClick={() => setShowCommentBox4(1)}
                      // defaultChecked
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="Breakfast_poor"
                    >
                      Poor
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input fair_radio_btn"
                      type="radio"
                      name="BreakfastExperience"
                      id="Breakfast_fair"
                      value="2"
                      onClick={() => setShowCommentBox4(2)}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="Breakfast_fair"
                    >
                      Fair
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input good_radio_btn"
                      type="radio"
                      name="BreakfastExperience"
                      value="3"
                      id="Breakfast_good"
                      onClick={() => setShowCommentBox4(3)}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="Breakfast_good"
                    >
                      Good
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input excellent_radio_btn"
                      type="radio"
                      name="BreakfastExperience"
                      id="Breakfast_excellent"
                      value="4"
                      onClick={() => setShowCommentBox4(4)}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="Breakfast_excellent"
                    >
                      Excellent
                    </label>
                  </div>
                  <div className="emoji_BreakfastExperience">
                    {showCommentBox4 == 1 && (
                      <HiOutlineEmojiSad style={emojiStyle} />
                    )}
                    {/* {showCommentBox4 == 2 && (
                      <p style={emojiStyle}>{neutralEmoji}</p>
                    )}
                    {showCommentBox4 == 3 && (
                      <p style={emojiStyle}>{happyEmoji}</p>
                    )} */}
                    {showCommentBox4 == 4 && (
                      <HiOutlineEmojiHappy style={emojiStyle} />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  className="text-danger"
                  component="div"
                  name="BreakfastExperience"
                />
                {(showCommentBox4 == 1 || showCommentBox4 == 2) && (
                  <div className="mt-3">
                    <p className="text_box_title">
                      Please provide your valuable suggestions to improve
                    </p>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control custom-shadow input_textbox"
                        placeholder="Please leave a comment here"
                        id="Breakfast_excellent"
                        defaultValue={""}
                        style={inputColorChanges}
                        rows="4"
                        cols="30"
                        onChange={(e) =>
                          setBreakFast_Experience(e.target.value)
                        }
                      />
                      {/* <label htmlFor="Breakfast_excellent">
                          Leave a comment here
                        </label> */}
                    </div>
                  </div>
                )}
              </div>
              {/* 7 */}
              <div className="mb-3">
                <p>
                  <strong className="Input_titles">Beverages</strong>
                </p>

                <div className="d-flex flex-row gap-4">
                  <div className="form-check">
                    <Field
                      className="form-check-input poor_radio_btn"
                      type="radio"
                      name="BeveragesServed"
                      id="Beverages_poor"
                      value="1"
                      onClick={() => setShowCommentBox5(1)}
                      // defaultChecked
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="Beverages_poor"
                    >
                      Poor
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input fair_radio_btn"
                      type="radio"
                      name="BeveragesServed"
                      id="Beverages_fair"
                      value="2"
                      onClick={() => setShowCommentBox5(2)}
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="Beverages_fair"
                    >
                      Fair
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input good_radio_btn"
                      type="radio"
                      name="BeveragesServed"
                      id="Beverages_good"
                      onClick={() => setShowCommentBox5(3)}
                      value="3"
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="Beverages_good"
                    >
                      Good
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input excellent_radio_btn"
                      type="radio"
                      name="BeveragesServed"
                      id="Beverages_excellent"
                      onClick={() => setShowCommentBox5(4)}
                      value="4"
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="Beverages_excellent"
                    >
                      Excellent
                    </label>
                  </div>
                  <div className="emoji_BeveragesServed">
                    {showCommentBox5 == 1 && (
                      <HiOutlineEmojiSad style={emojiStyle} />
                    )}
                    {/* {showCommentBox5 == 2 && (
                      <p style={emojiStyle}>{neutralEmoji}</p>
                    )}
                    {showCommentBox5 == 3 && (
                      <p style={emojiStyle}>{happyEmoji}</p>
                    )} */}
                    {showCommentBox5 == 4 && (
                      <HiOutlineEmojiHappy style={emojiStyle} />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  className="text-danger"
                  component="div"
                  name="BeveragesServed"
                />
                {(showCommentBox5 == 1 || showCommentBox5 == 2) && (
                  <div className="mt-3">
                    <p className="text_box_title">
                      Please provide your valuable suggestions to improve
                    </p>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control custom-shadow input_textbox"
                        placeholder="Please leave a comment here"
                        id="Beverages_txtArea"
                        defaultValue={""}
                        style={inputColorChanges}
                        rows="4"
                        cols="30"
                        onChange={(e) => setBeverages_Served(e.target.value)}
                      />
                      {/* <label htmlFor="Beverages_txtArea">
                          Leave a comment here
                        </label> */}
                    </div>
                  </div>
                )}
              </div>
              {/* 8 */}
              <div className="mb-3">
                <p>
                  <strong className="Input_titles">
                    Please rate the overall experience
                  </strong>
                </p>

                <div className="d-flex flex-row gap-4">
                  <div className="form-check">
                    <Field
                      className="form-check-input poor_radio_btn"
                      type="radio"
                      name="OverallExperience"
                      id="overallExperience_poor"
                      onClick={() => setShowCommentBox6(1)}
                      value="1"
                      // defaultChecked
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="overallExperience_poor"
                    >
                      Poor
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input fair_radio_btn"
                      type="radio"
                      name="OverallExperience"
                      id="overallExperience_fair"
                      onClick={() => setShowCommentBox6(2)}
                      value="2"
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="overallExperience_fair"
                    >
                      Fair
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input good_radio_btn"
                      type="radio"
                      name="OverallExperience"
                      id="overallExperience_good"
                      onClick={() => setShowCommentBox6(3)}
                      value="3"
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="overallExperience_good"
                    >
                      Good
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input excellent_radio_btn"
                      type="radio"
                      name="OverallExperience"
                      id="overallExperience_excellent"
                      onClick={() => setShowCommentBox6(4)}
                      value="4"
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="overallExperience_excellent"
                    >
                      Excellent
                    </label>
                  </div>
                  <div className="emoji_OverallExperience">
                    {showCommentBox6 == 1 && (
                      <HiOutlineEmojiSad style={emojiStyle} />
                    )}
                    {/* {showCommentBox6 == 2 && (
                      <p style={emojiStyle}>{neutralEmoji}</p>
                    )}
                    {showCommentBox6 == 3 && (
                      <p style={emojiStyle}>{happyEmoji}</p>
                    )} */}
                    {showCommentBox6 == 4 && (
                      <HiOutlineEmojiHappy style={emojiStyle} />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  className="text-danger"
                  component="div"
                  name="OverallExperience"
                />
                {(showCommentBox6 == 1 || showCommentBox6 == 2) && (
                  <div className="mt-3">
                    <p className="text_box_title">
                      Please provide your valuable suggestions to improve
                    </p>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control custom-shadow input_textbox"
                        placeholder="Please leave a comment here"
                        id="overallExperience_txtArea"
                        defaultValue={""}
                        style={inputColorChanges}
                        rows="4"
                        cols="30"
                        onChange={(e) => setOverall_Experience(e.target.value)}
                      />
                      {/* <label htmlFor="overallExperience_txtArea">
                        Leave a comment here
                      </label> */}
                    </div>
                  </div>
                )}
              </div>
              {/* 9 */}
              <div className="mb-3 mt-5">
                {true && (
                  <div className="mt-3">
                    <label htmlFor="additionalComments_txtArea">
                      <strong className="Input_titles">
                        Please share your suggestions to improve
                      </strong>
                    </label>

                    <div className="form-group mt-2">
                      <textarea
                        className="form-control custom-shadow input_textbox"
                        placeholder="Please type your suggestions here"
                        id="additionalComments_txtArea"
                        defaultValue={""}
                        style={inputColorChanges}
                        rows="4"
                        cols="30"
                        maxLength="256"
                        onChange={(e) =>
                          setAdditional_Suggestion(e.target.value)
                        }
                      />
                      {/* <label htmlFor="additionalComments_txtArea">
                          Please give your valuable suggestions here
                        </label> */}
                    </div>
                  </div>
                )}
              </div>
              {/* 10 */}
              <div className="mb-3">
                <p>
                  <strong className="Input_titles">
                    Do we have your consent to add this feedback in SBH
                    testimonials?
                  </strong>
                </p>

                <div className="d-flex flex-row gap-4">
                  <div className="form-check">
                    <Field
                      className="form-check-input consent_yes"
                      type="radio"
                      name="FeedbackConsent"
                      id="feedbackConsent_yes"
                      onClick={() => setConsentFeedback("No")}
                      value="No"
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="feedbackConsent_yes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input consent_no"
                      type="radio"
                      name="FeedbackConsent"
                      id="feedbackConsent_no"
                      onClick={() => setConsentFeedback("No")}
                      value="Yes"
                    />
                    <label
                      className="form-check-label radio_btn_txt"
                      htmlFor="feedbackConsent_no"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <ErrorMessage
                className="text-danger"
                component="div"
                name="FeedbackConsent"
              />
              {/* Submit Button */}
              <div className="mt-3" onClick={handleSubmit}>
                <button
                  type="button"
                  class="btn btn-success"
                  disabled={isButtonDisabled}
                >
                  Submit
                </button>
              </div>
              {/* </div> */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
