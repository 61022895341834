import React, { useState, useEffect, useContext } from "react";
import { IMAGES } from "../helper/images";
import { Parallax } from "react-parallax";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
// import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { userForgotPasswordVerifyApi } from "../helper/http_api";
import { useAlert } from "react-alert";
import { resendVerifyCodeApi } from "../helper/http_api";
import HeaderWithIcon from "../components/commoncomponents/header_home_icon";
import styles from "../styles/forgot_password_verify.module.css";
import { getBookFunc } from "../helper/temporary_data";
import { setLocalStorage } from "../helper/localstorage_update";
import { CartContext } from "../context/cart_context";
import { STRINGS } from "../model/constant";
function EmailVerify(props) {
 const { updateLoggedIn_Func } = useContext(CartContext);
 // const [isSmallScreen, setIsSmallScreen] = useState(false);

 // useEffect(() => {
 //   const handleResize = () => {
 //     if (window.innerWidth >= 320) {
 //       setIsSmallScreen(true);
 //     } else {
 //       setIsSmallScreen(false);
 //     }
 //   };

 //   // Initial check
 //   handleResize();

 //   // Attach the event listener
 //   window.addEventListener("resize", handleResize);

 //   // Clean up the event listener on component unmount
 //   return () => {
 //     window.removeEventListener("resize", handleResize);
 //   };
 // }, []);

 const location = useLocation();
 const queryParams = new URLSearchParams(location.search);

 /* getting data from home page using queryParams */
 const userMail = queryParams.get("userMail");
 const userId = queryParams.get("userId");
 const userName = queryParams.get("userName");
 const userPhone = queryParams.get("userPhone");
 const userAccessToken = queryParams.get("userAccessToken");
 const userLoginStatus = queryParams.get("userLoginStatus");
 let userIsVerified = queryParams.get("userLoginStatus");

 const [showVerifyCode, setVerifyCode] = useState(false);
 const [userForgotPasswordVerifyData, setUserForgotPasswordVerifyData] = useState({
  verifyCode: "",
 });

 const alert = useAlert(); // Initialize the useAlert hook

 const [showSpinner, setShowSpinner] = useState(false);
 const navigate = useNavigate();

 const handleChange = (e) => {
  const { name, value } = e.target;
  if (value.length != 6) {
   e.target.setCustomValidity(STRINGS.verificationCode);
  } else {
   e.target.setCustomValidity("");
  }
  setUserForgotPasswordVerifyData((prevUserForgotPasswordVerifyData) => ({
   ...prevUserForgotPasswordVerifyData,
   [name]: value.toUpperCase(),
  }));
 };

 /* payloads */
 const mailVerify_api_payload = {
  verificationCode: userForgotPasswordVerifyData.verifyCode,
  id: userId,
 };

 const resendVerifyCode_api_payload = {
  email: userMail,
 };

 console.log("resendMailVerify api payload: ", resendVerifyCode_api_payload);

 const handleSubmit = (e) => {
  e.preventDefault();
  // console.log(userForgotPasswordVerifyData);
  setShowSpinner(true);

  const forgotPasswordVerifyPromise = new Promise(async (resolve, reject) => {
   try {
    const response = await userForgotPasswordVerifyApi(mailVerify_api_payload);
    resolve(response.data);
   } catch (error) {
    reject(error);
   }
  });

  forgotPasswordVerifyPromise
   .then((data) => {
    const { success, message } = data;
    // setShowSpinner(false);

    setTimeout(() => {
     setShowSpinner(false);
     if (success) {
      console.log("### forgot password verify code match successfull! ");
      updateLoggedIn_Func(data);
      // navigate(`/`);
      if (!getBookFunc()) {
       navigate(`/`);
      } else {
       navigate("/user/booking_details");
      }
      // alert(message);
     } else {
      // console.log("### forgot password verify code failed ! ", message);
      // alert(message);
      alert.error(message);
     }
    }, 1000);
   })
   .catch((error) => {
    setShowSpinner(false);
    console.error("Error occurred while user mail verification in server", error);
    alert.error("Oops! Something unexpected happened.");
   });

  // setTimeout(() => {
  //   setShowSpinner(false);
  // }, 2000);
 };

 const handleHomeClick = () => {
  setShowSpinner(true);
  setTimeout(() => {
   setShowSpinner(false);
   navigate(`/`);
  }, 1000);
 };

 const [countdown, setCountdown] = useState(180);

 // useEffect(() => {
 //   let timer;

 //   if (countdown > 0 && !showVerifyCode) {
 //     timer = setInterval(() => {
 //       setCountdown(countdown - 1);
 //     }, 1000);
 //   }

 //   return () => {
 //     clearInterval(timer);
 //   };
 // }, [countdown, showVerifyCode]);

 useEffect(() => {
  let timer;
  if (countdown > 0) {
   timer = setInterval(() => {
    setCountdown(countdown - 1);
   }, 1000);
  }

  return () => {
   clearInterval(timer);
  };
 }, [countdown]);

 const handleResendCode = () => {
  // Add your logic to resend the code here
  setShowSpinner(true);
  const resendCodePromise = new Promise(async (resolve, reject) => {
   try {
    const response = await resendVerifyCodeApi(resendVerifyCode_api_payload);
    resolve(response.data);
   } catch (error) {
    reject(error);
   }
  });

  resendCodePromise
   .then((data) => {
    // console.log("verification data", data);
    const { success, message } = data;
    // setShowSpinner(false);

    setTimeout(() => {
     setShowSpinner(false);
     if (success) {
      setCountdown(180);
      alert.success(message);
      // navigate(`/user/auth/forgot_password_verify`);
     } else {
      alert.error(message);
     }
    }, 1000);
   })
   .catch((error) => {
    setShowSpinner(false);
    console.error("Error occurred:", error);
    alert.error(
     "An error occurred while forgot password email verify. Please try again later."
    );
   });
 };

 return (
  <div>
   <Parallax
    // blur={10}
    bgImage={IMAGES.bgImg_section1}
    bgImageAlt="section1"
    // strength={120}
   >
    <div className={styles.content}>
     {/* active spinner */}
     {showSpinner && (
      <div className={styles.spinner_layout}>
       <SpinnerDotted color="white" />
      </div>
     )}

     <HeaderWithIcon HomeIconClick={handleHomeClick} />

     <form onSubmit={handleSubmit} className={styles.form_container}>
      <div className={styles.forgotPassword2_details_cont}>
       {/* <div
                className={{
                  // display: "flex",
                  backgroundColor: "red",
                }}
              >
                <RxCross1
                  size={20}
                  // onClick={() => setVerifyCode(true)}
                  className={styles.back_icon}
                />
              </div> */}
       <div className={styles.forgotPassword_title}>Email Verification</div>
       <div className={styles.user_input}>
        <div className={styles.input_field_cont}>
         <input
          type={showVerifyCode ? "text" : "password"}
          placeholder="Verification Code"
          className={styles.input_field1}
          name="verifyCode"
          value={userForgotPasswordVerifyData.verifyCode}
          onChange={handleChange}
          required
          maxLength="6"
          autoComplete="off"
          // pattern="[A-Z0-9]+"
         />
         <div className={styles.input_icons}>
          {showVerifyCode ? (
           <BsEye size={20} onClick={() => setVerifyCode(false)} className={styles.eye_icon} />
          ) : (
           <BsEyeSlash
            size={20}
            onClick={() => setVerifyCode(true)}
            className={styles.eye_icon}
           />
          )}
         </div>
        </div>
        {/* <div className={styles.resend_code1}>
                  {countdown > 0 ? (
                    `Resend code in ${countdown} seconds`
                  ) : (
                    <div
                      onClick={handleResendCode}
                      className={styles.resend_code2}
                    >
                      Resend code
                    </div>
                  )}
                </div> */}
        <div className={styles.resend_code1}>
         {countdown > 0 ? (
          `Resend Code in ${Math.floor(countdown / 60)}:${(countdown % 60)
           .toString()
           .padStart(2, "0")} minutes`
         ) : (
          <div onClick={handleResendCode} className={styles.resend_code2}>
           Resend Code
          </div>
         )}
        </div>
       </div>
      </div>
      {/* abc */}
      <div className={styles.login_details_cont1}>
       <div className={styles.below_upper_cont}>
        <div className={styles.submit_btn_cont}>
         <button type="submit" className={styles.btn_submit}>
          <div className={styles.btn_cont}>
           <div className={styles.btn_name}>Submit</div>
          </div>
         </button>
        </div>
       </div>
       {/* {isSmallScreen ? (
                <div className={styles.below_lower_cont}>
                  <div>We've sent a code to your</div>
                  <div>registered mail.</div>
                </div>
              ) : (
                <div className={styles.below_lower_cont}>
                  We've sent a code to your registered mail.
                </div>
              )} */}
       <div className={styles.below_lower_cont}>We've sent a code to your mail.</div>
      </div>
     </form>
    </div>
   </Parallax>
  </div>
 );
}

export default EmailVerify;
