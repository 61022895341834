import { SpinnerDotted } from "spinners-react";
import styles from "../../styles/spinner_loader.module.css";

const SpinnerLoader = () => {
  return (
    <div className={styles.spinner_layout}>
      <SpinnerDotted color="white" />
    </div>
  );
};

export default SpinnerLoader;
