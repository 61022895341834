import { APP_CONFIG } from "../model/constant";

/* local server ip*/
// export const BASE_URL = "http://192.168.10.132:66661";

/* development server ip */
export const BASE_URL = APP_CONFIG.API_URL;
console.log({ BASE_URL });

/* development server global ip */
// export const BASE_URL = "http://103.109.44.52:55544";

/* staging server ip  */
// export const BASE_URL = "https://ftp.iinvsys.com:55576/sengal";

/* production server ip */
// export const BASE_URL = "https://ftp.iinvsys.com:8443/ver";
