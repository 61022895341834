import React from "react";
import { STRINGS } from "../../../model/constant";
import styles from "../../../styles/foodstyles/cartstyles/CartBottom.module.css";

const CartBottomRestaurant = () => {
  return (
    <div className={styles.cartBottom_cont1}>
      <p className={styles.admin_contact_note}>{STRINGS.admin_contact_txt}</p>
    </div>
  );
};

export default CartBottomRestaurant;
