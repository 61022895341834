import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import {
 checkRoomAvailabilityApi,
 getRoomDetailsApi,
 userLogoutAPI,
} from "../../helper/http_api";
import { setBookFunc, setBookingSummaryDataFunc } from "../../helper/temporary_data";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { GoUnmute } from "react-icons/go";
import { GoMute } from "react-icons/go";
import { BsChevronDoubleDown } from "react-icons/bs";
import { BsFillCalendar2EventFill } from "react-icons/bs";
import { SpinnerDotted } from "spinners-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { CartContext } from "../../context/cart_context";
import ConfirmTemplate from "../commoncomponents/confirm_popup";
import formatDateToYYYYMMDD from "../../helper/date_conversion";
import enIN from "date-fns/locale/en-IN";

// styles
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/datepicker.css";
import "react-dropdown/style.css";
import "../../styles/dropdown.css";
import styles from "../../styles/home_section_top.module.css";
import { STRINGS } from "../../model/constant";
import { getLocalStorage } from "../../helper/localstorage_update";

/* global temp. variables */
let PerDayCost = 7000;
let ExtraBedCost = 1000;
let GST = 12;
let roomCount = 1;
let adultCount = 2;
let kidCount = 2;
let extraBedCount = 1;
let RoomType = "Deluxe";
let adult_in_room = 2;
let kid_in_room = 2;
let extraBed_in_room = 1;

const HomeSectionTop = ({ scrollToHomeSectionMiddle, onToggleMute }) => {
 const { userAuthentication_Details, setUserAuthentication_Details, logout_Func } =
  useContext(CartContext);
 const [availabilityData, setAvailabilityData] = useState({
  checkInDate: "",
  checkOutDate: "",
  numberOfRooms: "",
  numberOfAdults: "",
  numberOfKids: "",
  numberOfExtraBeds: "",
 });
 const [showMute, setShowMute] = useState(true);
 const [showSpinner, setShowSpinner] = useState(false);
 const [isButtonDisabled, setIsButtonDisabled] = useState(false);
 const [showConfirmDialog, setShowConfirmDialog] = useState(false);
 const [isSmallScreen, setIsSmallScreen] = useState(false);
 const [isLandscape, setIsLandscape] = useState(false);
 const loggedIn_Status = localStorage.getItem("loggedIn_Status");
 const loggedIn_user_mail_status = localStorage.getItem("loggedIn_user_mail_status");
 const [isLoggedIn, setisLoggedIn] = useState(loggedIn_Status);
 const [allData, setAllData] = useState([]);
 const loginState = isLoggedIn;

 localStorage.setItem("isLoggedInUser", loginState);

 const [roomTypes, setRoomTypes] = useState(["Deluxe"]);
 const [isOpen, setIsOpen] = useState(false);
 const [isOpen1, setIsOpen1] = useState(false);
 const [roomType, setRoomType] = useState("Deluxe");
 const [rooms, setRooms] = useState(1);
 const [adults, setAdults] = useState(1);
 const [kids, setKids] = useState(0);
 const [extraBeds, setExtraBeds] = useState(0);

 const [adult, setAdult] = useState(2);
 const [kid, setKid] = useState(2);
 const [extraBed, setExtraBed] = useState(1);

 const [isDeluxe, setIsDeluxe] = useState(false);

 const getIntial_Data = (hotelRoomData1) => {
  let hotelRoomData = hotelRoomData1;

  if (hotelRoomData[0]?.PerDayCost != undefined) {
   PerDayCost = hotelRoomData[0]?.PerDayCost;
  } else {
   PerDayCost = 0;
  }

  if (hotelRoomData[0]?.ExtraBedsCost != undefined) {
   ExtraBedCost = hotelRoomData[0]?.ExtraBedsCost;
  } else {
   ExtraBedCost = 0;
  }

  if (hotelRoomData[0]?.GST != undefined) {
   GST = hotelRoomData[0]?.GST;
  }

  if (hotelRoomData[0]?.RoomCounts != undefined) {
   roomCount = hotelRoomData[0]?.RoomCounts;
  } else {
   roomCount = 1;
  }

  if (hotelRoomData[0]?.Adult != undefined) {
   adultCount = hotelRoomData[0]?.Adult;
  } else {
   adultCount = 2;
  }

  if (hotelRoomData[0]?.Child != undefined) {
   kidCount = hotelRoomData[0]?.Child;
  } else {
   kidCount = 2;
  }

  if (hotelRoomData[0]?.NofExtraBeds != undefined) {
   extraBedCount = hotelRoomData[0]?.NofExtraBeds;
  } else {
   extraBedCount = 1;
  }

  RoomType = hotelRoomData[0]?.RoomType ? hotelRoomData[0]?.RoomType : "Deluxe";

  if (hotelRoomData[0]?.Adult != undefined) {
   adult_in_room = hotelRoomData[0]?.Adult;
  } else {
   adult_in_room = 2;
  }

  if (hotelRoomData[0]?.Child != undefined) {
   kid_in_room = hotelRoomData[0]?.Child;
  } else {
   kid_in_room = 2;
  }

  if (hotelRoomData[0]?.NofExtraBeds != undefined) {
   extraBed_in_room = hotelRoomData[0]?.NofExtraBeds;
  } else {
   extraBed_in_room = 1;
  }

  if (hotelRoomData[0]?.Adult != undefined) {
   setAdult(hotelRoomData[0]?.Adult);
  } else {
   setAdult(2);
  }

  if (hotelRoomData[0]?.Child != undefined) {
   setKid(hotelRoomData[0]?.Child);
  } else {
   setKid(2);
  }

  if (hotelRoomData[0]?.NofExtraBeds != undefined) {
   setExtraBed(hotelRoomData[0]?.NofExtraBeds);
  } else {
   setExtraBed(1);
  }
 };

 useMemo(() => {
  getIntial_Data(allData);
 }, [roomType]);

 useEffect(() => {
  getRoomDetails();
 }, []);

 const getRoomDetails = async () => {
  const getRoomDetailPromise = new Promise(async (resolve, reject) => {
   try {
    const response = await getRoomDetailsApi({});
    resolve(response.data);
   } catch (error) {
    reject(error);
   }
  });

  getRoomDetailPromise
   .then((data) => {
    const { success } = data;
    setTimeout(() => {
     if (success) {
      getIntial_Data(data.getroomdetails);
      setAllData(data.getroomdetails);
     } else {
     }
    }, 1000);
   })
   .catch((error) => {
    console.error("Error occurred while getting room details from server:", error);
   });
 };

 const dropdownRef = useRef(null);

 useEffect(() => {
  function handleClickOutside(event) {
   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    setIsOpen(false);
    setIsOpen1(false);
   }
  }

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
   document.removeEventListener("mousedown", handleClickOutside);
  };
 }, []);

 const toggleDropdown = () => {
  setIsOpen(!isOpen);
 };

 const toggleDropdown1 = () => {
  setIsOpen1(!isOpen1);
 };

 const increment_room = (valueSetter) => {
  if (rooms < roomCount) {
   setAdult(adult + adult_in_room);
   setKid(kid + kid_in_room);
   setExtraBed(extraBed + extraBed_in_room);
  }
  valueSetter((prevValue) => (prevValue < roomCount ? prevValue + 1 : roomCount));
 };

 const increment_adult = (valueSetter) => {
  valueSetter((prevValue) => (prevValue < adult ? prevValue + 1 : adult));
 };

 const increment_kid = (valueSetter) => {
  valueSetter((prevValue) => (prevValue < kid ? prevValue + 1 : kid));
 };

 const increment_bed = (valueSetter) => {
  valueSetter((prevValue) => (prevValue < extraBed ? prevValue + 1 : extraBed));
 };

 const decrement_room = (valueSetter) => {
  rooms > 1 ? setAdult(adult - adult_in_room) : setAdult(adult_in_room);
  rooms > 1 ? setKid(kid - kid_in_room) : setKid(kid_in_room);
  rooms > 1 ? setExtraBed(extraBed - extraBed_in_room) : setExtraBed(extraBed_in_room);
  if (rooms > 1) {
   adults > 2 ? setAdults(adults - adult_in_room) : setAdults(1);
  }

  if (rooms > 1) {
   kids > 1 ? setKids(kids - kid_in_room) : setKids(0);
  }

  if (rooms > 1) {
   extraBeds > 1 ? setExtraBeds(extraBeds - extraBed_in_room) : setExtraBeds(0);
  }
  valueSetter((prevValue) => (prevValue > 1 ? prevValue - 1 : 1));
 };

 const decrement = (valueSetter) => {
  valueSetter((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
 };

 const decrement1 = (valueSetter) => {
  valueSetter((prevValue) => (prevValue > 1 ? prevValue - 1 : 1));
 };

 const selectedOptionsText1 = `${rooms} ${roomType} Room${rooms !== 1 ? "s" : ""}`;

 const selectedOptionsText = `${adults} Adult${adults !== 1 ? "s" : ""}, ${kids} Kid${
  kids !== 1 ? "s" : ""
 }, ${extraBeds} Extra bed${extraBeds !== 1 ? "s" : ""}`;

 const handleMuteUnmute = () => {
  setShowMute(!showMute);
  onToggleMute();
 };

 registerLocale("enIN", enIN);
 const hotel_address =
  "https://www.google.com/maps/place/Sengal+Boutique+Hotel/@12.0232246,79.7984199,17z/data=!4m20!1m10!3m9!1s0x3a5367c617d3024f:0x285b7f8dc66496e1!2sSengal+Boutique+Hotel!5m2!4m1!1i2!8m2!3d12.0232194!4d79.8009948!16s%2Fg%2F11fmxj04vj!3m8!1s0x3a5367c617d3024f:0x285b7f8dc66496e1!5m2!4m1!1i2!8m2!3d12.0232194!4d79.8009948!16s%2Fg%2F11fmxj04vj?entry=ttu";

 const alert = useAlert();
 const navigate = useNavigate();

 const handleLogout = () => {
  setShowConfirmDialog(true);
 };

 const confirmLogout = async () => {
  setShowConfirmDialog(false);
  setShowSpinner(true);
  let sessionData = await getLocalStorage(["sessionID"]);
  try {
   const response = await userLogoutAPI({
    userId: userAuthentication_Details.loggedInUserId,
    sessionID: sessionData?.sessionID,
   });
   let { success } = response?.data;
   setShowSpinner(false);
   if (success) {
    logout_Func();
    setisLoggedIn(false);
    setUserAuthentication_Details({});
    handleResetForm();
    setBookFunc(false);
    navigate(`/`);
    alert.success(STRINGS.loggedoutToast);
   } else {
    alert.success(STRINGS.somethingunexpected);
   }
  } catch (error) {
   setShowSpinner(false);
   alert.success(STRINGS.somethingunexpected);
  }
 };

 const cancelLogout = () => {
  setShowConfirmDialog(false);
 };

 const handleRoomTypeChange = (event) => {
  setRooms(1);
  setAdults(1);
  setKids(0);
  setExtraBeds(0);
  setAdult(2);
  setKid(1);
  setExtraBed(1);
  setRoomType(event.target.value);
 };

 const handleDateChange = (name, date) => {
  setAvailabilityData((prevAvailabilityData) => ({
   ...prevAvailabilityData,
   [name]: date,
  }));
 };

 const handleResetForm = () => {
  setAvailabilityData({
   checkInDate: "",
   checkOutDate: "",
   numberOfRooms: "",
   numberOfAdults: "",
   numberOfKids: "",
   numberOfExtraBeds: "",
  });
  setRooms(1);
  setAdults(1);
  setKids(0);
  setExtraBeds(0);
 };

 const availability_check_api_payload = {
  checkInDate: formatDateToYYYYMMDD(availabilityData.checkInDate),
  checkOutDate: formatDateToYYYYMMDD(availabilityData.checkOutDate),
  NofAdults: adults,
  NofChild: kids,
  numberOfRooms: rooms,
  RoomType: RoomType,
 };

 // console.log("###PAYLOAD DATA 1: ", availability_check_api_payload);

 const handleSubmit = async (e) => {
  e.preventDefault();

  setShowSpinner(true);
  setIsButtonDisabled(true);
  await getRoomDetails();
  const checkRoomAvailabilityPromise = new Promise(async (resolve, reject) => {
   try {
    const response = await checkRoomAvailabilityApi(availability_check_api_payload);
    resolve(response.data);
   } catch (error) {
    reject(error);
   }
  });

  checkRoomAvailabilityPromise
   .then((data) => {
    // console.log("### Checking Availability Response Data ===>", data);
    let { success, isAvailable, availableRooms, message, Price } = data;
    // setShowSpinner(false);

    setTimeout(() => {
     setShowSpinner(false);
     if (success && isAvailable) {
      if (rooms <= availableRooms) {
       if (extraBeds > rooms) {
        alert.error("Number of extra bed cannot be more than number of rooms.");
       } else {
        /* storing room checking availability data in pojo*/
        setBookingSummaryDataFunc({
         checkInDate: formatDateToYYYYMMDD(availabilityData.checkInDate),
         checkOutDate: formatDateToYYYYMMDD(availabilityData.checkOutDate),
         numberOfAdults: adults,
         numberOfKids: kids,
         numberOfRooms: rooms,
         RoomType: roomType,
         PerDayCost: Price,
         ExtraBedCost: ExtraBedCost,
         GST: GST,
         numberOfExtraBeds: extraBeds,
         mailVerifiedStatus: loggedIn_user_mail_status ? loggedIn_user_mail_status : false,
         loggedInStatus: loggedIn_Status ? loggedIn_Status : false,
         // RoomId: 1,
        });

        navigate(`/user/booking_details`);
        // alert.success("Please continue with the booking process.");
       }
      } else {
       alert.error(`Sorry, currently ${availableRooms} rooms are available only`);
       handleResetForm();
      }
     } else {
      if (message === "Date must be in the ISO date format (YYYY-MM-DD)") {
       message = "Please select check-in date & check-out date.";
      } else if (message === "Number of rooms must be a valid number") {
       message = "Please select number of rooms.";
      } else if (message === "Number of Members must be a valid number") {
       message = "Please select number of adults.";
      } else if (message === "Selected room type is not available") {
       message = "No rooms available at this moment.";
      }
      alert.error(
       // "Sorry, the room are not available for the selected dates."
       message
      );
      handleResetForm();
     }
     // enabling button
     setTimeout(() => {
      setIsButtonDisabled(false);
     }, 4000);
    }, 1000);
   })
   .catch((error) => {
    setShowSpinner(false);
    console.error("Error occurred while checking room availability from server:", error);
    alert.error("Oops! Something unexpected happened.");
    handleResetForm();
    // enabling button
    setTimeout(() => {
     setIsButtonDisabled(false);
    }, 4000);
   });
  // }
  // }
 };

 const NoKeyboardInput = (e) => {
  e.preventDefault(); // Prevents the input field from receiving focus
 };

 /* current date as per local machine  */
 const today = new Date();

 // Get current date in UTC
 // const utcDate = today.toLocaleDateString("en-US", { timeZone: "UTC" });

 // Get current date in Indian Standard Time (IST)
 // const istDate = today.toLocaleDateString("en-IN", {
 //   timeZone: "Asia/Kolkata",
 // });

 // console.log("LOCAL Date:", today);
 // console.log("UTC Date:", utcDate);
 // console.log("IST Date:", istDate);

 function isSameDay(date1, date2) {
  return (
   date1.getDate() === date2.getDate() &&
   date1.getMonth() === date2.getMonth() &&
   date1.getFullYear() === date2.getFullYear()
  );
 }

 useEffect(() => {
  if (showConfirmDialog) {
   document.body.classList.add(styles["disable-scrollbar"]);
  } else {
   document.body.classList.remove(styles["disable-scrollbar"]);
  }
 }, [showConfirmDialog]);

 // useEffect(() => {
 //   const handleResize = () => {
 //     if (window.innerWidth <= 912) {
 //       setIsSmallScreen(true);
 //     } else {
 //       setIsSmallScreen(false);
 //     }
 //   };

 //   handleResize();

 //   window.addEventListener("resize", handleResize);

 //   return () => {
 //     window.removeEventListener("resize", handleResize);
 //   };
 // }, []);

 useEffect(() => {
  const handleResize = () => {
   // Check for small screen
   if (window.innerWidth <= 912) {
    setIsSmallScreen(true);
   } else {
    setIsSmallScreen(false);
   }

   // Check for landscape orientation with a max-width of 767 pixels
   const isLandscapeOrientation = window.matchMedia(
    "(max-height: 575.98px) and (orientation: landscape)"
   ).matches;
   setIsLandscape(isLandscapeOrientation);
  };

  // Initial check
  handleResize();

  // Event listener for resize
  window.addEventListener("resize", handleResize);

  // Cleanup the event listener on component unmount
  return () => {
   window.removeEventListener("resize", handleResize);
  };
 }, []);

 if (showConfirmDialog) {
  return (
   <ConfirmTemplate
    confirmDialog_msg={STRINGS.logout}
    confirmOk={confirmLogout}
    confirmCancel={cancelLogout}
    // className={styles.confirm_popup}
   />
  );
 }

 // const handleOccupancyClick = () => {
 //   setOccupancyClicked(true);
 // };

 return (
  <>
   {showSpinner && (
    <div className={styles.spinner_layout}>
     <SpinnerDotted color="white" />
    </div>
   )}
   <div className={styles.header_container}>
    <header className={styles.primary_header}>
     <nav className={styles.header_nav}>
      {isLoggedIn && (
       <div className={styles.loggedin_user}>
        Hi, {userAuthentication_Details.loggedIn_user_name}
       </div>
      )}
      {isSmallScreen ? (
       <div className={styles.mute_unmute_icon}>
        {showMute ? (
         <GoMute size={23} color="rgba(216, 212, 207, 1)" onClick={handleMuteUnmute} />
        ) : (
         <GoUnmute size={23} color="rgba(216, 212, 207, 1)" onClick={handleMuteUnmute} />
        )}
       </div>
      ) : (
       <div className={styles.mute_unmute_icon}>
        {showMute ? (
         <GoMute size={25} color="rgba(216, 212, 207, 1)" onClick={handleMuteUnmute} />
        ) : (
         <GoUnmute size={25} color="rgba(216, 212, 207, 1)" onClick={handleMuteUnmute} />
        )}
       </div>
      )}
      <div className={styles.location}>
       <a
        href={hotel_address}
        target="_blank"
        rel="noreferrer"
        className={styles.location_link}
       >
        Location
       </a>
      </div>
      {userAuthentication_Details?.loggedInUserId ? (
       <div className={styles.register_btn_link}>
        <div className={styles.btn_signup} onClick={handleLogout}>
         {STRINGS.sign_out}
        </div>
       </div>
      ) : (
       <>
        <Link to="/user/auth/login" className={styles.register_btn_link}>
         <div className={styles.btn_signup}>{STRINGS.Sign_in_text}</div>
        </Link>
       </>
      )}
     </nav>
    </header>
   </div>
   <div className={styles.section1}>
    <div className={styles.page_title}>Sengal Boutique Hotel</div>
    <form onSubmit={handleSubmit}>
     {isLandscape ? (
      <div ref={dropdownRef} className={styles.book_now_btn_cont}>
       {isOpen1 && (
        <div
         className={styles.dropdown_panel1}
         style={
          isDeluxe
           ? {}
           : {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             }
         }
        >
         {isDeluxe && (
          <div className={styles.down1}>
           <select
            value={roomType}
            onChange={handleRoomTypeChange}
            className={styles.room_type1}
           >
            {roomTypes.map((roomType, index) => (
             <option key={index} value={roomType} className={styles.room_types1_opt}>
              {roomType}
             </option>
            ))}
           </select>
          </div>
         )}
         <div className={styles.up1}>
          <div className={styles.left2_r}>Rooms</div>
          <div className={styles.right2_r}>
           <div className={styles.count1} onClick={() => decrement_room(setRooms)}>
            <AiOutlineMinus />
           </div>
           <div className={styles.room_val}>{rooms}</div>
           <div className={styles.count1} onClick={() => increment_room(setRooms)}>
            <AiOutlinePlus />
           </div>
          </div>
         </div>
        </div>
       )}
       {isOpen && (
        <div className={styles.dropdown_panel}>
         <div className={styles.left1}>
          <div className={styles.left2_a}>Adults</div>
          <div className={styles.left2_k}>Kids</div>
          <div className={styles.left2_ex}>Extra Beds</div>
         </div>
         <div className={styles.right1}>
          <div className={styles.right2_a}>
           <div className={styles.count} onClick={() => decrement1(setAdults)}>
            <AiOutlineMinus color="rgba(19, 20, 20, 0.851)" />
           </div>
           <div className={styles.adult_val}>{adults}</div>
           <div className={styles.count} onClick={() => increment_adult(setAdults)}>
            <AiOutlinePlus color="rgba(19, 20, 20, 0.851)" />
           </div>
          </div>
          <div className={styles.right2_k}>
           <div className={styles.count} onClick={() => decrement(setKids)}>
            <AiOutlineMinus color="rgba(19, 20, 20, 0.851)" />
           </div>
           <div className={styles.adult_val}>{kids}</div>
           <div className={styles.count} onClick={() => increment_kid(setKids)}>
            <AiOutlinePlus color="rgba(19, 20, 20, 0.851)" />
           </div>
          </div>
          {/* extra beds counter */}
          <div className={styles.right2_ex}>
           <div className={styles.count} onClick={() => decrement(setExtraBeds)}>
            <AiOutlineMinus color="rgba(19, 20, 20, 0.851)" />
           </div>
           <div className={styles.adult_val}>{extraBeds}</div>
           <div className={styles.count} onClick={() => increment_bed(setExtraBeds)}>
            <AiOutlinePlus color="rgba(19, 20, 20, 0.851)" />
           </div>
          </div>
         </div>
        </div>
       )}
      </div>
     ) : (
      <div ref={dropdownRef} className={styles.book_now_btn_cont}>
       {isOpen1 && (
        <div
         className={styles.dropdown_panel1}
         style={
          isDeluxe
           ? {}
           : {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             }
         }
        >
         {isDeluxe && (
          <div className={styles.down1}>
           <select
            value={roomType}
            onChange={handleRoomTypeChange}
            className={styles.room_type1}
           >
            {roomTypes.map((roomType, index) => (
             <option key={index} value={roomType} className={styles.room_types1_opt}>
              {roomType}
             </option>
            ))}
           </select>
          </div>
         )}
         <div className={styles.up1}>
          <div className={styles.left2_r}>Rooms</div>
          <div className={styles.right2_r}>
           <div className={styles.count1} onClick={() => decrement_room(setRooms)}>
            <AiOutlineMinus />
           </div>
           <div className={styles.room_val}>{rooms}</div>
           <div className={styles.count1} onClick={() => increment_room(setRooms)}>
            <AiOutlinePlus />
           </div>
          </div>
         </div>
        </div>
       )}
       {isOpen && (
        <div className={styles.dropdown_panel}>
         <div className={styles.left1}>
          <div className={styles.left2_a}>Adults</div>
          <div className={styles.left2_k}>Kids</div>
          <div className={styles.left2_ex}>Extra Beds</div>
         </div>
         <div className={styles.right1}>
          <div className={styles.right2_a}>
           <div className={styles.count} onClick={() => decrement1(setAdults)}>
            <AiOutlineMinus color="rgba(19, 20, 20, 0.851)" />
           </div>
           <div className={styles.adult_val}>{adults}</div>
           <div className={styles.count} onClick={() => increment_adult(setAdults)}>
            <AiOutlinePlus color="rgba(19, 20, 20, 0.851)" />
           </div>
          </div>
          <div className={styles.right2_k}>
           <div className={styles.count} onClick={() => decrement(setKids)}>
            <AiOutlineMinus color="rgba(19, 20, 20, 0.851)" />
           </div>
           <div className={styles.adult_val}>{kids}</div>
           <div className={styles.count} onClick={() => increment_kid(setKids)}>
            <AiOutlinePlus color="rgba(19, 20, 20, 0.851)" />
           </div>
          </div>
          <div className={styles.right2_ex}>
           <div className={styles.count} onClick={() => decrement(setExtraBeds)}>
            <AiOutlineMinus color="rgba(19, 20, 20, 0.851)" />
           </div>
           <div className={styles.adult_val}>{extraBeds}</div>
           <div className={styles.count} onClick={() => increment_bed(setExtraBeds)}>
            <AiOutlinePlus color="rgba(19, 20, 20, 0.851)" />
           </div>
          </div>
         </div>
        </div>
       )}
      </div>
     )}
     {isLandscape ? (
      <div className={styles.book_form}>
       <div className={styles.book_form_up_cont}>
        <div className={styles.book_form_up_cont_left}>
         {/* checkin */}
         <div className={styles.dropdown_btn1a}>
          <div className={styles.dropdown_btn_name}>
           <div className={styles.datepicker_container}>
            <DatePicker
             className={styles.date_picker}
             selected={availabilityData.checkInDate}
             onChange={(date) => handleDateChange("checkInDate", date)}
             dateFormat="yyyy-MM-dd"
             placeholderText="Check-in date"
             popperPlacement="top-start"
             minDate={today}
             locale="enIN"
             onKeyDown={NoKeyboardInput}
             onClick={NoKeyboardInput}
             onFocus={(e) => e.target.blur()}
             disabledKeyboardNavigation
             dayClassName={(date) => {
              if (date < today && !isSameDay(date, today)) {
               return "custom-disabled-day";
              }
              return null;
             }}
            />

            <div
             className={styles.dropdown_calendar_icon}
             onClick={() => {
              const checkOutDatepickerInput = document?.querySelector(
               `.${styles.dropdown_btn1a} .${styles.date_picker}`
              );
              checkOutDatepickerInput?.click();
             }}
            >
             <BsFillCalendar2EventFill size={13.5} color="rgb(240, 255, 255, 0.9)" />
            </div>
           </div>
          </div>
         </div>
         <div className={styles.dropdown_btn1d}>
          <div className={styles.dropdown_btn_name}>
           <div className={styles.datepicker_container}>
            <DatePicker
             className={styles.date_picker}
             selected={availabilityData.checkOutDate}
             onChange={(date) => {
              if (
               availabilityData.checkInDate &&
               date <= new Date(availabilityData.checkInDate.getTime())
              ) {
               return;
              }
              handleDateChange("checkOutDate", date);
             }}
             dateFormat="yyyy-MM-dd"
             placeholderText="Check-out date"
             popperPlacement="top-start"
             minDate={
              availabilityData.checkInDate
               ? new Date(availabilityData.checkInDate.getTime() + 86400000)
               : today
             }
             locale="enIN"
             onKeyDown={NoKeyboardInput}
             onClick={NoKeyboardInput}
             onFocus={(e) => e.target.blur()}
             disabledKeyboardNavigation
             dayClassName={(date) => {
              if (
               (date < today && !isSameDay(date, today)) ||
               (availabilityData.checkInDate && date <= availabilityData.checkInDate)
              ) {
               return "custom-disabled-day";
              }

              if (
               availabilityData.checkInDate &&
               isSameDay(date, availabilityData.checkInDate)
              ) {
               return "custom-same-day";
              }
              return null;
             }}
            />
            <div
             className={styles.dropdown_calendar_icon}
             onClick={() => {
              const checkOutDatepickerInput = document?.querySelector(
               `.${styles.dropdown_btn1d} .${styles.date_picker}`
              );
              checkOutDatepickerInput?.click();
             }}
            >
             <BsFillCalendar2EventFill size={13.5} color="rgb(240, 255, 255,0.9)" />
            </div>
           </div>
          </div>
         </div>
        </div>
        <div className={styles.book_form_up_cont_right}>
         <div className={styles.dropdown_btn2}>
          <div className={styles.test_room} onClick={toggleDropdown1}>
           <div className="dropdown-toggle1">{selectedOptionsText1}</div>
           <div className={styles.icon3}>
            {isOpen1 ? (
             <IoMdArrowDropup color="#999" size={22} />
            ) : (
             <IoMdArrowDropdown color="rgb(240, 255, 255)" size={22} />
            )}
           </div>
          </div>
         </div>
         <div className={styles.dropdown_btn2}>
          <div className={styles.test2} onClick={toggleDropdown}>
           <div className="dropdown-toggle1">{selectedOptionsText}</div>
           <div className={styles.icon3}>
            {isOpen ? (
             <IoMdArrowDropup color="#999" size={22} />
            ) : (
             <IoMdArrowDropdown color="rgb(240, 255, 255)" size={22} />
            )}
           </div>
          </div>
         </div>
        </div>
       </div>
       <div className={styles.book_form_down_cont}>
        <button type="submit" className={styles.btn_submit} disabled={isButtonDisabled}>
         <div className={styles.dropdown_btn4}>
          <div className={styles.dropdown_btn_name}>Book Now</div>
          <div className={styles.dropdown_btn_icon}>
           <BsArrowRight size={17.5} />
          </div>
         </div>
        </button>
       </div>
      </div>
     ) : (
      <div className={styles.book_form}>
       <div className={styles.dropdown_btn1a}>
        <div className={styles.dropdown_btn_name}>
         <div className={styles.datepicker_container}>
          <DatePicker
           className={styles.date_picker}
           selected={availabilityData.checkInDate}
           onChange={(date) => handleDateChange("checkInDate", date)}
           dateFormat="yyyy-MM-dd"
           placeholderText="Check-in date"
           popperPlacement="top-start"
           minDate={today}
           locale="enIN"
           onKeyDown={NoKeyboardInput}
           onClick={NoKeyboardInput}
           onFocus={(e) => e.target.blur()}
           disabledKeyboardNavigation
           dayClassName={(date) => {
            if (date < today && !isSameDay(date, today)) {
             return "custom-disabled-day";
            }
            return null;
           }}
          />

          <div
           className={styles.dropdown_calendar_icon}
           onClick={() => {
            const checkOutDatepickerInput = document?.querySelector(
             `.${styles.dropdown_btn1a} .${styles.date_picker}`
            );
            checkOutDatepickerInput?.click();
           }}
          >
           <BsFillCalendar2EventFill size={13.5} color="rgb(240, 255, 255, 0.9)" />
          </div>
         </div>
        </div>
       </div>
       <div className={styles.dropdown_btn1d}>
        <div className={styles.dropdown_btn_name}>
         <div className={styles.datepicker_container}>
          <DatePicker
           className={styles.date_picker}
           selected={availabilityData.checkOutDate}
           onChange={(date) => {
            if (
             availabilityData.checkInDate &&
             date <= new Date(availabilityData.checkInDate.getTime())
            ) {
             return;
            }
            handleDateChange("checkOutDate", date);
           }}
           dateFormat="yyyy-MM-dd"
           placeholderText="Check-out date"
           popperPlacement="top-start"
           minDate={
            availabilityData.checkInDate
             ? new Date(availabilityData.checkInDate.getTime() + 86400000)
             : today
           }
           locale="enIN"
           onKeyDown={NoKeyboardInput}
           onClick={NoKeyboardInput}
           onFocus={(e) => e.target.blur()}
           disabledKeyboardNavigation
           dayClassName={(date) => {
            if (
             (date < today && !isSameDay(date, today)) ||
             (availabilityData.checkInDate && date <= availabilityData.checkInDate)
            ) {
             return "custom-disabled-day";
            }
            if (
             availabilityData.checkInDate &&
             isSameDay(date, availabilityData.checkInDate)
            ) {
             return "custom-same-day";
            }
            return null;
           }}
          />
          <div
           className={styles.dropdown_calendar_icon}
           onClick={() => {
            const checkOutDatepickerInput = document?.querySelector(
             `.${styles.dropdown_btn1d} .${styles.date_picker}`
            );
            checkOutDatepickerInput?.click();
           }}
          >
           <BsFillCalendar2EventFill size={13.5} color="rgb(240, 255, 255,0.9)" />
          </div>
         </div>
        </div>
       </div>
       <div className={styles.dropdown_btn2}>
        <div className={styles.test_room} onClick={toggleDropdown1}>
         <div className="dropdown-toggle1">{selectedOptionsText1}</div>
         <div className={styles.icon3}>
          {isOpen1 ? (
           <IoMdArrowDropup color="#999" size={22} />
          ) : (
           <IoMdArrowDropdown color="rgb(240, 255, 255)" size={22} />
          )}
         </div>
        </div>
       </div>
       {/* occupancy */}
       <div className={styles.dropdown_btn2}>
        <div className={styles.test2} onClick={toggleDropdown}>
         <div className="dropdown-toggle1">{selectedOptionsText}</div>
         <div className={styles.icon3}>
          {isOpen ? (
           <IoMdArrowDropup color="#999" size={22} />
          ) : (
           <IoMdArrowDropdown color="rgb(240, 255, 255)" size={22} />
          )}
         </div>
        </div>
       </div>
       <button type="submit" className={styles.btn_submit} disabled={isButtonDisabled}>
        <div className={styles.dropdown_btn4}>
         <div className={styles.dropdown_btn_name}>Book Now</div>
         <div className={styles.dropdown_btn_icon}>
          <BsArrowRight size={17.5} />
         </div>
        </div>
       </button>
      </div>
     )}
     {isSmallScreen || isLandscape ? (
      <></>
     ) : (
      <div
       style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
       }}
      >
       <button
        className={styles.learn_more_button}
        onClick={(e) => {
         e.preventDefault();
         scrollToHomeSectionMiddle();
        }}
       >
        <BsChevronDoubleDown size={20} />
       </button>
      </div>
     )}
    </form>
   </div>
  </>
 );
};
export default HomeSectionTop;
