import React, { useState, useContext, useEffect } from "react";
import styles from "../../../styles/foodstyles/cartstyles/cart_page.module.css";
import { STRINGS } from "../../../model/constant";
import { CartContext } from "../../../context/cart_context";
import {
 getCutOffTimeApi,
 SBH_Cart_Update,
 storeFoodOrderDataApi,
} from "../../../helper/http_api";
import Arrow_svg from "../../../assets/svg/arrow_svg";
import foodpageStyles from "../../../styles/foodstyles/FoodPage.module.css";
import Modal from "react-modal";
import CutoffPopupView from "../cutoffpopup";
import { cutofftimeCheckPlaceOrder, mealTypeSegregation } from "../../../helper/cutofftime";
import Cart_sepration_wrapper from "./Cart_sepration_wrapper";

Modal.setAppElement("body");
const modalStyles = {
 overlay: {
  backgroundColor: "rgba(0,0,0,0.5)",
 },
};

const Sbh_Cart_component = ({
 ShowSpinner,
 alert_Predict_Func,
 setShowPlaceOrderMsg,
 setOrderPlacedSuccess,
 cutoff_data,
 setRestaurant_show,
}) => {
 const {
  userAuthentication_Details,
  decCartOrderQty,
  increaseOrderQty,
  removeItem,
  cartFood,
  setSpecialInstruction,
  special_instruction,
  cartFoodTotal,
  currentSelectedDate,
  setCartFood,
  cartOthers,
  setFoodCartQtyAmount,
 } = useContext(CartContext);
 const [CutoffPopup, setCutoffPopup] = useState({
  popup: false,
  cutoffdata: "",
 });
 const [cartSepration, setCartSepration] = useState({
  Breakfast: [],
  Lunch: [],
  Dinner: [],
  Snacks: [],
 });

 useEffect(() => {
  seprate_Category_Func(cartFood);
 }, [cartFood]);

 const seprate_Category_Func = (data) => {
  cartSepration.Breakfast = data.filter((item) => item?.foodCategory == "breakfast");
  cartSepration.Lunch = data.filter((item) => item?.foodCategory == "lunch");
  cartSepration.Dinner = data.filter((item) => item?.foodCategory == "dinner");
  cartSepration.Snacks = data.filter((item) => item?.foodCategory == "snack");
  setCartSepration({ ...cartSepration });
 };
 const handleSpecialInstruc = (e) => {
  setSpecialInstruction(e.target.value);
  SBH_Cart_Update({
   user_email: userAuthentication_Details.loggedIn_user_email,
   spl_ins: e.target.value,
   meal_date: currentSelectedDate,
  })
   .then((data) => {
    console.log("getting valueee======>", data);
   })
   .catch((err) => {
    //alert handling
   });
  //   updateCartAllFood(
  //    { key: 3, value: e.target.value },
  //    userAuthentication_Details.loggedIn_user_email
  //   );
 };

 const foodCart_Structure_Formation = (cartData) => {
  let cartPayload = {
   user_email: userAuthentication_Details.loggedIn_user_email,
   Cart_data: cartData,
   meal_date: currentSelectedDate,
   spl_ins: cartData?.length ? special_instruction : "",
  };
  return cartPayload;
 };
 // increase cart order quantity
 const incCartOrderQty = async (qty, mainDish, foodCategory) => {
  if (qty < 40) {
   let cartFood_Index = cartFood.findIndex(
    (item) => item.mainDish == mainDish && item.foodCategory == foodCategory
   );
   const newCart = [...cartFood];
   newCart[cartFood_Index].orderQty = qty + 1;
   SBH_Cart_Update(foodCart_Structure_Formation([...newCart]))
    .then((data) => {
     console.log("getting data", data);
     increaseOrderQty(qty, cartFood_Index);
    })
    .catch((err) => {
     //alert handling
    });
  } else {
   //maximum cart reached
  }
 };

 const decCartOrderQuantity = (qty, mainDish, foodCategory) => {
  let cartFood_Index = cartFood.findIndex(
   (item) => item.mainDish == mainDish && item.foodCategory == foodCategory
  );
  const newCart = [...cartFood];
  newCart[cartFood_Index].orderQty = qty > 1 ? qty - 1 : 1;
  SBH_Cart_Update(foodCart_Structure_Formation([...newCart]))
   .then((data) => {
    decCartOrderQty(qty, cartFood_Index);
   })
   .catch((err) => {
    //alert handling
   });
 };

 const remove_Cart_items = (mainDish, foodCategory) => {
  let cartFood_Index = cartFood.findIndex(
   (item) => item.mainDish == mainDish && item.foodCategory == foodCategory
  );
  if (cartFood_Index != -1) {
   const newCart = [...cartFood];
   newCart.splice(cartFood_Index, 1);
   SBH_Cart_Update(foodCart_Structure_Formation([...newCart]))
    .then((data) => {
     removeItem(cartFood_Index);
    })
    .catch((err) => {
     console.log("entering remove cart error", err);
     alert_Predict_Func(STRINGS.somethingunexpected);
    });
  }
 };

 /* API controller for storing food order data in database */
 const storeFoodOrderData = async () => {
  const cartFoodItemsStore = cartFood.map((ele) => {
   userAuthentication_Details?.mapped_Dates[currentSelectedDate] == true &&
   ele?.complementary === true
    ? (ele.price = 0)
    : "";
   return ele;
  });
  const totalPrice = cartFoodItemsStore.reduce((a, c) => {
   return a + Number(c.price) * c.orderQty;
  }, 0);
  const meal_typeValue = await mealTypeSegregation(cartFoodItemsStore);
  console.log(meal_typeValue, "storeFoodOrderData");
  try {
   ShowSpinner(true);
   const response = await storeFoodOrderDataApi({
    user_name: userAuthentication_Details.loggedIn_user_name,
    user_email: userAuthentication_Details.loggedIn_user_email,
    breakfast: meal_typeValue.breakfast,
    lunch: meal_typeValue.lunch,
    snack: meal_typeValue.snacks,
    dinner: meal_typeValue.dinner,
    spl_ins: special_instruction,
    room_no: userAuthentication_Details.roomIds[0],
    user_phn_no: parseInt(userAuthentication_Details.loggedIn_user_phone),
    order_date: currentSelectedDate,
    totalAmount: totalPrice,
    order_status: 1,
   });
   ShowSpinner(false);
   console.log(response, "storeFoodOrderDataApi================>");
   const { status } = response.data;
   if (status === true) {
    // updateCartAllFood({ key: 6 }, userAuthentication_Details.loggedIn_user_email);
    // closeModal_cart(false);
    SBH_Cart_Update(foodCart_Structure_Formation([]))
     .then(() => {
      setCartFood([]);
      setShowPlaceOrderMsg(true);
      setOrderPlacedSuccess(true);
      setRestaurant_show(cartOthers?.length ? 1 : 0);
      setSpecialInstruction("");
     })
     .catch((err) => {
      //alert handling
     });
   } else if (status === false) {
    setShowPlaceOrderMsg(true);
    setOrderPlacedSuccess(false);
    // closeModal_cart(false);
   }
  } catch (error) {
   ShowSpinner(false);
   alert_Predict_Func(STRINGS.somethingunexpected);
   console.error("Error placing food order:", error);
  }
 };

 /**
  *
  */
 const handlePlaceOrderClick = async () => {
  try {
   const cutofftime = await getCutOffTimeApi();
   if (cutofftime.data.status) {
    console.log("success========>");
    const ReturnValue = await cutofftimeCheckPlaceOrder(
     cutofftime,
     cartFood,
     currentSelectedDate
    );
    if (ReturnValue) {
     console.log(ReturnValue, "returnvalue");
     if (ReturnValue.breakfast == 0 || ReturnValue.lunch == 0 || ReturnValue.dinner == 0) {
      setCutoffPopup({
       popup: true,
       cutoffdata: ReturnValue,
      });
     } else {
      await storeFoodOrderData();
     }
    } else {
     console.log("elsePart");
     alert_Predict_Func("Please choose another day");
    }
   } else {
    alert_Predict_Func(cutofftime.data.message);
    console.log(cutofftime, "getCutOffTimeApielsepart==========>");
   }
  } catch (error) {
   alert_Predict_Func(STRINGS.somethingunexpected);
  }
 };

 const handleOkCutoffTimePopup = async () => {
  console.log(cartFood, "cartFood=====>");
  let breakfastdata = [];
  let lunchdata = [];
  let dinnerdata = [];
  let snackdata = cartFood.filter((item) => item.foodCategory == "snack");
  if (CutoffPopup.cutoffdata.breakfast == 1) {
   breakfastdata = cartFood.filter((item) => item.foodCategory == "breakfast");
   console.log(breakfastdata, "breakfastdate======>");
  }
  if (CutoffPopup.cutoffdata.lunch == 1) {
   lunchdata = cartFood.filter((item) => item.foodCategory == "lunch");
   console.log(lunchdata, "lunchdata======>");
  }
  if (CutoffPopup.cutoffdata.dinner == 1) {
   dinnerdata = cartFood.filter((item) => item.foodCategory == "dinner");
   console.log(dinnerdata, "dinnerdata======>");
  }
  setCartFood([...dinnerdata, ...lunchdata, ...breakfastdata, ...snackdata]);
  // updateCartAllFood(
  //  {
  //   key: 1,
  //   value: [...dinnerdata, ...lunchdata, ...breakfastdata, ...snackdata],
  //  },
  //  userAuthentication_Details.loggedIn_user_email
  // );
  setCutoffPopup({
   popup: false,
   cutoffdata: "",
  });
 };

 return (
  <div className={styles.cart_maincontainer}>
   {CutoffPopup.popup && (
    <Modal
     isOpen={CutoffPopup.popup}
     style={modalStyles}
     onRequestClose={() =>
      setCutoffPopup({
       popup: false,
       cutoffdata: "",
      })
     }
     contentLabel=""
     className={foodpageStyles.modal_cart_details123}
    >
     <CutoffPopupView
      meal_type={CutoffPopup.cutoffdata}
      // onClickClose={() => setCutoffPopup(false)}
      onClickOk={handleOkCutoffTimePopup}
     />
    </Modal>
   )}
   <div className={styles.left_cart_container}>
    <div className={styles.inner_left_container}>
     <div className={styles.cart_container_header}>{STRINGS.yourorders}</div>
     {cartSepration?.Breakfast?.length > 0 && (
      <>
       <div className={styles.meal_container}>
        <div className={styles.mealtype}>Breakfast</div>
        <div className={styles.mealtime}>
         {STRINGS.order_place_before} {cutoff_data.Breakfastmin}
        </div>
       </div>
       <Cart_sepration_wrapper
        cartSepration={cartSepration.Breakfast}
        decCartOrderQuantity={decCartOrderQuantity}
        incCartOrderQty={incCartOrderQty}
        remove_Cart_items={remove_Cart_items}
        userAuthentication_Details={userAuthentication_Details}
        currentSelectedDate={currentSelectedDate}
       />
       <div className={styles.divider_line} />
      </>
     )}
     {cartSepration?.Lunch?.length > 0 && (
      <>
       <div className={styles.meal_container}>
        <div className={styles.mealtype}>Lunch</div>
        <div className={styles.mealtime}>
         {STRINGS.order_place_before} {cutoff_data.Lunchmin}
        </div>
       </div>
       <Cart_sepration_wrapper
        cartSepration={cartSepration.Lunch}
        decCartOrderQuantity={decCartOrderQuantity}
        incCartOrderQty={incCartOrderQty}
        remove_Cart_items={remove_Cart_items}
        userAuthentication_Details={userAuthentication_Details}
        currentSelectedDate={currentSelectedDate}
       />
       <div className={styles.divider_line} />
      </>
     )}
     {cartSepration?.Dinner?.length > 0 && (
      <>
       <div className={styles.meal_container}>
        <div className={styles.mealtype}>Dinner</div>
        <div className={styles.mealtime}>
         {STRINGS.order_place_before} {cutoff_data.Dinnermin}
        </div>
       </div>
       <Cart_sepration_wrapper
        cartSepration={cartSepration.Dinner}
        decCartOrderQuantity={decCartOrderQuantity}
        incCartOrderQty={incCartOrderQty}
        remove_Cart_items={remove_Cart_items}
        userAuthentication_Details={userAuthentication_Details}
        currentSelectedDate={currentSelectedDate}
       />
       <div className={styles.divider_line} />
      </>
     )}
     {cartSepration?.Snacks?.length > 0 && (
      <>
       <div className={styles.meal_container}>
        <div className={styles.mealtype}>Snack</div>
        <div className={styles.mealtime} />
       </div>
       <Cart_sepration_wrapper
        cartSepration={cartSepration.Snacks}
        decCartOrderQuantity={decCartOrderQuantity}
        incCartOrderQty={incCartOrderQty}
        remove_Cart_items={remove_Cart_items}
        userAuthentication_Details={userAuthentication_Details}
        currentSelectedDate={currentSelectedDate}
       />
       <div className={styles.divider_line} />
      </>
     )}
     <div className={styles.splc_instruc_title}>{STRINGS.special_ins_txt}</div>
     <textarea
      onChange={handleSpecialInstruc}
      value={special_instruction}
      className={styles.spcl_instruct}
      rows="5"
      cols="50"
      maxLength="250"
     />
    </div>
   </div>
   <div className={styles.rightCart_full_container}>
    <div className={styles.rightcart_container}>
     <div className={styles.inner_right_container}>
      <div className={styles.right_cart_container_header}>{STRINGS.yourtotal}</div>
      <div className={styles.subtotal_container}>
       <div className={styles.subtotal_txt}>{STRINGS.total}</div>
       <div className={styles.subtotal_txt}>Rs.{cartFoodTotal}.00</div>
      </div>
      <div className={styles.charges_txt}>{STRINGS.chargesincl}</div>
     </div>
    </div>
    <div
     onClick={handlePlaceOrderClick}
     tabIndex={0}
     onKeyDown={(event) => {
      if (event.key == "Enter") {
       handlePlaceOrderClick();
      }
     }}
     className={styles.cart_place_order}
    >
     {STRINGS.order_place_btn_txt}
     <Arrow_svg />
    </div>
   </div>
  </div>
 );
};

export default Sbh_Cart_component;
