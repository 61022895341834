import axios from "axios";
import { getLocalStorage } from "./localstorage_update";
import eventEmitter from "../store/evenemitter_Intializer";

axios.interceptors.request.use(
 async (config) => {
  let sessionConfig = await getLocalStorage(["loggedInUserId", "sessionID"]);
  if (sessionConfig?.loggedInUserId && sessionConfig?.sessionID) {
   console.error("getting user session", sessionConfig?.loggedInUserId);
   let userID = {
    "x-auth-token": sessionConfig.loggedInUserId,
    "session-id": sessionConfig.sessionID,
    "is-Client": true,
   };
   config.headers = Object.assign(config.headers, userID); // Attach token to headers
  }
  return config;
 },
 (error) => {
  console.log("AXIOS REQUEST ERROR IN INTERCEPTOR", error);
  return Promise.reject(error);
 }
);

axios.interceptors.response.use(
 (response) => {
  console.log("AXIOS INTERCEPTOR RESPONSE");
  return response;
 },
 (error) => {
  console.log("AXIOS RESPONE ERROR IN INTERCEPTOR", error?.response?.status);
  /**
   * Session expired handling
   */
  if (error?.response?.status == 440) {
   eventEmitter.emit("Logout_Event", "logout");
  }
  return Promise.reject(error);
 }
);

export const axiosInstance = axios;
